.swatches {
    > a {
        display: inline-block;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        > span {
            display: inline-block;
        }
    }

    &.slick-slider {
        margin: 0 10px;

        .slick-slide {
            text-align: center;

            a {
                > span {
                    width: 100%;
                }
            }
        }

        .swatch-circle {
            display: block !important;
        }
    }
}

// swatch circle additional colors
$swatch-colors: (
    'orange': $orange,
    'yellow': $yellow,
    'beige': $beige,
    'blue': $blue,
    'brown': $brown,
    'green': $green,
    'grey': $grey,
    'navy': $navy,
    'pink': $pink,
    'purple': $purple,
    'red': $red,
    'neon': $neon
);

@each $color in map-keys($swatch-colors) {
    $colorValue: #{map-get($swatch-colors, $color)};

    .swatch-circle-#{$color} {
        // important to override OOTB swatches
        background: $colorValue !important;
    }
}

.swatch-circle-miscellaneous {
    background: linear-gradient(-105deg, $pink, $blue-misc, $yellow, $red-misc) !important;
}

.swatch-circle-neutral {
    background: linear-gradient(to bottom, $java 25%, $dusty-clay 25% 50%, $contour 50% 75%, $tan 75% 100%) !important;
}

.color-border,
.swatch-circle-container {
    display: inline-block;
    box-shadow: none;
    border: 1px solid $transparent;
    margin-bottom: 5px;
    border-radius: 50% !important;
    padding: 0;
    background: none;
    outline: none !important;
    margin-top: 3px;

    &.selected {
        border: 1px solid $black;
    }

    &.focus {
        border: 0;
    }

    &.selected:hover {
        border: 1px solid $black;
    }

    &.unselectable {
        img {
            &:focus {
                outline: none !important;
            }
        }
    }

    > a {
        display: inline-block;
    }
}

/*
    Base cartidge has so many hierarchies to override .swatch-circle styles in each page.
    Adding !important to override all the swatch circle styles from base cartidge.
*/
.swatch-circle {
    width: 1.5em;
    height: 1.5em;
    display: inline-block !important;
    position: relative !important;
    border-radius: 50%;
    vertical-align: middle;
    margin: 3px;
    margin-right: 3px !important;
    border: none !important;

    @include media-breakpoint-down('md') {
        width: 1em !important;
        height: 1em !important;
    }

    img {
        height: 100%;
        width: 100%;
        @include media-breakpoint-down('md') {
            width: 1em !important;
            height: 1em !important;
        }
    }

    &[data-attr-value='white'] {
        img {
            border: 1px solid $shadyLady-gray;
        }
    }

    &.selected {
        border: 1px solid $black;

        &::after {
            display: none;
        }
    }

    &.swatch-circle-miscellaneous {
        transform: rotate(0);
    }
}

.oldswatch-circle {
    width: 33px !important;
    height: 33px !important;
    display: inline-block !important;
    position: relative !important;
    border-radius: 50% !important;
    vertical-align: middle;

    .oldswatch-circleImg {
        height: 100%;
        width: 100%;
        border-radius: 50% !important;

        @include media-breakpoint-down('md') {
            height: 100% !important;
            width: 100% !important;
        }
    }
}

.markdown-prices {
    color: #000 !important;
}

.product-detail {
    a.swatch-circle-container {
        .swatch-circle {
            &.color-value.selected {
                &::after {
                    display: none;
                }
            }

            img {
                vertical-align: top;
            }
        }
    }

    .pdp-swatches {
        &.slick-slider {
            max-width: 100%;
            margin: 10px 20px;
        }
    }

    a.swatch-circle-container {
        .swatch-circle {
            &.color-value {
                &.unselectable {
                    cursor: pointer;
                    position: relative;

                    &::after {
                        background: $black;
                        width: 1px;
                        position: absolute;
                        z-index: $zindex-lowest;
                        transform: rotate(54deg);
                        transform-origin: bottom;
                        height: 48px;
                        top: -25px;
                        left: 1px;
                        content: " ";
                        display: block;
                    }
                }
            }
        }
    }
}

.refinements {
    li a span:first-child {
        margin-right: 10px;
    }
}

.pdp-swatches {
    margin-bottom: 10px;

    &.slick-slider {
        .slick-slide {
            .swatch-circle-container {
                width: auto !important;
                margin: 0 5px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &.slick-slider {
            .slick-slide {
                .swatch-circle-container {
                    margin: 0 40px;
                }
            }
        }
    }
}

#editWishlistProductModal,
#editProductModal {
    .detail-panel {
        .swatch {
            .attribute {
                width: 100%;
                margin: 0;
                padding: 0;

                &[data-attr="color"] {
                    .color {
                        @include font(600, 16px, $black, 16px, normal);
                    }

                    .selected-swatch-name {
                        @include font(normal, 16px, $black, 20px, normal);
                    }
                }
            }

            &[data-attr="size"] {
                .size,
                .size-chart {
                    display: none;
                }

                .attribute {
                    overflow: auto;

                    .custom-select {
                        @include font(600, 16px, $black, 18px, normal);

                        border: none;
                        max-width: 220px;
                        min-width: initial;
                        padding-left: 16px;
                        text-transform: uppercase;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }

        .quantity-wrapper {
            > label {
                margin-bottom: 0;
            }
        }
    }

    .swatch-circle-container {
        margin-bottom: 5px;
    }

    .swatch-circle-container {
        background: none;
        padding: 0;
    }

    .swatch-circle {
        box-shadow: none;
    }

    .custom-select {
        width: 100%;
    }
}

@include media-breakpoint-up(lg) {
    #editProductModal,
    #editWishlistProductModal {
        .detail-panel {
            .swatch[data-attr=size] {
                .attribute {
                    .custom-select {
                        float: left;
                    }
                }
            }
        }
    }
}

.swatch-circle-container:hover {
    border: 1px solid #d5d5d5;
}
