$playIcon: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0icGxheUljb24iPgo8ZyBpZD0iVmlkZW8gSWNvbnMiPgo8cGF0aCBpZD0iVmVjdG9yIDEiIGQ9Ik03IDRWMjBMMjEgMTJMNyA0WiIgZmlsbD0iYmxhY2siLz4KPC9nPgo8L3N2Zz4=');
$pauseIcon: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0icGF1c2UtaWNvbiI+CjxnIGlkPSJHcm91cCA2NiI+CjxyZWN0IGlkPSJSZWN0YW5nbGUgMzEzIiB3aWR0aD0iNCIgaGVpZ2h0PSIxNiIgZmlsbD0iYmxhY2siLz4KPHJlY3QgaWQ9IlJlY3RhbmdsZSAzMTQiIHg9IjgiIHdpZHRoPSI0IiBoZWlnaHQ9IjE2IiBmaWxsPSJibGFjayIvPgo8L2c+Cjwvc3ZnPg==');
$muteIcon: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0ibXV0ZS1idG4iPgo8ZyBpZD0iTkEgVmlkZW8gUGxheWVyIENvbnRyb2xzIj4KPHBhdGggaWQ9IlNwZWFrZXIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTUuNzUyOCAyMC4yMTk2QzE1LjE4NzMgMjAuNDUzOCAxNC41OTk2IDIwLjYyNTcgMTQgMjAuNzMzMlYxOC42Nzk2QzE0LjMzNDkgMTguNiAxNC42NjM3IDE4LjQ5MzggMTQuOTgzMSAxOC4zNjE0QzE1Ljc3MDQgMTguMDM1MyAxNi40ODU3IDE3LjU1NzQgMTcuMDg4MiAxNi45NTQ4QzE3LjY5MDggMTYuMzUyMyAxOC4xNjg3IDE1LjYzNyAxOC40OTQ4IDE0Ljg0OTdDMTguODIwOSAxNC4wNjI1IDE4Ljk4ODcgMTMuMjE4NyAxOC45ODg3IDEyLjM2NjZDMTguOTg4NyAxMS41MTQ1IDE4LjgyMDkgMTAuNjcwNyAxOC40OTQ4IDkuODgzNDZDMTguMTY4NyA5LjA5NjIxIDE3LjY5MDggOC4zODA5IDE3LjA4ODIgNy43NzgzNkMxNi40ODU3IDcuMTc1ODMgMTUuNzcwNCA2LjY5Nzg3IDE0Ljk4MzEgNi4zNzE3OEMxNC42NjM3IDYuMjM5NDQgMTQuMzM0OSA2LjEzMzE3IDE0IDYuMDUzNjFWNEMxNC41OTk2IDQuMTA3NSAxNS4xODczIDQuMjc5NCAxNS43NTI4IDQuNTEzNjJDMTYuNzg0MSA0Ljk0MDc5IDE3LjcyMTEgNS41NjY4OSAxOC41MTA0IDYuMzU2MTlDMTkuMjk5NyA3LjE0NTQ5IDE5LjkyNTggOC4wODI1MiAyMC4zNTMgOS4xMTM3OUMyMC43ODAxIDEwLjE0NTEgMjEgMTEuMjUwNCAyMSAxMi4zNjY2QzIxIDEzLjQ4MjggMjAuNzgwMSAxNC41ODgxIDIwLjM1MyAxNS42MTk0QzE5LjkyNTggMTYuNjUwNyAxOS4yOTk3IDE3LjU4NzcgMTguNTEwNCAxOC4zNzdDMTcuNzIxMSAxOS4xNjYzIDE2Ljc4NDEgMTkuNzkyNCAxNS43NTI4IDIwLjIxOTZaTTE2LjUgMTIuMzY2NkMxNi41IDE0LjA0NTMgMTUuNDY1OSAxNS40ODI1IDE0IDE2LjA3NThWOC42NTczNkMxNS40NjU5IDkuMjUwNzUgMTYuNSAxMC42ODc5IDE2LjUgMTIuMzY2NlpNMyA5Ljg2NjdWMTUuMzY2N0g3TDEyIDIwLjM2NjdWNC44NjY3TDcgOS44NjY3SDNaIiBmaWxsPSJibGFjayIvPgo8L2c+Cjwvc3ZnPg==');

.overlay-play,
.overlay-mute {
    height: 60px;
    width: 60px;
    background-color: $white;
    border-radius: 50%;
    position: absolute;
    border: 1px solid $zumthor;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up (md) {
        &:hover {
            background-color: $black;
            border: 1px solid $gainsboro-gray2;

            .play-icon,
            .pause-icon,
            .mute-icon {
                &::before {
                    filter: invert(1);
                }
            }
        }
    }

    @include media-breakpoint-down (sm) {
        border: none;
        height: 40px;
        width: 40px;

        &:focus {
            outline: none !important;
        }
    }
}

.overlay-play {
    .pause-icon {
        &::before {
            content: $pauseIcon;
        }
    }

    .play-icon {
        &::before {
            content: $playIcon;
        }
    }

    .play-icon,
    .pause-icon {
        &::before {
            height: 24px;
            width: 24px;
        }
    }
}

.short-form-video {
    position: relative;

    .custom-video-controls {
        position: absolute;
        bottom: 21px;
        left: 16px;
        z-index: 2;
        display: flex;
    }

    .overlay-play {
        position: unset;

        .play-icon,
        .pause-icon {
            position: unset;
            margin-top: 5px;
        }

        .pause-icon {
            display: none;
        }
    }

    .volume-controls {
        .overlay-mute {
            position: unset;
            margin-left: 16px;

            .mute-icon {
                position: relative;
                margin-top: 4px;

                &::before {
                    content: $muteIcon;
                }

                &.muted {
                    &::after {
                        content: "";
                        border-top: 2px solid;
                        width: 30px;
                        top: 10px;
                        right: 0;
                        height: 15px;
                        position: absolute;
                        transform: rotate(45deg);
                    }
                }
            }

            @include media-breakpoint-up (lg) {
                &:hover {
                    .mute-icon {
                        &.muted {
                            &::after {
                                border-color: $white;
                            }
                        }
                    }
                }
            }

            &.hidden {
                display: none;

                ~ .volume-slider {
                    display: none;
                }
            }
        }

        input.volume-slider {
            cursor: pointer;
            appearance: none;
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 20px;
            margin-left: 8px;

            &::-webkit-slider-runnable-track {
                background: $gainsboro-gray3;
                border-radius: 16px;
                height: 5px;
            }

            &::-moz-range-track {
                background: $gainsboro-gray3;
                border-radius: 16px;
                height: 5px;
            }

            &::-webkit-slider-thumb {
                position: relative;
                background: $black;
                appearance: none;
                width: 15px;
                height: 15px;
                bottom: 5px;
                border-radius: 50%;
            }

            &::-moz-range-thumb {
                position: relative;
                background: $black;
                appearance: none;
                width: 15px;
                height: 15px;
                bottom: 5px;
                border-radius: 50%;
            }

            @include media-breakpoint-down (sm) {
                display: none !important;
            }
        }

        .no-audio-msg {
            display: none;
            margin-left: 8px;
            background: rgba(0, 0, 0, 0.66);
            border-radius: 4px;
            padding: 2px 6px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;

            p {
                color: $white;
                margin-bottom: unset;
                font-weight: 500;
                line-height: 18px;
                @include fontSize($font-body3);
            }

            @include media-breakpoint-down (sm) {
                display: none !important;
            }
        }
    }
}

@include media-breakpoint-down (sm) {
    .hero-slot-router-component,
    .hero-no-overlay-component,
    .header-content {
        .short-form-video {
            .custom-video-controls {
                position: relative;
                background: $white;
                left: unset;
                bottom: 5px;

                .overlay-mute {
                    margin-left: 0;
                }
            }
        }
    }
}

.long-form-video {
    position: relative;

    .overlay-section {
        height: 99%;
        width: 100%;
        position: absolute;
        cursor: pointer;
        z-index: 2;

        .overlay-bg {
            height: 100%;
            width: 100%;
            background-color: $black;
            opacity: 0.3;
        }

        .overlay-play {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .play-icon {
                margin-top: 3px;
            }
        }

        .overlay-timer {
            padding: 4px;
            background: $black;
            font-weight: bold;
            bottom: 16px;
            right: 16px;
            position: absolute;
            color: $white;
        }
    }

    &.autoplayed {
        .overlay-section {
            display: none;
        }
    }
}

.hero-video[data-native-player=true] {
    position: relative;
}
