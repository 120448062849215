$checkmark: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjM4MDA3IDEzLjgzQzUuMTk2MjIgMTMuODI3NiA1LjAxOTE0IDEzLjc2MDMgNC44ODAwNyAxMy42NEwwLjU5MDA3MyA5Ljg1TDAuOTIwMDczIDkuNDhDMS4wOTU1NCA5LjI3ODMgMS4zNDQ0NyA5LjE1NTI2IDEuNjExMjcgOS4xMzgzNUMxLjg3ODA4IDkuMTIxNDQgMi4xNDA1NCA5LjIxMjA3IDIuMzQwMDcgOS4zOUw1LjM0MDA3IDEyLjAxTDEzLjc4MDEgMi4yNTk5OUMxNC4xNDE5IDEuODQzMjcgMTQuNzczIDEuNzk4NTIgMTUuMTkwMSAyLjE1OTk5TDE1LjU3MDEgMi40ODk5OUw2LjAwMDA3IDEzLjU3QzUuODY3NyAxMy43MjAzIDUuNjgwMDkgMTMuODEwNSA1LjQ4MDA3IDEzLjgyTDUuMzgwMDcgMTMuODNaIiBmaWxsPSIjMDAwMDAwIi8+Cjwvc3ZnPg==');

/* The switch - the box around the slider */
.toggle-switch {
    display: flex;
    width: 60px;
    height: 34px;
    margin-bottom: 0;
    justify-content: flex-end;

    .toggle-switch-input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
            + .toggle-slider {
                background-color: $black;

                &::before {
                    content: $checkmark;
                    text-align: center;
                    -webkit-transform: translateX(18px);
                    -ms-transform: translateX(18px);
                    transform: translateX(18px);
                }

                &:focus {
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.45);
                }
            }
        }

        &:focus {
            box-shadow: 0 0 1px $black;
        }
    }

    /* The slider */
    .toggle-slider {
        display: block;
        cursor: pointer;
        height: 28px;
        width: 46px;
        background-color: $zumthor;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        padding: 2px;
        border-radius: 34px;
        outline: 1px solid $bright-gray2;

        &::before {
            content: '';
            height: 24px;
            width: 24px;
            background-color: $white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
            display: block;
            padding: 4px;
        }
    }
}
