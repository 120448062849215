$font-family-playfair: 'Playfair Display', serif;
$font-weight-thin: 100;
$font-weight-exlight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-exbold: 800;
$font-weight-black: 900;

$primary-btn-idle-color: $black;
$primary-btn-idle-background-color: $white;
$primary-btn-idle-border-color: $black;
$primary-btn-hover-color: $white;
$primary-btn-hover-background-color: $black;
$primary-btn-hover-border-color: transparent;
$primary-btn-active-color: $white;
$primary-btn-active-background-color: $black;
$primary-btn-active-border-color: transparent;
$secondary-btn-idle-color: $white;
$secondary-btn-idle-background-color: $black;
$secondary-btn-idle-border-color: transparent;
$secondary-btn-hover-color: $black;
$secondary-btn-hover-background-color: $white;
$secondary-btn-hover-border-color: $black;
$secondary-btn-active-color: $black;
$secondary-btn-active-background-color: $white;
$secondary-btn-active-border-color: $black;

//z-index values
$zindex-zero: 0;
$zindex-lowest: 1;
$zindex-low: 2;
$zindex-medium: 3;
$zindex-high: 4;
$zindex-higher: 5;
$zindex-highest: 999;

// custom apple pay cart media query sizes
$apple-pay-min-width: 1024px;
$apple-pay-max-width: 1159px;

//custom size guide media query sizes
$sizeGuide992px: 992px;
$sizeGuide767px: 767px;
$sizeGuide576px: 576px;
$sizeGuide1023px: 1023px;

// bootstrap gutter width
$grid-gutter-width: 20px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1362px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1342px
);

$alert-success-text: $black;
$slick-font-family: 'Calibre', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;

html {
    &:lang(ja) {
        $slick-font-family: 'Noto Sans JP', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
    }
}

html {
    &:lang(ko) {
        $slick-font-family: 'Noto Sans KR', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
    }
}

html {
    &:lang(zh) {
        $slick-font-family: 'Noto Sans HK', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
    }
}

$hover-underline-width: 3px;

/* Bootstrap is overriding breakpoint lg to 1409px,
    which is causing no-margin issue in tablet landspace mode.
    Hence the custom logic. */
$medium-device-min-width: map-get($grid-breakpoints, lg);
$large-device-min-width: map-get($grid-breakpoints, xl);
$medium-device-max-width: map-get($grid-breakpoints, lg) - 0.02;
$large-device-max-width: map-get($grid-breakpoints, xl) - 0.02;
$small-device-max-width: map-get($grid-breakpoints, md);
$phone-device-max-width: map-get($grid-breakpoints, sm);

// Additional Spacers (ISBS-561)
$spacer: 1rem !default;
$spacers: (
    6: ($spacer * 4.5),
    7: ($spacer * 6)
);

.lazyload {
    visibility: hidden;
}

.lazyloaded {
    visibility: visible;
}
