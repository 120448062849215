.email-signup-component {
    text-align: center;
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: $white;

    .title {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1.1px;
        @include fontSize($font-text);
    }

    .description {
        color: $dark-gray;
        line-height: 1.25;
        margin-bottom: 16px;
    }

    .email-signup-form-container {
        display: block;
    }

    .email-signup-submitted {
        display: none;

        .title {
            border-top: none;
            padding-bottom: 16px;
        }
    }

    .btn-submit {
        @include button($bg: $black, $color: $white);

        &[disabled] {
            &:hover,
            &:active {
                @include button(
                    $hover-bg: $black,
                    $hover-color: $white,
                    $hover-border-color: $black
                );
            }
        }

        &:hover,
        &:active {
            @include button(
                $hover-bg: $white,
                $hover-color: $black,
                $hover-border-color: $black
            );
        }
    }

    .newsletter-btn {
        margin-top: 24px;
        letter-spacing: 1.6px;
        font-size: 13px;
        background: $dim-gray;
        border: none;
        overflow: hidden;

        &:hover {
            border: 1px solid $black;
        }
    }

    @include media-breakpoint-up(lg) {
        .title {
            font-size: 16px;
        }

        .email-signup-form-container,
        .email-signup-submitted,
        .email-signup-text-component {
            text-align: left;
            max-width: 315px;
            padding-left: 10px;
            padding-right: 10px;
            margin: 0 auto;
        }

        .email-signup-form-container {
            .terms-and-conditions-section {
                display: flex;

                label {
                    margin-left: -14px;
                }
            }
        }
    }

    @media screen and (min-width: $medium-device-max-width) and (max-width: $large-device-max-width) {
        .email-signup-form-container {
            .terms-and-conditions-section {
                label {
                    margin-left: -13px;
                }
            }
        }
    }

    &.footer-top-email-signup {
        background: $light-white-smoke;
        padding-top: 32px;

        @include media-breakpoint-down(md) {
            padding: 16px 10px;
            border: 0;

            .container {
                padding-bottom: 0;
            }
        }

        .form-check-label[for="subscribeMenFooter"] {
            margin-left: 20px;
        }

        .subscribe-women-men-category {
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: 20px;

            .email-shop-for-fieldset {
                margin-right: 20px;
            }

            .email-shop-for-fieldset,
            label {
                line-height: 18px;
                @include fontSize($font-body1);
            }
        }

        .email-signup-text-component {
            max-width: 100%;
            text-align: center;

            .title {
                @include fontSize($font-medium);

                margin-bottom: 8px;
                text-transform: none;
                letter-spacing: normal;

                @include media-breakpoint-up(lg) {
                    @include fontSize($font-medium-desk);
                }
            }
        }

        .sub-title {
            @include fontSize($font-xsmall-desk);

            margin-bottom: 20px;
            font-weight: 600;
        }

        .title-section {
            @include media-breakpoint-down(md) {
                padding: 0 20px;
            }
        }

        .terms-and-condition-copy {
            @include fontSize($font-body3);

            text-align: center;
            margin-bottom: 0;
            color: $bright-gray;

            @include media-breakpoint-up(lg) {
                padding: 0 80px;
            }

            .link {
                color: $bright-gray;
                text-decoration-color: $bright-gray;
            }
        }

        .footer-emailSignupCheckbox {
            margin-bottom: 0.5rem;

            label {
                line-height: 17px;
                @include fontSize($font-body2);
            }

            .required-indicator {
                color: $black;
                margin: 0;
            }
        }

        .email-signup-form-container {
            @include media-breakpoint-up(lg) {
                max-width: 792px;
            }
        }

        .email-address-cta-section {
            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.5rem;

                .email-address-section {
                    width: 573px;
                }

                .signup-action-section {
                    width: 180px;
                }
            }

            .email-address-label {
                @include media-breakpoint-down(lg) {
                    font-size: 1.1875rem;
                    font-weight: 600;
                    padding-bottom: 0.5rem;
                    display: block;
                }
            }

            .form-group,
            .privacy-policy-section {
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }

            .signup-action-section {
                .ctaButton {
                    &.buttonPrimary {
                        border: 0 !important;
                        height: 52px;
                    }
                }
            }
        }

        .emailsignup {
            height: 52px;
            border-radius: 4px;
            @include fontSize($font-body1);
        }

        .form-control {
            &.emailsignup {
                &:not(.is-invalid) {
                    border: 1px solid $black;
                }
            }
        }

        .custom-checkbox input[type=checkbox]:checked + label::before {
            background: $bright-gray;
            border: 0;
        }

        .custom-checkbox input[type=checkbox]:checked + label::after {
            background: $bright-gray;
        }

        .required-notice {
            display: none;
        }

        .email-signup-submitted {
            max-width: 100%;
            text-align: center;

            .title {
                @include fontSize($font-medium);

                margin-bottom: 8px;
                text-transform: none;
                letter-spacing: normal;
                padding-bottom: 0;

                @include media-breakpoint-up(lg) {
                    @include fontSize($font-medium-desk);
                }
            }

            .description {
                .semibold {
                    @include fontSize($font-xsmall-desk);

                    margin-bottom: 16px;
                    font-weight: 600;
                }

                span {
                    display: block;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 420px;
                    margin: 0 auto;
                }

                .link {
                    color: $bright-gray;
                    text-decoration-color: $bright-gray;
                }
            }

            .privacy-copy-text {
                color: $bright-gray;
                @include fontSize($font-body2);

                .link {
                    color: $bright-gray;
                    text-decoration-color: $bright-gray;
                }
            }
        }

        .signUpKR-text-component {
            max-width: 100%;
            text-align: center;

            .signUpKR {
                font-weight: 700;
            }

            .sub-title {
                @include fontSize($font-body);

                font-weight: 700;
            }

            .signup-action-section {
                max-width: 248px;
                margin: 0 auto;

                .newsletter-btn {
                    border-radius: 4px;
                    height: 52px;
                    margin-top: 16px;
                    padding: 14px;

                    &:not(:hover) {
                        background: $blackCurrent;
                    }
                }
            }
        }
    }
}

.email-signup-toaster {
    position: fixed;
    z-index: 2;
    background-color: $black;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px 40px 16px 16px;
    transition: all 0.5s ease-out;
    bottom: -80px;
    border-radius: 4px 4px 0 0;
    opacity: 0;

    @include media-breakpoint-down(md) {
        right: 0;
        left: 0;
        padding-bottom: 28px;
    }

    @include media-breakpoint-up(md) {
        right: 24px;
        max-width: 448px;
    }

    &.show {
        bottom: 0;
        opacity: 1;
    }

    .taoster-copy {
        color: $white;
        display: flex;

        .copy {
            padding-left: 16px;
            display: flex;
            align-items: center;
        }

        p {
            margin: 0;
            @include fontSize($font-body1);
        }

        button {
            &.signup-cta {
                color: $white;
                font-weight: 600;
                text-decoration: underline;
                padding: 0;
                letter-spacing: 0;
                @include fontSize($font-body1);
            }
        }
    }

    .logo-icon {
        display: flex;
        align-items: center;
    }

    .icon-logo {
        fill: $red;
        width: 34px;
        height: 34px;
        margin: 0 auto;
        display: block;
    }

    button {
        background: transparent;
        border: 0;
        outline: none !important;

        &.close-cta {
            position: absolute;
            right: 10px;
            top: 14px;
        }
    }
}

.pd-email-signup-component {
    min-height: 420px;
}

div[class*="pageBackground"] {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        background: none !important;
    }
}

.storepage {
    &.SignUp {
        @include media-breakpoint-up(md) {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        @include media-breakpoint-down(md) {
            background: none !important;
            margin: 0 -10px;
        }
    }
}

div[class*="formframework"],
div[class*="formSuccessMessage"] {
    position: relative;
    z-index: 2;
}

div[class*="formSuccessMessage"] {
    h4 {
        line-height: 1.1;
        @include fontSize($font-xsmall);
    }

    svg {
        margin-bottom: 1rem;
    }

    .button-primary {
        margin-top: 0.5rem;
    }
}

.emailsignupform-progress-loader {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &.footer-form-loader {
        background: rgba(242, 242, 242, 0.8);
    }

    .progress-3FbDU {
        animation: spin-2agK_ 2s linear infinite both;
    }

    .progressCircle-1a06A {
        animation: strokeSpin-QUQeu 1.4s ease-in-out infinite both;
        fill: transparent;
        stroke-dasharray: 150;
        stroke-linecap: round;
        stroke-width: 0.25rem;
        transform-origin: 50% 50%;
    }

    .progressCircle-1a06A.gray-3rBA4 {
        stroke: $suva-grey;
    }

    .progressCircle-1a06A.red-1z0aE {
        stroke: $suva-grey;
    }

    .progressCircle-1a06A.white-31Koi {
        stroke: $white;
    }

    @keyframes spin-2agK_ {
        0% {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(1turn);
        }
    }

    @keyframes strokeSpin-QUQeu {
        0%,
        25% {
            stroke-dashoffset: 140;
            transform: rotate(0);
        }

        50%,
        75% {
            stroke-dashoffset: 75;
            transform: rotate(45deg);
        }

        to {
            stroke-dashoffset: 140;
            transform: rotate(1turn);
        }
    }
}

.pd-email-signup-component-inner {
    width: 648px;
    background: $white;
    padding: 40px 56px 48px;
    border-radius: 4px;
    position: relative;

    @include media-breakpoint-up(md) {
        margin: 62px auto;
    }

    @include media-breakpoint-down(md) {
        padding: 34px 10px 0;
        background: none;
        width: 100%;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"] {
        border: 1px solid $black;
        height: 52px;
        border-radius: 4px;
        @include fontSize($font-body1);

        &.is-invalid {
            border-color: $red;
        }
    }

    .custom-checkbox input[type=checkbox]:checked + label::before {
        background: $bright-gray;
        border: 0;
    }

    .custom-checkbox input[type=checkbox]:checked + label::after {
        background: $bright-gray;
    }

    .checkbox-group {
        .invalid-feedback {
            color: $black;
            margin-top: 8px;

            &::before {
                content: "!";
                border: 2px solid $crimson;
                border-radius: 50%;
                color: $crimson;
                padding: 0 9px;
                margin-right: 4px;
                @include fontSize($font-body2);

                font-weight: 500;
            }
        }
    }

    .form-group {
        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }

        &.signup-action-section {
            margin: 0.125rem 0 0.75rem;
        }

        &.builtinbox {
            display: flex;
            flex-direction: column;

            .invalid-feedback {
                background: none;
                padding: 0;
                font-weight: 400;
                font-size: 0.938rem; // 15
                line-height: 19px;
            }

            .builtinbox-fieldWrapper {
                position: relative;
            }

            .pd-field-error-clear-icon {
                position: absolute;
                top: 14px;
                right: 20px;
                cursor: pointer;
                color: $red;
            }
        }

        .form-check-input:invalid ~ .form-check-label {
            color: $black;
        }

        .form-check-input:required ~ .form-check-label {
            .checkbox-label {
                &::after {
                    content: "*";
                    color: $black;
                    padding-left: 3px;
                }
            }
        }

        .form-check-label {
            p {
                margin: 0;
                font-weight: 400;
                line-height: 24px;
                display: inline;
                @include fontSize($font-body1);
            }

            &::before {
                top: 3px;
            }
        }

        .btn {
            width: 100%;
        }

        .btn-submit {
            @include button($bg: $black, $color: $white);

            &[disabled] {
                &:hover,
                &:active {
                    @include button(
                        $hover-bg: $black,
                        $hover-color: $white,
                        $hover-border-color: $black
                    );
                }
            }

            &:hover,
            &:active {
                @include button(
                    $hover-bg: $white,
                    $hover-color: $black,
                    $hover-border-color: $black
                );
            }
        }

        label {
            font-weight: 400;
            line-height: 23px;
            margin-bottom: 6px;
            color: $black;
            @include fontSize($font-text);
        }
    }

    .hint-text {
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        padding-top: 6px;
        color: $bright-gray;
    }

    .form-check-input.is-invalid ~ .form-check-label {
        color: $black;
    }

    div[class*="formMultipleBox"] {
        .invalid-feedback {
            background: none;
            padding: 0;
            font-weight: 400;
            font-size: 0.938rem;
            line-height: 19px;
        }

        .pd-textfiledWrapper {
            position: relative;
        }

        .pd-field-error-clear-icon {
            position: absolute;
            top: 14px;
            right: 20px;
            cursor: pointer;
            color: $red;
        }
    }

    div[class*="formCheckbox"],
    div[class*="formSingleCheckbox"] {
        .invalid-feedback {
            color: $black;
            margin-top: 8px;
            border: 1px solid $red;
            padding: 12px;

            &::before {
                content: "!";
                border: 2px solid $crimson;
                border-radius: 50%;
                color: $crimson;
                padding: 0 9px;
                margin-right: 4px;
                @include fontSize($font-body2);

                font-weight: 500;
            }
        }
    }

    .checkbox-group {
        @include media-breakpoint-down(md) {
            padding: 0 10px;

            .form-group {
                padding: 0;
            }
        }

        .experience-fields {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .experience-component {
            width: 33.33%;
            @include media-breakpoint-down(md) {
                width: 50%;
            }

            &[class*="formCheckboxOtherValue"] {
                width: 100%;
            }
        }

        .invalid-feedback {
            margin: 0 0 10px;
        }
    }

    .richtext {
        .lll-text-body-3 {
            margin-bottom: 0;
        }

        h2,
        h4 {
            margin-bottom: 1rem;
        }
    }

    .required-indicator {
        margin: 0;
        color: $black;
    }
}

html {
    &:lang(ja),
    &:lang(ko),
    &:lang(zh) {
        .email-signup-component.footer-top-email-signup {
            .terms-and-condition-copy {
                line-height: 20px;
            }
        }
    }
}

footer {
    @include media-breakpoint-up(lg) {
        .email-signup-form {
            .email-address-section {
                width: 556px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .invalid-feedback {
                    width: 100%;
                }

                label {
                    @include fontSize($font-body1);

                    font-weight: 600;
                    width: 236px;
                    padding-right: 20px;
                    text-align: right;
                }
            }

            input.emailsignup {
                width: 336px !important;

                &::-webkit-input-placeholder {
                    color: transparent;
                }

                &:-moz-placeholder { /* Firefox 18- */
                    color: transparent;
                }

                &::-moz-placeholder {  /* Firefox 19+ */
                    color: transparent;
                }

                &:-ms-input-placeholder {
                    color: transparent;
                }
            }
        }
    }
}
