@import "./productBubble";

.shop-look-component {
    .image-wrapper {
        .masonry-link {
            position: relative;
            height: 100%;
            display: block;
        }
    }

    .shop-look-cta {
        position: absolute;
        bottom: 8px;
        left: 8px;
        border: none;
        border-radius: 25px;
        font-weight: 500;
        padding: 5px 10px;
        height: unset;
        width: unset;
        line-height: 20px;
        outline: none !important;
        letter-spacing: normal;
        background-color: $white;
        color: $black;
        @include fontSize($font-body3);

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

        @include media-breakpoint-up (lg) {
            left: 12px;
            bottom: 12px;

            // stylelint-disable-next-line selector-pseudo-class-no-unknown
            &:focus-visible {
                outline: 4px solid $blue2 !important;
                outline-offset: 1px;
            }
        }

        &:hover {
            @include media-breakpoint-up (lg) {
                color: $zumthor;
                border: 1px solid $zumthor;
                background-color: $black;
            }
        }

        &.active {
            background-color: $black !important;
            border: 1px solid $zumthor;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
            color: $zumthor !important;

            ~ .prod-hotspots {
                display: flex;
                outline: none;
            }
        }
    }

    .prod-hotspots {
        position: absolute;
        display: none;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        cursor: pointer;

        .solid {
            background: $white;
            border: 1px solid $bright-gray;
            border-radius: 50%;
            box-shadow: 0 0 5px 2px $bright-gray;
            width: 22px;
            height: 22px;
            transition: 0.1s ease;
        }

        &.active {
            .solid {
                width: 18px;
                height: 18px;
            }
        }

        @include media-breakpoint-up (lg) {
            &:hover {
                .solid {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    &.masonry-element {
        .shop-look-cta {
            margin-bottom: 16px;
        }

        .bubble-container {
            &.left-aligned {
                margin-left: 5px;
            }

            &.right-aligned {
                margin-right: 5px;
            }
        }
    }

    .hero-img {
        position: relative;

        .shop-look-wrapper {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;

            .shop-look-cta {
                @include media-breakpoint-down (sm) {
                    left: 12px;
                }
            }
        }
    }

    .bubble-wrapper {
        visibility: hidden;
        position: absolute;
        height: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        &.displayed {
            display: inline-block;
            visibility: visible;
        }

        .bubble-container {
            min-height: 100px;
            outline: none;

            &.tabbed {
                outline: 4px solid $blue2;
            }

            .product-image {
                margin-bottom: 0;
            }

            .loader {
                position: absolute;
                height: 90%;
                width: 95%;
                background-color: rgba(255, 255, 255, 0.5);

                svg {
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }
            }
        }
    }
}

.shopLook-masonry-AB {
    display: none;
}

.shopLook-hero-AB {
    display: none;
}
