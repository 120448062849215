.bubble-container {
    width: 255px;
    padding: 8px;
    position: absolute;
    display: flex;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 8px 0 12px 0 rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(lg) {
        outline: 4px solid $blue2;
        outline-offset: 2px;
    }

    .product-image {
        width: 80px;
        min-height: unset;
    }

    .product-details {
        display: flex;
        flex-wrap: wrap;
        padding-left: 8px;

        .product-name {
            flex: 100%;
            font-weight: 500;
        }

        .product-price {
            flex: 80%;
            align-self: end;

            .price,
            .sales {
                font-weight: 400;

                .strike-through {
                    margin-right: 0;
                    display: inline;
                    color: $bright-gray2;
                }
            }

            .dynamicPricing {
                display: none;
            }
        }

        .product-name,
        .product-price,
        .strikeout-price {
            text-align: left;
            margin-bottom: 0;
            @include fontSize($font-body2);
        }

        .strikeout-price {
            color: $bright-gray2;
            text-decoration: line-through;
        }

        svg {
            align-self: end;

            path {
                fill: $bright-gray2;
            }
        }
    }
}
