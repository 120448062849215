.content-slot-container {
    padding: 0 8px;
}

.qa-container {
    .qa-section {
        border-bottom: 1px solid $medium-gray;
        padding-bottom: 16px;
        margin-top: 20px;

        .qa-content {
            display: none;
            margin-top: 16px;
        }

        .qa-title {
            margin-bottom: 0;
            font-size: initial;
            line-height: normal;

            .title {
                @include fontSize($font-body);

                line-height: 1;
                display: block;

                &:hover {
                    text-decoration: none;
                }

                .icon-collapse {
                    display: none;
                }

                .icon {
                    float: right;
                    margin-right: 4px;
                }
            }
        }

        &.active {
            .qa-content {
                display: block;
            }

            .qa-title {
                .title {
                    .icon-collapse {
                        display: inline-block;
                    }

                    .icon-expand {
                        display: none;
                    }
                }
            }
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 0 60px;

        .qa-section {
            padding-bottom: 32px;
            margin-bottom: 32px;
            margin-top: 32px;

            .qa-content {
                margin-top: 32px;
                padding-right: 60px;
            }

            .qa-title {
                .title {
                    @include fontSize($font-medium-desk);

                    .icon-expand {
                        margin-top: 12px;
                    }

                    .icon-collapse {
                        margin-top: 72px;
                    }

                    .icon {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
