.outfit-component {
    margin-bottom: 48px;

    .show-carousel {
        @extend .col-lg-8;
    }

    .title {
        text-align: center;
        margin-bottom: 14px;

        @include font(600, 40px, $black, 33px, normal);
    }

    .main-product {
        justify-content: center;

        .img-wrapper {
            position: relative;
            text-align: center;

            @include media-breakpoint-up (lg) {
                padding-top: 10px;
            }

            .shop-look {
                position: absolute;
                left: 8px;
                bottom: 8px;
                border: none;
                border-radius: 25px;
                font-weight: 500;
                padding: 5px 10px;
                height: unset;
                line-height: 20px;
                outline: none !important;
                border: 1px solid $white;
                letter-spacing: normal;
                @include fontSize($font-body3);

                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

                @include media-breakpoint-up (lg) {
                    left: 12px;
                    bottom: 12px;

                    // stylelint-disable-next-line selector-pseudo-class-no-unknown
                    &:focus-visible {
                        outline: 4px solid $blue2 !important;
                        outline-offset: 1px;
                    }
                }

                &:hover {
                    color: $zumthor;
                    border: 1px solid $zumthor;

                    @include media-breakpoint-down(lg) {
                        color: $black;
                        background-color: $white;
                        border: none;
                    }
                }

                &.active {
                    background-color: $black;
                    border: 1px solid $zumthor;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
                    color: $zumthor;

                    ~ .hotspot {
                        display: flex;
                        outline: none;
                    }
                }
            }
        }

        .outfit-description {
            margin-top: 16px;
            @include fontSize($font-body1);
        }
    }

    .no-carousel {
        .outfit-carousel {
            display: flex;

            .outfit-slide {
                width: 33.5%;
            }
        }
    }

    .btn-wrapper {
        margin: 16px auto 0;
        width: 300px;
        text-align: center;

        .outfit-btn {
            @include font(600, 16px, null, null, 1.6px);

            display: inline;
            padding: 16px;
            border-radius: 4px;

            &.cta-text-wrap {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .outfit-carousel {
        .outfit-slide {
            padding: 0 4px;

            .tile-body {
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
                padding: 20px 12px;
                margin-bottom: 2px;

                .price,
                .sales {
                    .strike-through {
                        margin-right: 0;
                        display: inline;
                    }
                }
            }

            .product-tile {
                .slick-slide {
                    margin-right: 11px;
                }
            }
        }

        &.slick-slider {
            > .slick-arrow {
                background-color: $white;
                opacity: 1;
                width: 28px;
                height: 35px;

                &.slick-disabled {
                    display: none !important;
                }
            }

            > .slick-prev {
                left: 16px;
            }

            > .slick-next {
                right: 16px;
            }

            > .slick-dots {
                display: flex;
                justify-content: center;
                position: static;
                margin-top: 16px;

                li {
                    width: 8px;
                    height: 8px;
                    margin-right: 8px;
                    border-radius: 50%;
                    background-color: $gainsboro;

                    &.slick-active {
                        background-color: $bright-gray;
                    }

                    &:focus,
                    &:hover {
                        outline: 1px solid -webkit-focus-ring-color !important;
                    }
                }
            }
        }

        .btn-primary {
            font-weight: 600;
        }
    }

    .bubble-wrapper {
        visibility: hidden;

        &.displayed {
            visibility: visible;

            @include media-breakpoint-up (lg) {
                visibility: hidden;
            }
        }

        @include media-breakpoint-up (lg) {
            &.visible-lg {
                visibility: visible;
            }
        }

        .bubble-container {
            min-height: 100px;

            .loader {
                position: absolute;
                height: 90%;
                width: 95%;
                background-color: rgba(255, 255, 255, 0.5);

                svg {
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;

        .btn-wrapper {
            width: 100%;
        }

        .image-section {
            width: 33%;
        }

        .outfit-carousel-section {
            padding-right: 6px;
            padding-left: 6px;
            width: 66.5%;

            &.two-prods {
                width: 45%;

                .outfit-slide {
                    width: 50%;
                }
            }

            &.one-prod {
                width: 23%;

                .outfit-slide {
                    width: 100%;
                }
            }

            &.no-prod {
                width: unset;
            }
        }

        .title {
            margin-bottom: 32px;

            @include font(600, 40px, $black, 44px, 0);
        }

        .outfit-carousel {
            &.slick-initialized {
                .slick-track {
                    padding-right: 8px;
                }

                > .slick-list {
                    > .slick-track {
                        margin-left: 0;
                    }
                }
            }

            .outfit-slide {
                padding: 10px 5px 5px;

                &.selected {
                    padding: 0 0 2px;
                    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.2));

                    .image-container {
                        z-index: $zindex-lowest;
                        background-color: $white;
                    }

                    .tile-body {
                        box-shadow: 0 8px 6px -6px $zumthor;
                        z-index: $zindex-lowest;
                        background-color: $white;
                    }
                }

                .product-tile {
                    margin-bottom: 0;
                }

                .tile-body {
                    box-shadow: none;
                }
            }

            &.slick-slider {
                > .slick-arrow {
                    width: 48px;
                    height: 48px;
                    border-radius: 4px;
                    top: 25%;
                    transform: translateY(-25%);
                }

                > .slick-next,
                > .slick-prev {
                    &::before {
                        width: 9px;
                        height: 16px;
                    }
                }

                > .slick-next {
                    &::before {
                        margin-left: 5px;
                    }
                }

                > .slick-prev {
                    &::before {
                        margin-left: 8px;
                    }
                }

                > .slick-dots {
                    li {
                        height: 8px;
                    }
                }
            }
        }
    }
}

.hotspot {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    cursor: pointer;

    .solid {
        background: $white;
        border: 1px solid $bright-gray;
        border-radius: 50%;
        box-shadow: 0 0 5px 2px $bright-gray;
        width: 22px;
        height: 22px;
        transition: 0.1s ease;
    }

    &.active {
        .solid {
            width: 18px;
            height: 18px;
        }
    }

    @include media-breakpoint-up (lg) {
        &:hover {
            .solid {
                width: 18px;
                height: 18px;
            }
        }
    }
}
