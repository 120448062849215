@import 'bootstrapSelect';
@import './saveForLater';

.attributes {

    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.disabled-veil {
    opacity: 0.5;
    pointer-events: none;
}

body {
    &:lang(ja) {
        font-size: 13px;
    }

    &:lang(ko) {
        font-size: 13px;
    }
}

.page {
    background-color: $grey1;
}

.cart-header {
    h1.page-title {
        @include font(600, 26px, $black, normal, 0);

        left: 0;
        margin: 0;
        padding: 15.5px 0;
        background-color: $transparent;

        &::before {
            content: none;
        }
    }

    .title-help {
        .help-phone-number {
            @include underlineOnHover();
        }
    }

    &-empty {
        padding: 32px 10px;
    }

    .title-border {
        border-bottom: 1px solid $black;
        height: 1px;
        margin-bottom: 16px;
    }

    @include media-breakpoint-up(lg) {
        h1.page-title {
            @include font(600, 40px, $black, normal, 0);

            padding: 24px 0;
        }

        .title-help {
            @include fontSize($font-body);
        }

        .title-border {
            margin-bottom: 32px;
        }
    }
}

.cart-error-messaging {
    &.cart-error {
        .global-cart-error {
            display: flex;
            width: auto;
            margin: 8px 10px 0;
            background-color: $amour;
            border: 1px solid $red;
            padding: 14px 10px 12px;
            line-height: 21px;
            @include fontSize($font-body2);

            &.checkout-alert {
                margin: 8px 10px;
            }

            .cart-error-icons {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                flex-shrink: 0;

                + span {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;

                    .sfl-save-all {
                        margin-left: 16px;
                        border-bottom: 1px solid $gainsboro-gray2;
                        padding-bottom: 3px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            @include media-breakpoint-up('lg') {
                width: 873px;
                margin: 40px auto 5px;
                padding: 14px 18px 13px 12px;
                align-items: center;

                .cart-error-icons {
                    margin-right: 8px;
                }

                &.checkout-alert {
                    margin: 8px auto 5px;
                }
            }
        }
    }
}

.cart,
.saveforlater-page {
    .line-item-errors {
        padding: 12px;
        margin-bottom: 8px;
        display: flex;
        line-height: 21px;
        align-items: center;
        @include fontSize($font-body2);

        &.line-item-1 {
            margin-top: 16px;
        }

        &.line-error {
            border-color: $red;
            background: $amour;
            flex-shrink: 0;
        }

        &.line-warning {
            @include font(500, 14px, $dark-golden, 20px, normal);

            background: $light-cream;
            border-radius: 4px;
            padding: 1px 8px 2px;
            margin: 0;

            @include media-breakpoint-up(lg) {
                padding: 2px 12px 4px;
                line-height: 20px !important;
                @include fontSize($font-body2);
            }
        }

        svg {
            width: 24px;
            height: 24px;
            margin-right: 7px;
            flex-shrink: 0;
        }

        @include media-breakpoint-up('lg') {
            align-items: center;
            line-height: 21px;
            @include fontSize($font-body2);

            &.line-item-1 {
                margin-top: 0;
            }
        }
    }

    .out-of-stock {
        @include font(500, 14px, $vivid-red, 20px, normal);

        background: $light-pink;
        border-radius: 4px;
        padding: 1px 8px 2px;
        margin: 0;
        font-weight: 500 !important;

        @include media-breakpoint-up(lg) {
            padding: 2px 12px 4px;
            line-height: 20px !important;
            @include fontSize($font-body2);
        }
    }

    .sfl-oos-low-stock {
        width: 100%;
        justify-content: space-between;
        margin-top: 16px;
    }

    .product-info {
        box-shadow: 0 1px 0 0 $silver;
        border: none;
        border-radius: 0;
        background-color: $white;
        margin-bottom: 24px;
        border: 1px solid $light-gray;
        border-bottom: 0;
        padding: 24px;

        @include media-breakpoint-down(md) {
            margin-left: -10px;
            margin-right: -10px;
            padding: 16px 10px;
            box-shadow: none;
            margin-bottom: 0;
        }

        .cart-item {
            display: flex;
            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }
        }

        .sfl-mob-ATC {
            flex: 0 0 100%;

            a.move-to-cart-saveforlater {
                @include fontSize($font-button);

                font-weight: 600;
                line-height: 20.211px;
                letter-spacing: 1.2px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .card-product-image {
            width: 204px;
            height: 245px;
            margin-right: 24px;

            @include media-breakpoint-down(md) {
                width: 100px;
                height: auto;
                max-height: 154px;
                margin-right: 10px;
            }
        }

        .card-product-info {
            width: calc(100% - 204px);
            @include media-breakpoint-down(md) {
                width: calc(100% - 128px);
            }

            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
            }

            .line-item-body {
                flex-direction: column;
            }
        }

        .line-item-name {
            @include font(500, 19px, $black, 24px, normal);
            @include fontSize($font-body1);

            margin-bottom: 16px;
            margin-right: 24px;
            white-space: initial;
            width: auto;

            @include media-breakpoint-down(md) {
                line-height: 20px;
                @include fontSize($font-body2);

                font-weight: 500;
                margin-bottom: 8px;
            }

            .product-flag {
                @include font(normal, 16px, $red, 16px, normal);

                padding-top: 8px;
                margin-bottom: 4px;
            }
        }

        .item-image {
            width: 106px;
            height: initial;
            max-height: initial;

            .product-image {
                width: 100%;
                max-height: initial;
            }
        }

        .remove-line-item {
            width: 14px;
            height: 14px;

            .remove-product {
                padding: 0;
                background: $transparent;
                border: none;
                display: block;
                position: initial;
                font-size: initial;
                margin: 0;
                height: 14px;

                .icon-cross {
                    width: 14px;
                    height: 14px;
                    vertical-align: top;

                    @include media-breakpoint-up(lg) {
                        width: 20px;
                        height: 20px;
                    }
                }

                &:focus {
                    outline-offset: 2px;
                }
            }
        }

        .item-attributes {
            width: 260px;
            margin-right: 70px;
            overflow-y: visible;

            @include media-breakpoint-down(md) {
                width: 193px;
            }

            @include media-breakpoint-down(sm) {
                margin-right: 10px;
            }

            .line-item-attributes {
                display: flex;
                line-height: 20px;
                @include fontSize($font-body2);

                color: $bright-gray2;

                @include media-breakpoint-down(md) {
                    line-height: 20px;
                    @include fontSize($font-body3);
                }

                .value {
                    text-transform: capitalize;
                }

                .name {
                    margin-right: 4px;
                }

                .prod-attributes {
                    white-space: nowrap;
                }

                .minicart-break {
                    display: none;
                }
            }

            .final-sale {
                @include font(400, 19px, $red, 24px, normal);

                margin-bottom: 4px;

                @include media-breakpoint-down(md) {
                    line-height: 20px;
                    @include fontSize($font-body2);
                }

                &:lang(zh) {
                    .custom-tooltip .bottom {
                        min-width: 155px;
                        left: 80%;
                        top: 27px;
                    }
                }

                &:lang(ja) {
                    .custom-tooltip .bottom {
                        min-width: 220px;
                        left: 60%;
                    }
                }
            }

            .line-item-availability {
                margin-bottom: 16px;

                .line-item-attributes {
                    margin-bottom: 0;
                    white-space: nowrap;

                    &.line-item-instock-date {
                        margin-bottom: 0;
                        width: 100%;
                        text-align: right;
                        display: block;
                    }
                }

                &.line-item-unavailability {
                    .line-item-attributes {
                        white-space: normal;
                    }
                }
            }

            .line-item-quantity {
                .quantity-form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .quantity-label {
                        font-size: 16px;
                    }
                }

                .quantity.custom-select {
                    display: inline-block;
                    border: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                    width: 71px;
                    min-width: initial;
                    float: right;
                    padding-left: 16px;
                }
            }

            .line-item-promo {
                display: none;
            }
        }

        .line-item-price-total {
            @include media-breakpoint-down(md) {
                margin-top: 16px;
            }
        }

        .line-item-price {
            @include media-breakpoint-up(lg) {
                width: 68px;
                align-items: flex-end;
                margin-right: 24px;
            }

            .line-item-price-info {
                @include font(normal, 18px, $black, 23px, normal);

                margin-bottom: 0;

                @include media-breakpoint-down(md) {
                    line-height: 20px;
                    position: inherit;
                    display: inline;
                    @include fontSize($font-body2);
                }
            }

            .price {
                line-height: 23px;
                text-align: right;
                @include fontSize($font-text);

                @include media-breakpoint-down(md) {
                    line-height: 20px;
                    display: inline;
                    @include fontSize($font-body2);
                }

                .pricing,
                .sales {
                    font-weight: normal;
                    margin-top: 18px;

                    @include media-breakpoint-down(md) {
                        margin-top: 0;
                    }

                    &.markdown-prices {
                        margin-top: 8px;

                        @include media-breakpoint-down(md) {
                            margin-top: 0;
                        }
                    }
                }

                .strike-through.list {
                    margin-right: 0;
                }
            }
        }

        .line-item-total-price {
            align-self: center;
            text-align: right;
            width: 200px;

            .line-item-price-info {
                line-height: 23px;
                margin-bottom: 0;
                @include fontSize($font-text);

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                    line-height: 23px;
                    @include fontSize($font-text);
                }
            }

            .price {
                line-height: 24px;
                text-align: right;
                @include fontSize($font-body2);

                @include media-breakpoint-down(md) {
                    line-height: 20px;
                    @include fontSize($font-body2);
                }

                .pricing {
                    display: block;
                    margin-top: 0;
                    color: $black;
                }

                .strike-through {
                    margin-right: 0;
                    color: $bright-gray2;
                    line-height: 20px;
                    @include fontSize($font-body3);

                    @include media-breakpoint-up(md) {
                        @include fontSize($font-body2);

                        line-height: 20px;
                    }

                    &.list {
                        margin-right: 0;
                    }
                }
            }
        }

        .line-item-quantity {
            align-self: center;

            @include media-breakpoint-up(lg) {
                .ui-selectmenu-button {
                    width: 92px;
                }
            }

            .quantity-form {
                .quantity-label {
                    line-height: 23px;
                    margin-bottom: 4px;
                    @include fontSize($font-text);

                    @include media-breakpoint-down(md) {
                        line-height: 23px;
                        margin-bottom: 0;
                        @include fontSize($font-text);
                    }
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 76px;
                }
            }

            .quantity.custom-select {
                border: 1px solid $black;
                border-radius: 4px;
                margin-top: 4px;
                width: 92px;
                height: 52px;
                font-weight: normal;
                float: none;
                padding: 16px;
                background-position-x: 64px;
                line-height: 18px;
                @include fontSize($font-text);

                @include media-breakpoint-down(md) {
                    width: 74px;
                    background-position-x: 44px;
                }

                &:focus {
                    box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
                    outline: none;
                    border: 2px solid;
                }
            }

            .cart-quantity.stepper-input {
                display: flex;
                height: 32px;
                width: 99px;
                padding: 8px;
                align-items: center;
                gap: 16px;
                border-radius: 4px;
                border: 1px solid $zumthor;

                .btn {
                    display: flex;
                    width: 16px;
                    height: 16px;
                    padding: 0;
                    margin: 0;
                    border: none;
                    color: $black;

                    &:disabled {
                        opacity: 1;
                        color: $gainsboro-gray2;
                    }
                }

                input {
                    display: flex;
                    width: 17px;
                    text-align: center;
                    line-height: 20px;
                    padding: 0;
                    margin: 0;
                    border: none;
                    @include fontSize($font-body2);
                }

                &:focus {
                    outline: 1px auto -webkit-focus-ring-color;
                }
            }
        }

        .card-product-edit-wrapper {
            float: right;
            display: flex;
            padding-bottom: 4px;
            justify-content: end;

            @include media-breakpoint-down(md) {
                position: relative;
                width: 100%;
                right: 0;
                bottom: 0;
                padding-bottom: 16px;
                padding-top: 24px;
            }

            a {
                @include fontSize($font-body3);

                line-height: 20px;

                @include media-breakpoint-up(lg) {
                    @include fontSize($font-body2);
                }
            }

            .product-move {
                margin-left: 16px;
                @include media-breakpoint-up(lg) {
                    margin-left: 24px;
                }
            }

            @include media-breakpoint-up(lg) {
                margin-top: auto;
            }
        }

        .product-card-mobile-qty-total {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .edit,
        .move-to-wishlist {
            @include font(normal, 17px, $black, 20px, normal);

            text-decoration: none;
            border-bottom: 1px solid $black;
            padding-bottom: 4px;
        }

        .product-card-footer {
            .line-item-price,
            .line-item-total-price {
                border-top: 1px solid $light-gray;
                margin-top: 16px;
                padding: 16px 0;

                .line-item-price-info {
                    @include font(normal, 16px, $black, 16px, normal);

                    margin-bottom: 0;
                }

                .price {
                    @include font(600, 16px, $black, 16px, normal);

                    max-width: 228px;
                    float: right;
                    text-align: right;

                    .sales,
                    .strike-through.list {
                        display: inline-block;
                        color: $bright-gray2;
                    }
                }
            }

            .line-item-promo {
                @include font(normal, 16px, $red, 16px, normal);

                max-width: 212px;
                float: right;
                margin-bottom: 16px;
            }
        }
    }

    .card-separator-mobile {
        border-bottom: 1px solid $light-grey;
    }

    .approaching-discounts {
        border: none;
        background-color: $white;

        .single-approaching-discount,
        .approaching-discount {
            @include font(normal, 16px, $black, 24px, normal);

            padding: 16px;
            margin: 0;
            border-bottom: 1px solid rgba($color: $black, $alpha: 0.5);
            padding-top: 64px;
            position: relative;
            border-radius: 0;

            .icon-logo {
                top: 16px;
                left: 50%;
                width: 34px;
                position: absolute;
                height: 33px;
            }
        }
    }

    .totals {
        position: -webkit-sticky;
        position: sticky;
        height: 100%;
        top: 33px;
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }

        .coupon-code-field {
            &.is-invalid {
                border-color: $red;
                color: $black;

                + .error-icon-container {
                    display: block;
                }
            }
        }

        .error-icon-container {
            position: absolute;
            right: 11px;
            bottom: 1px;
            padding: 16px;
            color: $red;
            display: none;
            cursor: pointer;
        }

        .promotion-label {
            @include font(400, 19px, $black, 24px, 0);
        }

        .collapse-promo-label {
            margin-bottom: 20px;
            cursor: pointer;

            .icon-expand {
                margin-right: 12px;
            }

            .promotion-btn-label {
                display: inline-block;
                border-bottom: 1px solid $black;
                padding-bottom: 4px;
                margin-bottom: 0;
                line-height: 20px;
                @include fontSize($font-body2);
            }

            @include media-breakpoint-down(md) {
                margin-top: 24px;
                margin-bottom: 20px;
            }
        }

        .collapse-promo-container {
            display: none;

            .form-group {
                margin-bottom: 0;
            }

            @include media-breakpoint-down(md) {
                margin-top: 24px;
            }
        }

        .cart-subtotal {
            margin-top: 32px;
        }

        .cart-subtotal,
        .cart-shipping-cost {
            p {
                margin-bottom: 12px;
                line-height: 20px;
                @include fontSize($font-body2);
            }

            .strike-through {
                text-decoration: line-through;
                color: $bright-gray2;

                &.list-price {
                    color: $raven;
                }
            }
        }

        .estimated-total {
            line-height: 24px;
            @include fontSize($font-body1);
        }

        .cart-checkout-summary-divider {
            border-bottom: 1px solid $gainsboro-gray;
            margin-bottom: 12px;
        }

        .accordion {
            width: 100%;

            .collapsed {
                .icon-expand {
                    display: block;
                }

                .icon-collapsed {
                    display: none;
                }
            }

            .icon-expand {
                display: none;
            }

            > .card {
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
            }

            .promotion-collapse-btn {
                width: 100%;
                text-decoration: none;
                color: $black;
            }

            .promotion-btn-label {
                float: left;
                transform: none;
            }

            .icon-collapsed,
            .icon-expand {
                width: 16px;
                height: 48px;
            }
        }

        .promotion {
            .coupon-error {
                display: none;
            }

            .coupon-error-message {
                color: $red;
                margin-top: 4px;
                margin-bottom: 4px;
                display: inline-block;
            }

            .coupon-missing-error {
                margin-top: 4px;
                display: none;
                color: $red;
                margin-bottom: 0;
                padding-left: 12px;
            }

            .promo-code-btn {
                @include font(600, 16px, $white, 16px, normal);

                height: 52px;
                text-transform: uppercase;
                line-height: 40px;
                background-color: $black;
                font-weight: 600;
                letter-spacing: 0.075rem;

                &[disabled] {
                    &:hover {
                        color: $white;
                        background-color: $black;
                    }
                }

                &:hover {
                    background-color: $matterhorn;
                    border-color: $matterhorn;
                }

                &:focus {
                    outline: 2px solid $blue-outline !important;
                }
            }
        }

        .shipping-discount {
            color: $black;
        }

        .grand-total {
            display: block;
            font-weight: 600;
        }

        .kosm-cart {
            padding-bottom: 32px;
            padding-top: 8px;
            min-height: 60px;
            @include media-breakpoint-down(md) {
                padding-bottom: 24px;
            }
        }

        .coupons-and-promos {
            margin-bottom: 20px;
            background: $white;

            .promotion-information {
                color: $red;

                .applied-promotion-discount {
                    font-weight: 600;
                    color: $red;
                }
            }

            .coupon-price-adjustment {
                border: 1px solid $salem-green;
                background-color: $skeptic;
                border-radius: 0;
                padding: 12px;
                margin-top: 8px;

                &.coupon-not-applied {
                    border: 1px solid $bright-gray;
                    background-color: $wan-white;
                }

                .icon-promo-success {
                    width: 24px;
                    height: 24px;
                }

                .icon-close {
                    width: 12px;
                    height: 12px;
                }

                .remove-coupon {
                    background: $transparent;
                    position: absolute;
                    right: 16px;
                    cursor: pointer;
                    border: none;
                }

                .icon-wrapper {
                    .icon-logo {
                        fill: $red;
                        width: 32px;
                        height: 32px;
                        margin-bottom: 20px;
                    }
                }

                .coupon-code {

                    @include font(normal, 16px, $black, 20px, normal);

                    span {
                        text-transform: lowercase;
                    }
                }

                .coupon-promotion-relationship {
                    list-style: none;
                    display: inline-block;
                    margin-left: 27.5px;

                    @include font(normal, 16px, $black, 20px, normal);

                    li {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .shipping-methods {
            &-label {
                @include font(normal, 18px, $black, 23px, normal);

                display: block;
            }

            &-select {
                @include font(normal, 18px, $black, 23px, normal);

                width: 100%;
                height: 52px;
                border: none;
                min-width: initial;
                padding-left: 16px;
                margin-bottom: 16px;
                display: inline-block;
                background-position: 98%;
                background-color: $white;
                border: 1px solid $black;
                border-radius: 4px;
                outline: none !important;

                &:focus {
                    box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
                }
            }
        }

        .applepay-icon,
        .paywithgoogle-icon,
        .kakaopay-icon,
        .naverpay-icon,
        .payco-icon {
            display: none;
        }

        .cards-container {
            margin-top: 24px;
        }

        .additional-cards-container,
        .applepay-cards-container,
        .googlepay-cards-container,
        .kakaopay-cards-container,
        .naverpay-cards-container {
            margin-top: 24px;
            justify-content: flex-start;
            display: flex;

            .cards-container {
                margin-top: 0;

                &:not(.logos-center) {
                    justify-content: flex-start;
                }

                flex-wrap: wrap;
            }

            .content-asset {
                flex: 1;

                @include media-breakpoint-down(md) {
                    width: 74%;
                }
            }

            .icon {
                margin-bottom: 8px;
            }

            strong {
                line-height: 20px;
                padding-right: 16px;
                @include fontSize($font-body2);

                // this is to handle the gap between small screens and large screens
                // eslint-disable-next-line media-feature-value-dollar-variable
                @media screen and (min-width: $apple-pay-min-width) and (max-width: $apple-pay-max-width) {
                    width: 90px;
                }
            }

            .applepay-icon {
                display: block;
            }
        }

        .googlepay-cards-container {
            .paywithgoogle-icon {
                display: block;
            }
        }

        .kakaopay-cards-container {
            .kakaopay-icon {
                display: block;
            }
        }

        .naverpay-cards-container {
            flex-direction: column;

            strong {
                padding-right: 0;
                text-align: center;
                margin-bottom: 8px;
                @include fontSize($font-body3);
            }

            .naverpay-icon {
                display: block;
            }

            .payco-icon {
                display: block;
            }

            .content-asset {
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }

        #paymentMethodsListGpay {
            li {
                input[type="radio"] {
                    display: none;

                    + svg {
                        display: none;

                        + label {
                            display: none;

                            + p {
                                display: none;
                            }
                        }
                    }

                    + img {
                        display: none;

                        + label {
                            display: none;

                            + p {
                                display: none;
                            }
                        }
                    }
                }

                button {
                    height: 53px;

                    iframe {
                        padding: 3.5px 0;
                    }
                }

                #gpay-button-online-api-id:focus {
                    outline-offset: 2px;
                }
            }
        }
    }

    .checkout-cta-fixed {
        position: fixed;
        bottom: -116px;
        padding-bottom: 32px;
        background-color: $white;
        border-top: 1px solid $gainsboro2;
        z-index: 999;
        visibility: hidden;
        transition: all 0.35s ease-in-out;

        .fixed-cta-totals {
            margin-top: 8px;
            margin-bottom: 8px;
            line-height: 20px;
            @include fontSize($font-body2);

            .value {
                float: right;
            }
        }

        .apple-pay-text,
        #lb_paywithgoogle,
        #lb_googlepay,
        .paymentMethod_img {
            display: none;
        }

        .adyen-checkout__paywithgoogle {
            .gpay-button.plain.short {
                width: 100%;
                height: 53px;
                background-size: 62px 24px;
            }
        }

        .quick-pay-container {
            &.double-payments {
                display: flex;
                align-items: center;
                justify-content: center;

                .apple-pay-container {
                    margin-right: 12px;
                }
            }

            .google-pay-container {
                .paymentMethod {
                    p {
                        display: none;
                    }
                }

                .gpay-button.plain.short {
                    width: 100%;
                    height: 53px;
                    background-size: 62px 24px;
                }

                #paymentMethodsListGpay {
                    button {
                        height: 53px;
                        min-width: unset;

                        iframe {
                            padding: 3.5px 0;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: none !important;
        }
    }

    .checkout-cta-fixed.sticky-cart-btns.single-quick-payment {
        @include media-breakpoint-down(md) {
            .row.minicart-footer-buttons {
                .sticky-checkout-cta {
                    padding-left: 6px;
                }

                .quick-pay-container {
                    padding-right: 6px;
                }
            }
        }
    }

    .checkout-continue {
        padding-right: 0;
        padding-left: 0;

        &.pm-cta-reorder-continue {
            .sticky-checkout-cta {
                flex: 1;
            }

            .quick-pay-container {
                @include media-breakpoint-down(md) {
                    flex: 1;
                }

                min-height: 52px;
                margin: 0;
            }

            .cart-apple-gPay-btns {
                flex: none;
                display: flex;
                justify-content: center;
                margin-top: 12px;
                align-items: center;

                .apple-pay-container,
                .google-pay-container {
                    width: 50%;
                    height: 52px;
                    @include media-breakpoint-up(lg) {
                        width: 100%;
                    }
                }

                @include media-breakpoint-up(lg) {
                    flex-wrap: wrap;
                }

                .apple-pay-container {
                    @include media-breakpoint-down(md) {
                        margin: 8px 12px 0 0;
                    }
                }

                .google-pay-container {
                    @include media-breakpoint-down(md) {
                        margin-top: 8px;
                    }
                }

                &.payment-cta-reorder {
                    @include media-breakpoint-down(md) {
                        margin: 0 0 12px;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                &.single-quick-payment {
                    .row.minicart-footer-buttons {
                        .col-12.quick-pay-container {
                            padding-right: 6px;
                            flex-basis: 50%;
                        }

                        .col-12.sticky-checkout-cta {
                            flex-basis: 50%;

                            .checkout-btn {
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }
        }

        .quick-pay-container {
            margin-top: 12px;

            .apple-pay-container {
                margin-bottom: 12px;

                .dw-apple-pay-button {
                    margin: 0;
                }
            }

            .gpay-button {
                width: 100%;
                height: 53px;
                background-size: 62px 24px;
            }

            &.payment-cta-reorder {
                order: -1;
                min-height: 53px;

                &.cart-Apay-enabled-hide {
                    flex: auto;
                    min-height: auto;
                }
            }
        }

        .row {
            .grand-total {
                padding: 0;
                margin-top: 24px;
                margin-bottom: 8px;

                @include font(600, 20px, $black, 20px, normal);
            }

            .checkout-btn {
                width: auto;
                margin-bottom: calc(24px + env(safe-area-inset-bottom));
                margin-right: calc(10px + env(safe-area-inset-right));
                margin-left: calc(10px + env(safe-area-inset-left));
                @include font(600, 16px, $white, 48px, 1.6px);

                height: 53px;
            }

            &.minicart-footer-buttons {
                max-width: 100%;
                margin-left: 0;
                margin-right: 0;

                .col-12 {
                    padding-left: 0;
                    padding-right: 0;

                    .quick-pay-container {
                        .apple-pay-container {
                            margin-bottom: 12px;
                        }

                        min-height: 99px;
                    }
                }
            }
        }
    }

    .buttonPrimary-1m-xO {
        -webkit-font-smoothing: antialiased;
        letter-spacing: 0.075rem;
        border: 0.0625rem solid;
        border-radius: 0.25rem;
        background-color: $crimson;
        border-color: $crimson;
        color: $white;

        &:hover {
            background-color: $cardinal;
            border-color: $cardinal;
        }

        &:active {
            background-color: $falu-red;
            border-color: $falu-red;
        }

        &.disabled,
        &:disabled {
            background-color: $silver-grey;
            border-color: $silver-grey;
        }
    }

    .buttonSecondary-1ZKFx {
        -webkit-font-smoothing: antialiased;
        letter-spacing: 0.075rem;
        border: 0.0625rem solid;
        border-radius: 0.25rem;
        background-color: $black;
        border-color: $black;
        color: $white;

        &:hover {
            background-color: $matterhorn;
            border-color: $matterhorn;
        }

        &:active {
            background-color: $nightrider-grey;
        }

        &:disabled {
            background-color: $silver-grey;
            border-color: $silver-grey;
        }
    }

    .input-2-hFT {
        background-color: $white;
        background-position: center right 1rem;
        background-repeat: no-repeat;
        border: 1.5px solid $black;
        border-radius: 0.25rem;
        display: block;
        height: 3.25rem;
        padding: 0.875rem 1rem;
        width: 100%;
        @include fontSize($font-text);

        &:focus {
            box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
            outline: none !important;
        }
    }

    .mobile-only-cta {
        margin-bottom: 16px;

        .quick-pay-container {
            display: none;
        }

        @include media-breakpoint-down(md) {
            .checkout-btn {
                line-height: 44px;
            }
        }
    }

    &-empty {
        padding-top: 72px;
        padding-bottom: 32px;

        &-icon {
            width: 48px;
            height: 48px;
        }

        &-line-1 {
            margin-top: 16px;
            margin-bottom: 16px;
            line-height: 48px;
            text-transform: lowercase;
            @include fontSize($font-medium-desk);

            &:lang(de) {
                text-transform: unset;
            }

            &::first-letter {
                text-transform: uppercase;
            }

            @include media-breakpoint-down(md) {
                line-height: 32px;
                @include fontSize($font-medium);
            }
        }

        &-line-2 {
            @include fontSize($font-body);

            line-height: 20px;
            font-weight: normal;
            margin-bottom: 24px;
        }

        &-cta.shop-cta {
            background-color: $nightrider-grey;
            border-color: $nightrider-grey;
            padding-left: 2rem;
            padding-right: 2rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;

            &:hover {
                color: $white;
                text-decoration: none;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0.5rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .mobile-only-cta {
            display: none;
        }

        .totals {
            .promotion {
                .promotion-label {
                    display: block;
                    @include font(400, 19px, $black, 24px, 0);
                }
            }

            .accordion {
                .promotion-collapse-btn {
                    display: none;
                }

                .card {
                    background-color: $transparent;
                    box-shadow: none;
                    border: none;

                    > .collapse {
                        display: block;
                        height: auto !important;
                        visibility: visible;
                    }
                }

                .card-body {
                    display: block;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    margin-bottom: 0;
                }
            }
        }

        .product-info {
            border: solid 1px $gray;
            padding-bottom: 20px;

            .line-item-name {
                //@include font(600, 24px, $black, 28px, normal);

                .product-flag {
                    @include font(normal, 20px, $red, 22px, normal);
                }
            }

            .item-image {
                width: 204px;
                display: block;
            }

            .item-attributes {
                .line-item-attributes {
                    margin-bottom: 8px;

                    .value {
                        font-weight: normal;
                    }
                }

                .line-item-quantity {
                    .quantity-label {
                        font-weight: 600;
                    }

                    .quantity.custom-select {
                        width: 135px;
                        padding-left: 20px;
                        min-width: initial;
                    }
                }
            }

            .line-item-availability {
                .line-item-attributes {
                    font-weight: 600;

                    &.line-item-instock-date {
                        font-weight: normal;
                    }
                }
            }

            .remove-line-item {
                .remove-product {
                    padding: 0;
                }
            }

            .product-card-footer {
                .line-item-price,
                .line-item-total-price {
                    margin-top: 0;
                    max-width: initial;
                    padding: 16px 0 8px;
                }

                .line-item-promo {
                    float: left;
                    max-width: initial;
                    padding-bottom: 8px;
                    margin-bottom: 0;
                }
            }
        }

        &-empty {
            padding: 136px 12px 64px;
        }

        .checkout-continue {
            position: static;
            padding-right: 10px;
            padding-left: 10px;
            box-shadow: initial;
            background-color: initial;

            .quick-pay-container {
                .apple-pay-container {
                    margin-bottom: 12px;

                    .dw-apple-pay-button {
                        margin: 0;
                        height: 53px;
                    }
                }

                .google-pay-container {
                    margin-bottom: 12px;

                    &:focus {
                        outline: 2px solid -webkit-focus-ring-color !important;
                        outline-offset: 2px;
                    }
                }
            }

            .row {
                .grand-total {
                    padding: 16px 0 8px;

                    @include font(600, 20px, $black, 20px, normal);
                }

                .checkout-btn {
                    @include font(600, 16px, $white, 48px, 1.6px);

                    margin-bottom: 24px;
                    margin-left: auto;
                    margin-right: auto;
                    text-transform: uppercase;
                    height: 53px;
                }

                .continue-shopping-btn {
                    @include font(600, 16px, null, 48px, 1.6px);

                    margin-bottom: 16px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

#removeCouponModal {
    .modal-title {
        @include font(400, 20px, $black, normal, normal);
    }

    .coupon-to-remove {
        @include font(400, 20px, $black, normal, normal);
    }

    .modal-footer {
        border: 0;
        padding: 0 12px 32px;

        .btn {
            flex-grow: 1;
        }

        .cart-delete-confirmation-btn {
            margin-left: 12px;
        }
    }
}

footer {
    &.cart-footer {
        position: absolute;
        z-index: -1;
        width: 100%;
        margin-top: 0;
    }
}

#removeProductModal {
    @include font(400, 20px, $black, normal, normal);

    .modal-dialog {
        max-width: 466px;
        margin-top: 166px;
    }

    .modal-header {
        padding: 4px 0 8px 12px;
        line-height: 33px;

        .close {
            margin: 4px 36px 0;
            padding: 0;
        }
    }

    .modal-body {
        .product-to-remove {
            font-weight: 400;
        }
    }

    .modal-footer {
        border: 0;
        padding: 0 12px 32px;

        .btn {
            flex-grow: 1;
        }

        .cart-delete-confirmation-btn {
            margin-left: 12px;
        }
    }
}

.container.cart-recommendations {
    .recommendations {
        // row styles without flex
        margin-left: -10px;
        margin-right: -10px;
        padding-bottom: 94px;
        margin-bottom: 0;

        @include media-breakpoint-up('lg') {
            padding-bottom: 128px;
        }

        .carousel-heading {
            h2 {
                font-weight: 600;
                font-size: 28px;
                line-height: 32px;
                margin-top: 80px;
                margin-bottom: 18px;
                text-align: center;

                @include media-breakpoint-up('lg') {
                    line-height: 48px;
                    margin-top: 124px;
                    margin-bottom: 62px;
                    @include fontSize($font-xl);
                }
            }

            h2::first-letter {
                text-transform: capitalize;
            }

            .carousel-header-small {
                font-family: Noto Sans KR Bold;
                font-size: 33px;

                @include media-breakpoint-down(xs) {
                    font-size: 21px;
                    line-height: 28px;
                }
            }
        }

        .carousel.slider.product-carousel {
            .product-carousel-slide {
                padding: 10px 10px 9px;

                @include media-breakpoint-up('lg') {
                    padding: 10px 10px 2px;
                }

                .tile-body {
                    height: auto !important;

                    .slick-next,
                    .slick-prev {
                        &:hover {
                            &::before {
                                outline: 2px solid #2b52c1;
                            }
                        }
                    }
                }

                .product-tile {
                    margin-bottom: 30px;
                }
            }

            &.slick-slider {
                .slick-track {
                    margin-right: 0;
                    margin-left: 0;

                    @include media-breakpoint-up('lg') {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                &.slick-dotted {
                    @include media-breakpoint-up('lg') {
                        margin-right: 44px;
                        margin-left: 44px;
                    }

                    .slick-track {
                        margin-right: auto;
                        margin-left: auto;

                        @include media-breakpoint-up('lg') {
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }
                }
            }

            .slick-next,
            .slick-prev {
                height: 48px;
                width: 48px;

                &.slick-arrow {
                    padding: 0 16px;

                    &:focus {
                        outline: -webkit-focus-ring-color auto 1px !important;
                    }
                }

                &::before {
                    width: 16px;
                    height: 16px;
                }
            }

            .slick-prev {
                left: -14px;
            }

            .slick-next {
                right: -14px;
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                position: static;

                li {
                    width: 8px;
                    height: 8px;
                    margin-right: 8px;
                    border-radius: 50%;
                    background-color: $suva-gray;

                    &.slick-active {
                        background-color: $black;
                    }
                }
            }
        }

        .wishlistTile {
            .icon-wishlist-o {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22.75a.72.72 0 0 1-.42-.13c-.32-.21-7.79-5.27-10.24-9.76C-.12 10.18-.45 6.4 2.09 4a6.48 6.48 0 0 1 8.82 0L12 5l1.08-1a6.48 6.48 0 0 1 8.82 0c2.54 2.41 2.21 6.19.75 8.87-2.45 4.49-9.9 9.55-10.22 9.76a.72.72 0 0 1-.43.12zm-5.5-19a4.89 4.89 0 0 0-3.37 1.32c-2 1.87-1.66 4.9-.47 7.07 2 3.59 7.73 7.82 9.34 9 1.6-1.14 7.36-5.36 9.32-8.95 1.28-2.34 1.54-5.68-1-7.49a5.07 5.07 0 0 0-6.32.52l-.88.84 1.45 1.4-.35.36a1 1 0 0 1-1.41 0L9.87 5.07A4.89 4.89 0 0 0 6.5 3.75z'/%3E%3C/svg%3E");

                &:hover {
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23D31334' d='M12 22.75a.72.72 0 0 1-.42-.13c-.32-.21-7.79-5.27-10.24-9.76C-.12 10.18-.45 6.4 2.09 4a6.48 6.48 0 0 1 8.82 0L12 5l1.08-1a6.48 6.48 0 0 1 8.82 0c2.54 2.41 2.21 6.19.75 8.87-2.45 4.49-9.9 9.55-10.22 9.76a.72.72 0 0 1-.43.12zm-5.5-19a4.89 4.89 0 0 0-3.37 1.32c-2 1.87-1.66 4.9-.47 7.07 2 3.59 7.73 7.82 9.34 9 1.6-1.14 7.36-5.36 9.32-8.95 1.28-2.34 1.54-5.68-1-7.49a5.07 5.07 0 0 0-6.32.52l-.88.84 1.45 1.4-.35.36a1 1 0 0 1-1.41 0L9.87 5.07A4.89 4.89 0 0 0 6.5 3.75z'/%3E%3C/svg%3E");
                }
            }

            .icon-wishlist-s {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23D31334' d='M12 22.75a.72.72 0 0 1-.42-.13c-.32-.21-7.79-5.27-10.24-9.76C-.12 10.18-.45 6.4 2.09 4a6.48 6.48 0 0 1 8.82 0L12 5l1.08-1a6.48 6.48 0 0 1 8.82 0c2.54 2.41 2.21 6.19.75 8.87-2.45 4.49-9.9 9.55-10.22 9.76a.72.72 0 0 1-.43.12z'/%3E%3C/svg%3E");
            }
        }
    }
}

.cart-error-messaging {
    .valid-cart-error {
        .close {
            padding: 12px 10px;
        }

        p {
            margin: 0 8px 0 0;
        }

        @include media-breakpoint-up(lg) {
            .close {
                padding: 12px 20px;
            }

            p {
                margin: 0 16px 0 0;
            }
        }
    }
}

.content-center {
    margin: 10px auto 0;
    text-align: center;
}

// sweat-collective styling
.sweat-collective {
    display: flex;
    align-items: flex-start;

    div.svgIcon {
        float: right;
        padding-top: 0;
        cursor: pointer;
        pointer-events: auto;
        @include media-breakpoint-down(xs) {
            flex: 0.5;
        }

        svg.alert-icon-info {
            width: 24px;
            height: 24px;
        }
    }

    .tooltip {
        position: relative;
        display: inline-block;
        opacity: 100;

        /* Tooltip text */
        .tooltiptext {
            visibility: hidden;
            background-color: #fff;
            color: black;
            text-align: left;
            border-radius: 4px;

            /* Position the tooltip text - see examples below! */
            position: absolute;
            z-index: 1;
            width: 229px;
            bottom: 124%;
            left: -203px;
            border: 1px solid black;
            padding: 16px;
            line-height: 18px;

            &::after,
            &::before {
                content: " ";
                position: absolute;
                top: 100%;  /* At the top of the tooltip */
                left: 94%;
                margin-left: -5px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
            }

            &::before {
                border-top: 5px solid black;
            }

            &::after {
                border-top: 5px solid white;
                margin-top: -1px;
                z-index: 1;
            }
        }

        /* Show the tooltip text when you mouse over the tooltip container */
        &:hover .tooltiptext {
            visibility: visible;
        }
    }

    $xs-width: 350px;

    .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 28px;
        margin-bottom: 0;

        @media only screen and (max-width: $xs-width) {
            width: 55px;
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d5d5d5;
        content: "\2713";
        -webkit-transition: 0.4s;
        transition: 0.4s;
        text-align: center;
        line-height: 25px;

        &::before {
            position: absolute;
            content: "";
            height: 24px;
            width: 24px;
            top: 2px;
            left: 2px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.2s;
            transition: 0.2s;
        }

        &.round {
            border-radius: 34px;

            &::before {
                border-radius: 50%;
            }
        }
    }

    input:checked + .slider {
        background-color: black;
        content: "";
    }

    input:checked + .slider::before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
        content: url('../images/promo_check.svg');
        line-height: 29px;
    }

    input:focus + div.slider {
        box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
    }

    .sclabel {
        @include font(400, 17px, $black, 24px, 0);

        display: inline;
        margin: 0 8px;

        @include media-breakpoint-down(xs) {
            flex: 1.5;
        }
    }

    &.disabled {
        pointer-events: none;

        .sclabel {
            color: #d5d5d5;
        }
    }
}

.sweat-collective-tnc {
    margin-left: 45px;
    padding-left: 8px;
    color: $bright-gray2;
    line-height: 24px;
    @include fontSize($font-body2);

    a {
        text-decoration: underline;
        color: $bright-gray2;

        &:hover {
            color: $bright-gray2;
        }
    }
}

.sc-disabled-callout {
    background-color: #d6e4f4;
    border: 1px solid #4a90e2;
    padding: 12px;
    align-items: center;
    display: flex;
    margin-top: 8px;
    align-items: flex-start;
    line-height: 20px;
    @include fontSize($font-body2);

    svg.info-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        flex-shrink: 0;
        pointer-events: none;
    }
}

.sc-partially-applied {
    background-color: #d6e4f4;
    border: 1px solid #4a90e2;
    padding: 12px;
    align-items: center;
    display: flex;
    margin-top: 8px;
    align-items: flex-start;
    line-height: 20px;
    @include fontSize($font-body2);

    svg.info-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        flex-shrink: 0;
        pointer-events: none;
    }
}

div[data-action=Cart-Show] {
    .minicart-link {
        &.product-added {
            &::after {
                display: none;
            }
        }
    }
}

//styles for the apple pay button, and the little payment cards
//below the checkout buttons
.cart .checkout-continue .row .checkout-btn {
    margin-bottom: 0;
    height: 53px;

    &:focus {
        outline: 2px solid -webkit-focus-ring-color !important;
        outline-offset: 2px;
    }
}

.apple-pay-text {
    line-height: 25px;
    margin: 12px 0;
    display: block;
    font-size: 16px;
}

.minicart .quick-pay-container {
    display: none;
}

.dw-apple-pay-button,
.dw-apple-pay-button:hover,
.dw-apple-pay-button:active {
    margin: 0;
    height: 53px;
    font-size: 23px;
    line-height: 27px;
    border-radius: 0.25rem;
    background-size: 59px 27px !important;
}

@include media-breakpoint-down(md) {
    .quick-pay-container {
        margin-top: 12px;
    }

    .checkout-cta-fixed {
        .quick-pay-container.double-payments {
            display: flex;
            justify-content: center;
            align-items: center;

            .apple-pay-container,
            .google-pay-container {
                width: 50%;
            }

            .google-pay-container {
                p {
                    display: none;
                }
            }
        }

        &.sticky-cart-btns {
            padding-bottom: 12px;

            .fixed-cta-totals {
                margin: 12px 0;
            }

            .sticky-checkout-cta,
            .quick-pay-container {
                flex: 1;
            }

            .quick-pay-container {
                min-height: 52px;
                margin: 0;

                &.payment-cta-reorder {
                    order: -1;
                }
            }

            .checkout-btn {
                height: 53px;
                line-height: 48px;
            }

            .apple-pay-container {
                margin-top: -4px;
            }

            .cart-apple-gPay-btns {
                flex: none;
                display: flex;
                justify-content: center;
                margin-top: 12px;
                align-items: center;

                .apple-pay-container,
                .google-pay-container {
                    width: 50%;
                    height: 52px;
                }

                .apple-pay-container {
                    margin-right: 12px;
                }

                .google-pay-container {
                    margin-top: 8px;
                }

                &.payment-cta-reorder {
                    margin: 0 0 12px;
                }
            }

            .cart-Apay-enabled-hide {
                flex: auto;
                min-height: auto;
            }
        }
    }

    .cart .checkout-continue .row.minicart-footer-buttons .col-12.quick-pay-container {
        padding: 0 10px;
    }
}

body {
    &.apple-pay-enabled {
        .quick-pay-container {
            min-height: 53px;
        }

        .apple-pay-text {
            display: block;
        }
    }

    .apple-pay-text {
        display: none;
    }

    &.google-pay-visible {
        .quick-pay-container {
            min-height: 53px;
        }

        .apple-pay-text {
            display: block;
        }
    }

    &.apple-pay-enabled.google-pay-enabled {
        .quick-pay-container {
            min-height: 118px;
        }
    }
}

.SG-HK-final-sale-cart {
    .custom-tooltip .bottom {
        min-width: 265px;
        top: 27px;
        left: 75%;
    }
}

#cart-sgd-changes {
    .item-attributes {
        margin-right: 0;
    }

    .line-item-total-price {
        width: 126px;

        @include media-breakpoint-down(md) {
            width: auto;
        }
    }

    @media screen and (min-width: $apple-pay-min-width) and (max-width: $apple-pay-max-width) {
        .line-item-total-price {
            width: 19rem;
        }

        .line-item-price {
            min-width: 90px;
            margin-right: 1.25rem;
        }

        .line-item-quantity {
            margin-right: 0;
        }
    }

    .line-item-price {
        .price {
            .strike-through.list {
                @include media-breakpoint-down(lg) {
                    display: inline-block;
                }

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }

        @include media-breakpoint-up($apple-pay-max-width + 1) {
            min-width: 110px;
        }
    }
}

.price {
    .strike-through {
        color: $bright-gray2 !important;
    }
}

