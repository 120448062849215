@import '~base/components/quickView';
@import '../product/addToCartModal';

body.modal-open {
    overflow: hidden !important;
}

#quickViewModal {
    .modal-dialog {
        display: flex;
        justify-content: center;
        margin-top: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;

        &:focus {
            outline: none;
        }

        @include media-breakpoint-down('sm') {
            &.quick-view-dialog {
                margin: 0;
                bottom: 0;
                top: unset;
                left: unset;
                transform: unset;
            }
        }
    }

    .modal-content {
        border: none;

        @include media-breakpoint-up(lg) {
            max-width: 757px;
        }

        .modal-header {
            width: 100%;
            background: none;
            padding-top: 0;
            padding-bottom: 24px;
            border-bottom: none;
            padding-left: 10px;

            @include media-breakpoint-down('xs') {
                padding-right: 6px;
            }

            .pdpLinkAndArrow {
                .full-pdp-link {
                    padding-bottom: 4px;
                    border-bottom: 1px solid $gainsboro-gray;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 1px solid $crimson;
                    }
                }

                .pdp-link-arrow {
                    margin-left: 8px;
                    transition: 0.2s ease-in;
                }

                &:hover {
                    .pdp-link-arrow {
                        transform: translateX(25%);
                    }
                }
            }

            .close {
                display: flex;
                padding: 1rem 0 0;

                svg.icon-cross {
                    height: 14px;
                    width: 14px;
                }
            }
        }

        .header-parent {
            width: 100%;
        }

        .modal-body {
            max-height: unset;
            overflow-y: visible;

            @include media-breakpoint-down('xs') {
                padding: 10px 10px 12px;
            }

            .product-quickview {
                padding-left: 0;

                @include media-breakpoint-down('xs') {
                    padding-right: 0;

                    .quickViewContainer {
                        margin-left: unset;
                        margin-right: unset;

                        .carousel-container {
                            padding-left: 0;
                        }
                    }
                }

                .quickViewImages {
                    .carousel {
                        display: grid;

                        ol.carousel-indicators {
                            order: 1;
                            position: relative;
                            margin-top: 8px;

                            li {
                                height: 8px;
                                width: 8px;
                                border-top: 0;
                                border-bottom: 0;
                                border-radius: 50%;
                                background: $gainsboro-gray;

                                &.active {
                                    background: $bright-gray;
                                }
                            }
                        }

                        .carousel-inner {
                            order: 0;
                            @include media-breakpoint-up('lg') {
                                height: 416px;
                            }

                            @include media-breakpoint-down('xs') {
                                max-height: 220px;

                                .carousel-item {
                                    img {
                                        object-fit: contain;
                                    }
                                }
                            }
                        }

                        .carousel-control-prev {
                            padding-left: 8px;
                        }

                        .carousel-control-next {
                            padding-right: 8px;
                        }

                        .carousel-control-prev,
                        .carousel-control-next {
                            height: 93%;

                            &:focus {
                                outline: none;

                                .icon-prev,
                                .icon-next {
                                    outline: 1px solid $black;
                                }
                            }
                        }

                        .icon-prev,
                        .icon-next {
                            display: flex;
                            text-align: center;
                            justify-content: center;
                            border-radius: 4px;
                            align-items: center;
                            padding-top: 0;

                            &:hover {
                                outline: 1px solid $black;
                            }

                            &::before {
                                content: none;
                            }
                        }

                        @include media-breakpoint-down(xs) {
                            .carousel-control-prev,
                            .carousel-control-next {
                                display: none;
                            }
                        }
                    }
                }

                .productNameAndPriceContainer {
                    padding-left: 0;

                    .productNameAndPrice {
                        padding-left: 0;
                    }
                }

                .product-details-desktop {
                    padding-left: 6px;

                    @include media-breakpoint-only('md') {
                        padding: 0 0 0 10px;
                    }

                    @include media-breakpoint-down('xs') {
                        padding-left: 1px;
                        padding-right: 0;
                    }
                }

                .product-name {
                    @include fontSize($font-xsmall-desk);

                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: 4px;
                }

                .prices {
                    text-align: unset;

                    .price {
                        .sales {
                            @include fontSize($font-body1);

                            font-weight: 400;
                            line-height: 24px;
                        }

                        .strike-through {
                            margin-left: 6px;
                            text-decoration: none;
                            display: contents;
                            color: $bright-gray2;

                            .value {
                                @include fontSize($font-body2);

                                color: $silver-grey;
                                text-decoration: line-through;
                            }
                        }
                    }
                }

                .attributes {
                    padding-left: unset;
                    padding-right: unset;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    @include media-breakpoint-down('sm') {
                        justify-content: center;
                    }

                    @include media-breakpoint-only('md') {
                        padding-left: 10px;
                        padding-right: 0;
                    }

                    .attribute {
                        margin-top: 24px;

                        label {
                            @include fontSize($font-text);

                            font-weight: 600;
                        }

                        .selected-swatch-label {
                            margin-bottom: 6px;
                        }

                        .select-size {
                            .select-size-header {
                                p {
                                    margin-bottom: 6px;
                                }
                            }

                            .invalid-msg {
                                @include fontSize($font-body2);

                                display: none;
                                line-height: 20px;
                                color: $crimson;
                                top: 6px;
                            }
                        }

                        .bootstrap-select {
                            &.show {
                                .filter-option {
                                    box-shadow: 0 0 6px 0 #00000073 !important;
                                }
                            }
                        }

                        .select-color {
                            .custom-select {
                                &.quickViewSelect {
                                    .dropdown-toggle {
                                        padding-right: 0;
                                        box-sizing: content-box;
                                    }
                                }
                            }
                        }

                        .select-size,
                        .select-color {
                            .custom-select {
                                &.quickViewSelect {
                                    width: 333px;
                                    border: 1px solid $black;
                                    border-radius: 4px;
                                    background: none;

                                    @include media-breakpoint-down('md') {
                                        width: 100%;
                                    }

                                    .dropdown-toggle {
                                        background-color: unset;
                                        position: initial;
                                        color: $black;
                                        border: none;
                                        outline: none !important;
                                        box-shadow: none;

                                        &::before {
                                            border: none;
                                            background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTkuNDEyLjg4Mkw1IDUuMjk0LjU4OC44ODIiLz48L3N2Zz4=) no-repeat right;
                                            height: 7px;
                                            width: 14px;
                                            vertical-align: middle;
                                            background-position: unset;
                                        }

                                        &::after {
                                            display: none;
                                        }

                                        &:hover {
                                            background-color: unset;
                                            border: none;
                                        }

                                        &:focus-within {
                                            .filter-option {
                                                border: 1px solid;
                                            }
                                        }

                                        .filter-option {
                                            padding-right: 16px;

                                            .filter-option-inner {
                                                .filter-option-inner-inner {
                                                    display: flex;
                                                    align-items: center;
                                                    font-weight: 400;
                                                    letter-spacing: normal;

                                                    img {
                                                        border-radius: 50%;
                                                        width: 24px;
                                                        height: 24px;
                                                        margin-left: 2px;
                                                        box-shadow: 0 0 0 0.09375rem $gainsboro-gray;
                                                    }

                                                    .text-dark {
                                                        display: flex;

                                                        .name {
                                                            margin-left: 8px;

                                                            &.half-width {
                                                                max-width: 13ch;
                                                                overflow: hidden;
                                                                text-overflow: ellipsis;

                                                                @include media-breakpoint-down('xs') {
                                                                    max-width: 15ch;
                                                                }

                                                                &.quarter-width {
                                                                    max-width: 3ch;

                                                                    @include media-breakpoint-down('xs') {
                                                                        max-width: 6ch;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .stock-avail-msg {
                                                            color: $bright-gray;
                                                            padding-right: 8px;
                                                            margin-left: 2px;
                                                            display: flex;

                                                            &:not(.d-none) {
                                                                &::before {
                                                                    content: '-';
                                                                    margin-right: 2px;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .text-dark,
                                                    .stock-avail-msg {
                                                        font-weight: 400;
                                                        @include fontSize($font-text);

                                                        &:lang(ja),
                                                        &:lang(ko),
                                                        &:lang(zh) {
                                                            line-height: 48px;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &:lang(ja),
                                        &:lang(ko),
                                        &:lang(zh) {
                                            line-height: 48px;
                                        }
                                    }

                                    .dropdown-menu {
                                        padding-top: 0;

                                        .inner {
                                            max-height: inherit;
                                            max-width: 333px;
                                            overflow-wrap: break-word;
                                            overflow-x: hidden;

                                            @include media-breakpoint-down('md') {
                                                max-width: unset;
                                            }

                                            ul.dropdown-menu {
                                                li {
                                                    padding: 15px 0;
                                                    margin: 0 15px;
                                                    border-bottom: 1px solid $light-gray;

                                                    &:hover,
                                                    &.active {
                                                        background-color: $gainsboro;
                                                    }

                                                    &.price-groups {
                                                        &:hover {
                                                            background-color: $transparent;
                                                        }
                                                    }

                                                    a.opt,
                                                    a.dropdown-item {
                                                        padding-left: 8px;
                                                        height: unset !important;
                                                        width: unset !important;
                                                        display: block !important;

                                                        &.active,
                                                        &:active,
                                                        &:focus,
                                                        &:hover {
                                                            color: $black;
                                                            background: none;
                                                            outline: none !important;
                                                        }

                                                        .text {
                                                            display: flex;
                                                            align-items: center;

                                                            .text-dark {
                                                                margin-left: 15px;
                                                                white-space: normal;
                                                                @include fontSize($font-body1);

                                                                .stock-avail-msg {
                                                                    @include fontSize($font-body2);

                                                                    font-weight: 500;
                                                                    color: $bright-gray;

                                                                    &:lang(ja),
                                                                    &:lang(ko),
                                                                    &:lang(zh) {
                                                                        line-height: 48px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &.disabled {
                                                        .text {
                                                            color: $suva-grey;

                                                            .img-strike {
                                                                border-right: 1px solid $black;
                                                                height: 45px;
                                                                position: absolute;
                                                                transform: rotate(45deg);
                                                                left: 24px;
                                                            }

                                                            .text-dark {
                                                                color: $suva-grey !important;
                                                            }
                                                        }
                                                    }

                                                    &:last-child {
                                                        margin-bottom: 0;
                                                        border: none;
                                                    }

                                                    &.dropdown-divider {
                                                        display: none;
                                                    }

                                                    &.dropdown-header {
                                                        @include fontSize($font-body1);

                                                        color: $black;
                                                        line-height: 24px;
                                                        border-bottom: none;
                                                        padding-bottom: 8px;

                                                        .text {
                                                            .text-muted {
                                                                @include fontSize($font-body2);

                                                                color: $bright-gray;
                                                                text-decoration: line-through;
                                                                font-weight: 400;
                                                                margin-left: 4px;
                                                                padding-left: unset;
                                                            }
                                                        }
                                                    }

                                                    &.nolabel {
                                                        padding: 0;
                                                    }
                                                }
                                            }
                                        }

                                        .gradient {
                                            position: absolute;
                                            width: 95%;
                                            height: 80px;
                                            background: linear-gradient(to bottom, $transparent, $white);
                                            bottom: 0;
                                            pointer-events: none;
                                        }

                                        &::-webkit-scrollbar {
                                            width: 8px;
                                        }

                                        &::-webkit-scrollbar-thumb {
                                            background: $gainsboroNew;
                                            border-radius: 10px;
                                        }
                                    }

                                    &.default-opt {
                                        .filter-option-inner-inner {
                                            color: $newGrey;
                                        }
                                    }
                                }

                                &.show {
                                    .dropdown-toggle {
                                        &::before {
                                            transform: rotate(180deg);
                                        }
                                    }
                                }

                                &.invalid-state {
                                    border: 1px solid $red;
                                }
                            }
                        }

                        .select-size {
                            .custom-select {
                                &.quickViewSelect {
                                    .dropdown-menu {
                                        ul.dropdown-menu {
                                            li {
                                                &:first-child {
                                                    display: none;
                                                }

                                                &.disabled {
                                                    a {
                                                        .text {
                                                            text-decoration: line-through;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .swatch-circle {
                            background: unset;

                            @include media-breakpoint-down('md') {
                                margin-top: 0;
                                margin-bottom: 5px;
                                margin-left: 3px;
                            }

                            img {
                                width: 32px !important;
                                height: 32px !important;
                                border-radius: 50%;
                                box-shadow: 0 0 0 0.09375rem $gainsboro-gray;
                            }
                        }

                        .custom-select-btn {
                            &.one-size {
                                width: fit-content;
                                height: 36px;

                                input[type=radio]:disabled + label {
                                    &::before {
                                        width: 96px;
                                        -webkit-transform: rotate(-25deg);
                                        -moz-transform: rotate(-25deg);
                                        -ms-transform: rotate(-25deg);
                                        -o-transform: rotate(-25deg);
                                        transform: rotate(-25deg);
                                    }
                                }
                            }

                            input[type=radio] {
                                opacity: 0;
                            }

                            label {
                                font-weight: 600;
                                line-height: 23px;
                                margin: 0;
                                padding: 0 10px;
                                border: 1px solid $light-gray;
                                border-radius: 4px;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                @include fontSize($font-body1);

                                &::before,
                                &::after {
                                    content: none;
                                }

                                &:hover {
                                    background: $black;
                                    color: $white;
                                }
                            }

                            input[type=radio]:checked + label {
                                background: $black;
                                color: $white;
                            }

                            input[type=radio]:disabled + label {
                                pointer-events: none;
                                color: $medium-gray;
                                background: linear-gradient(to top left, $white calc(50% - 0.0625rem), #d5d5d5, $white calc(50% + 0.09375rem));
                            }
                        }
                    }

                    div[data-attr="color"] {
                        .attribute {
                            .select-color {
                                div.dropdown-menu {
                                    min-width: 101% !important;
                                    left: -10px !important;

                                    @include media-breakpoint-down('xs') {
                                        left: -4px !important;
                                    }
                                }

                                .color-group {
                                    &.price-groups {
                                        .pdp-swatches {
                                            .swatch-circle-container {
                                                .single-color-name {
                                                    margin-left: 5px;
                                                    margin-top: 8px;
                                                    font-weight: 400;
                                                    letter-spacing: normal;
                                                    text-align: left;
                                                    @include fontSize($font-text);

                                                    @include media-breakpoint-down('md') {
                                                        margin-left: 20px;
                                                        margin-top: 7px;
                                                    }

                                                    @include media-breakpoint-down('xs') {
                                                        margin-left: 12px;
                                                        margin-top: 2px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .single-color-div {
                                    .pdp-swatches {
                                        .swatch-circle-container {
                                            display: flex;
                                            border: none;
                                            pointer-events: none;

                                            img {
                                                padding: 3px;
                                                border: 1.5px solid $black;
                                            }
                                        }
                                    }

                                    @include media-breakpoint-down('xs') {
                                        .swatch-circle {
                                            img {
                                                width: 24px !important;
                                                height: 24px !important;
                                            }
                                        }
                                    }
                                }

                                .stock-avail-msg {
                                    &.lowStockSingleColor {
                                        background: $oasis;
                                        border: 1px solid $corn;
                                        padding: 10px;
                                        margin: 16px 0 10px;

                                        @include media-breakpoint-up('lg') {
                                            margin-top: -2px;
                                        }

                                        .msg {
                                            @include fontSize($font-body1);

                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    div[data-attr="size"],
                    div[data-attr="color"] {
                        width: 100%;

                        @include media-breakpoint-down('md') {
                            .size-color-container {
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            padding-top: 6px;
            padding-left: 10px;

            @include media-breakpoint-down('xs') {
                padding: 6px 0 0;
            }

            .add-to-bag-container {
                padding-left: 0;
                padding-right: 0;

                @include media-breakpoint-down('xs') {
                    margin: 0;
                }

                .add-to-bag-div {
                    padding-left: 0;
                    padding-right: 0;
                }

                .cart-and-ipay {
                    padding-bottom: 0;

                    @include media-breakpoint-down('xs') {
                        margin: 0;
                    }

                    .addToCart {
                        padding-left: 0;
                        padding-right: 0;

                        @include media-breakpoint-up('lg') {
                            padding-right: 10px;
                        }
                    }

                    .add-to-cart-global {
                        width: 100%;
                        min-height: 52px;
                        background-color: $crimson;
                        border: solid 1px $crimson;
                        border-radius: 4px;
                        line-height: 1;
                        margin-left: 0;
                        letter-spacing: 1.6px;
                        margin-bottom: 0;
                        color: $white;
                        font-size: 16px;

                        &:hover,
                        &:focus {
                            background-color: $cardinal;
                        }

                        &:focus {
                            outline: 2px solid $black !important;
                        }

                        &:disabled {
                            background: $silver-grey;
                            border: solid 1px $silver-grey;
                        }
                    }
                }
            }

            .global-availability {
                display: none;
            }
        }
    }

    .swatch-circle {
        &.color-value {
            &.selected::after {
                display: none;
            }
        }
    }
}

div[data-action=Search-Show] {
    #addedToCartModal {
        .modal-body {
            .modal-cart-details {
                .product-content-price {
                    .list-price {
                        text-decoration: line-through;
                    }
                }
            }

            .you-may-also-like {
                .recommendations {
                    .product-tile {
                        .tile-body {
                            max-height: unset;
                            height: unset !important;
                        }
                    }
                }
            }
        }
    }
}

.main-pdp-content {
    .quickViewLink {
        display: none;
    }
}
