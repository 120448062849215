.hero-images {
    .inner-image-wrapper {
        img {
            object-fit: cover;
        }
    }
}

.hero-images::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(419 / 1200 * 100%);
}

.hero-images::after {
    content: "";
    display: table;
    clear: both;
}

.hero-title {
    @include font(400, 26px, $white, 26px, normal);

    margin-bottom: 16px;
    margin-top: 8px;
    text-align: inherit;
}

.hero-subtext {
    color: $white;
    padding: 16px 10px 0;
    margin-bottom: 16px;
    text-align: inherit;
}

.hero-ctas {
    display: flex;
    flex-direction: column;

    .btn {
        width: 100%;
        margin: 0 auto;
        @include font(600, 16px, $black, 48px, 1.6px);
    }

    .cta-text-wrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.error-page {
    .hero-image-wrapper {
        .hero-images {
            margin: 0;
        }
    }

    .text-wrapper {
        width: 100%;
    }

    .hero-subtext {
        @include fontSize($font-body);

        padding: 0;

        @include media-breakpoint-up(lg) {
            @include fontSize($font-large);

            padding-top: 12px;
        }
    }
}

.text-wrapper {
    text-align: center;
}

.hero-image-wrapper {
    position: relative;

    .hero-overlay-slide {
        position: relative;
    }

    .hero-images {
        margin-left: -10px;
        margin-right: -10px;

        > .inner-image-wrapper {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    .slick-prev,
    .slick-next {
        background-color: $white;
        z-index: $zindex-lowest;
        opacity: 0.5;
        width: 28px;
        height: 35px;

        &:hover {
            background-color: $white;
        }
    }

    .slick-prev {
        left: 14px;
    }

    .slick-next {
        right: 14px;
    }

    img,
    .hero-video {
        width: 100%;
        min-height: 200px;
    }

    .slick-slide {
        img,
        .hero-video {
            padding: 0 4px;
        }
    }

    .slick-dots {
        bottom: -24px;
        height: 4px;
        width: calc(100% - 28px);
        margin-left: 14px;
        background-color: $light-gray;

        li {
            background-color: $light-gray;
        }

        li.slick-active {
            background-color: $shadyLady-gray;
        }
    }
}

.hero-slot-router-component {
    width: 100%;
    margin-bottom: 48px;

    .hero-title {
        color: $black;
        text-align: left;
        @include fontSize($font-xsmall);

        @include media-breakpoint-up('lg') {
            font-weight: 400;
            @include fontSize($font-xl);

            letter-spacing: normal;
            padding: 0;
            margin-right: 0;
            white-space: normal;
        }
    }

    .hero-link-wrapper {
        ul {
            display: flex;
            overflow-x: scroll;
            margin-bottom: 16px;
            background: $white;
            margin-left: -10px;
            margin-right: -10px;
            padding: 0 10px;

            li {
                @include font(400, 16px, $black, 16px, normal);

                white-space: nowrap;
                padding: 8px 0;
                margin-right: 32px;

                a {
                    @include underlineOnHover(currentColor, 3px, 1.5);
                    @include semiboldOnActive();
                }

                &:last-child {
                    padding-right: 10px;
                }
            }
        }

        a.saleRed {
            color: #d21e2f !important;
        }
    }

    .hero-image-wrapper {
        .hero-ctas {
            margin: 24px 10px 0;

            .btn {
                margin-top: 8px;
                @include fontSize($font-body3);

                font-weight: 600;
            }
        }
    }

    .hero-subtext {
        color: $black;
        padding-top: 16px;
        padding-bottom: 0;
        margin-bottom: 0;
        line-height: 20px;
        @include media-breakpoint-up(lg) {
            line-height: 1.11111;
        }
    }
}

.hero-overlay-component,
.hero-no-overlay-component {
    width: 100%;
    margin-bottom: 48px;

    .hero-image-wrapper {
        position: relative;
    }

    .text-wrapper {
        position: absolute;
        color: white;
        z-index: $zindex-lowest;
    }

    .hero-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background: black;
        opacity: 0.45;
        z-index: $zindex-zero;
    }

    .hero-ctas {
        margin: 32px 10px 0;

        .btn {
            text-align: center;
            width: 100%;
            border-radius: 0;
            margin-top: 8px;
            font-weight: 600;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.hero-no-overlay-component {
    .hero-title,
    .hero-subtext {
        color: $black;
        text-align: center;

        @include media-breakpoint-down(md) {
            text-align: left !important;
        }
    }
}

.refinement-hero-banner {
    margin-bottom: 40px;

    @include media-breakpoint-down (sm) {
        margin-bottom: 12px;
    }
}

@include media-breakpoint-up(lg) {
    .inner-image-wrapper {
        position: relative;
    }

    .hero-ctas {
        position: absolute;

        &.bottomLeft {
            bottom: 32px;
            left: 32px;
        }

        &.bottomCenter {
            bottom: 32px;
            right: 50%;
            transform: translateX(50%);
        }

        &.bottomRight,
        &.null {
            bottom: 32px;
            right: 32px;
        }

        &.middleLeft {
            top: 50%;
            left: 32px;
            transform: translateY(-50%);
        }

        &.middleCenter {
            top: 50%;
            right: 50%;
            transform: translate3d(50%, -50%, 0);
        }

        &.middleRight {
            top: 50%;
            right: 32px;
            transform: translateY(-50%);
        }

        &.topLeft {
            top: 32px;
            left: 32px;
        }

        &.topCenter {
            top: 32px;
            left: 50%;
            transform: translateX(-50%);
        }

        &.topRight {
            top: 32px;
            right: 32px;
        }

        .btn {
            width: 25%;
            margin: 0;
        }
    }

    .text-wrapper {
        &.left {
            left: 64px;

            .hero-title,
            .hero-subtext {
                text-align: left;
            }
        }

        &.right {
            right: 64px;

            .hero-title,
            .hero-subtext {
                text-align: right;
            }
        }

        &.center {
            left: 50%;
            transform: translate(-50%);

            .hero-title,
            .hero-subtext {
                text-align: center;
            }
        }
    }

    .hero-title {
        @include font(400, 48px, $white, 52px, normal);

        padding: 0;
        margin-right: 0;
        margin-bottom: 38px;
        white-space: normal;
    }

    .hero-subtext {
        color: $white;
    }

    .hero-image-wrapper {
        .slick-prev,
        .slick-next {
            width: 52px;
            height: 65px;
        }

        .slick-prev {
            left: 4px;
        }

        .slick-next {
            right: 4px;
        }

        .slick-slide {
            img,
            .hero-video {
                padding: 0;
            }
        }

        .slick-dots {
            bottom: -24px;
            height: 4px;
            margin: 0;
            overflow: auto;
            width: 100%;

            li {
                padding: 0;
                margin: 0;

                &:last-child {
                    margin: 0;
                }
            }
        }

        .btn {
            width: 100%;
            @include font(600, 16px, $black, 48px, 1.6px);
        }

        .hero-images {
            margin-left: 0;
            margin-right: 0;
        }

        img,
        .hero-video,
        .hero-images {
            min-height: 200px;
        }
    }

    .hero-slot-router-component {
        margin-top: 0;
        margin-bottom: 80px;

        .hero-link-wrapper {
            ul {
                display: block;
                overflow-x: auto;
                background: $transparent;
                margin-left: 0;
                margin-right: 0;
                padding: 0;

                li {
                    @include font(400, 20px, $black, 20px, normal);

                    padding: 0;
                    margin: 0 0 18px;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        .hero-title {
            padding-top: 6px;
            margin-top: 0;
            line-height: 52px;
            margin-bottom: 32px;
            margin-top: 24px;
            text-align: left;
        }

        .hero-subtext {
            text-align: left;
            max-width: 70%;
            padding: 26px 0 0;
        }

        .hero-image-wrapper {
            position: relative;

            .slick-prev,
            .slick-next {
                width: 52px;
                height: 65px;
            }

            .hero-ctas {
                margin-top: 0;
            }
        }

        .btn {
            border-radius: 0;
            line-height: 48px;
            font-weight: 600;
            margin-bottom: 0;
            margin-top: 32px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .hero-overlay-component,
    .hero-no-overlay-component {
        position: relative;
        margin-bottom: 80px;

        .text-wrapper {
            margin: 28px 32px;
            text-align: left;
            max-width: 90%;

            .hero-title {
                margin-bottom: 20px;
            }
        }

        .hero-ctas {
            margin: 0 10px;

            .btn {
                border-radius: 0;
                line-height: 48px;
                margin-bottom: 0;
                font-weight: 600;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .hero-no-overlay-component {
        .hero-title {
            margin-bottom: 16px;
            text-align: left;
        }

        .hero-images {
            position: relative;
        }

        .hero-subtext {
            padding-left: 0;
            text-align: left;
        }
    }
}

.img-and-cta {
    a {
        position: relative;
        display: block;
    }
}

.overlay-text-container {
    position: absolute;
    top: 0;
    height: 50%;
    width: 100%;

    @include media-breakpoint-down (sm) {
        width: 100vw;
    }

    h2 {
        color: $white;
        margin: 40px;
        line-height: 56px;
        text-align: left;
        @include fontSize($font-large-desk);

        @include media-breakpoint-down (sm) {
            margin: 10px;
            line-height: 24px;
            @include fontSize($font-xsmall);
        }
    }

    .gradient-background {
        height: 100%;
        display: flex;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
    }

    &.topLeft-nowrap,
    &.topLeft-wrap,
    &.bottomLeft-nowrap,
    &.bottomLeft-wrap {
        .gradient-background {
            justify-content: flex-start;
        }
    }

    &.topLeft-wrap,
    &.bottomLeft-wrap,
    &.topRight-wrap {
        height: 100%;

        h2 {
            max-width: 320px;

            @include media-breakpoint-down (sm) {
                max-width: 100px;
            }
        }
    }

    &.topRight-nowrap,
    &.topRight-wrap {
        .gradient-background {
            justify-content: flex-end;
        }
    }

    &.bottomLeft-nowrap,
    &.bottomLeft-wrap {
        bottom: 0;
        top: unset;

        .gradient-background {
            align-items: end;
        }
    }

    &.topLeft-nowrap {
        .gradient-background {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
        }
    }

    &.topLeft-wrap {
        .gradient-background {
            background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0.09%, rgba(0, 0, 0, 0.56) 100%);
        }
    }

    &.topRight-nowrap {
        .gradient-background {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
        }
    }

    &.topRight-wrap {
        .gradient-background {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0.09%, rgba(0, 0, 0, 0.56) 100%);
        }

        h2 {
            text-align: right;
        }
    }

    &.bottomLeft-nowrap {
        .gradient-background {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
        }
    }

    &.bottomLeft-wrap {
        .gradient-background {
            background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0.09%, rgba(0, 0, 0, 0.56) 100%);
        }
    }
}

.slick-slider {
    &.slick-initialized {
        .overlay-text-container {
            @include media-breakpoint-down (sm) {
                width: 95vw;
                padding: 0 1vw;
            }
        }
    }
}

.search-results {
    .overlay-text-container {
        @include media-breakpoint-down (sm) {
            padding: 0;
        }

        h2 {
            margin: 10px;
            line-height: 48px;
            @include fontSize($font-medium-desk);

            @include media-breakpoint-down (sm) {
                line-height: 24px;
                @include fontSize($font-xsmall);
            }
        }

        &.topLeft-wrap,
        &.bottomLeft-wrap,
        &.topRight-wrap {
            h2 {
                max-width: 250px;

                @include media-breakpoint-down (sm) {
                    max-width: 100px;
                }
            }
        }

        &.topLeft-nowrap,
        &.bottomLeft-nowrap,
        &.topRight-nowrap {
            height: 75%;

            @include media-breakpoint-down (sm) {
                height: 50%;
            }
        }
    }

    .masonry-component {
        .overlay-text-container {
            height: calc(100% - 16px);
            bottom: 16px;

            @include media-breakpoint-down (sm) {
                height: calc(100% - 32px);
                width: 90vw;
                bottom: 32px;
            }

            &.topLeft-nowrap,
            &.bottomLeft-nowrap,
            &.topRight-nowrap {
                height: calc(75% - 12px);

                @include media-breakpoint-down (sm) {
                    height: calc(50% - 16px);
                }
            }
        }
    }
}
