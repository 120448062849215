@mixin button(
    $bg: $white,
    $color: $black,
    $border-color: $black,
    $hover-bg: $white,
    $hover-color: $black,
    $hover-border-color: $black,
    $active-bg: $white,
    $active-color: $black,
    $active-border-color: $black
) {
    @if $bg {
        background-color: $bg;
    }
    @if $color {
        color: $color;
    }
    @if $border-color {
        border-color: $border-color;
    }

    &:hover {
        @if $hover-bg {
            background-color: $hover-bg;
        }
        @if $hover-color {
            color: $hover-color;
        }
        @if $hover-border-color {
            border-color: $hover-border-color;
        }
    }

    &:active {
        @if $active-bg {
            background-color: $active-bg;
        }
        @if $active-color {
            color: $active-color;
        }
        @if $active-border-color {
            border-color: $active-border-color;
        }
    }
}
