$rem30: 30rem;
$rem48: 48rem;
$rem62: 62rem;

.lll-text-button {
    font-size: 16px;
    font-weight: 600;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
}

.button-qfnRT {
    background-color: $white;
    border: 0.0625rem solid;
    border-radius: 0.25rem;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    text-decoration: none;

    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }

    &:focus {
        outline: 0.0625rem dotted $suva-grey;
        outline: 0.3125rem auto -webkit-focus-ring-color;
    }
}

.buttonPrimary-1m-xO {
    background-color: $crimson;
    border-color: $crimson;
    color: $white;
    border-radius: 0.25rem;

    &:hover {
        background-color: $cardinal;
        border-color: $cardinal;
    }

    &:active {
        background-color: $falu-red;
        border-color: $falu-red;
    }

    &.disabled,
    &:disabled {
        background-color: $silver-grey;
        border-color: $silver-grey;
    }
}

.buttonSecondary-1ZKFx {
    background-color: $black;
    border-color: $black;
    color: $white;

    &:hover {
        background-color: $matterhorn;
        border-color: $matterhorn;
    }

    &:active {
        background-color: $nightrider-grey;
    }

    &:disabled {
        background-color: $silver-grey;
        border-color: $silver-grey;
    }
}

.dropdown-RDgCJ {
    position: relative;
}

.label-27Swm {
    display: block;
    margin-bottom: 0.375rem;
    @include fontSize($font-text);
}

.backdrop-3sgcn {
    -webkit-animation: 0.3s linear forwards;
    animation: 0.3s linear forwards;
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 106;
    -webkit-animation-name: lll-fadeIn;
    animation-name: lll-fadeIn;
}

.modalContainer-1wlkX {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    overflow: auto;
    padding: 1.5rem 1.5rem 6.25rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 106;
}

.modal-1HN_J {
    max-width: 22.1875rem;
    padding-bottom: 2rem;
    text-align: left;

    @media only screen and (min-width: $rem30) {
        max-width: 26.5rem;
    }
}

.modal-2BrZH {
    align-self: flex-start;
    -webkit-animation: 0.2s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
    animation: 0.2s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
    background: $white;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: auto;
    margin-top: auto;
    max-width: 21rem;
    padding: 2.25rem 1.25rem 1.25rem;
    position: relative;
    width: 100%;
    z-index: 107;
    -webkit-animation-name: lll-fadeInUp;
    animation-name: lll-fadeInUp;
}

.closeButton-1yRs1 {
    background-color: $transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0.75rem;
    position: absolute;
    right: 0;
    top: 0;
}

.title-2cXAU {
    font-size: 1.5625rem;
    font-weight: 600;
    line-height: 1.22;
    margin: 0;

    @media only screen and (min-width: $rem48) {
        font-size: 1.75rem;
        line-height: 1.14;
    }
}

.lll-text-left {
    text-align: left;
}

.content-1jTDu {
    line-height: 1.23529;
    margin-top: 0.5rem;
    @include fontSize($font-body2);

    > * {
        margin: 0;
    }

    > * + * {
        margin-top: 0.75em;
    }

    @media only screen and (min-width: $rem62) {
        line-height: 1.5625;
        font-size: 16px;
    }
}

#orderErrorModal {
    .modal-2BrZH {
        max-width: 424px;
        padding: 24px;
    }

    .icon {
        width: 18px;
        height: 18px;
    }

    .title-2cXAU {
        line-height: 32px;

        @media only screen and (max-width: $rem48) {
            line-height: 28px;
        }
    }

    .content-1jTDu {
        line-height: 20px;
    }
}

.lll-text-button,
.lll-text-subtitle {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
}

.primaryCta-1bRYX {
    margin-top: 0.75rem;
}

.buttonTertiary-3Og1R {
    background-color: $white;
    border-color: $black;
    color: $black;

    &:hover {
        background-color: $blackCurrent;
        color: $white;
    }

    &:active {
        background-color: $black;
        color: $white;
    }
}

.buttonBlock-3QinT {
    display: flex;
    width: 100%;
}

.secondaryCtaBlock-Y8e2s {
    text-align: center;
}

.secondaryCta-25wwC {
    background: transparent;
    border: 0;
    margin-top: 1rem;
    padding: 0 0 0.125rem;
    @include fontSize($font-body1);
}

.lll-link-primary {
    color: currentColor;
    cursor: pointer;
    padding-bottom: 0.25rem;
    position: relative;
    text-decoration: none;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }

    &::before {
        background-color: $gainsboro-gray;
        bottom: 0;
        content: "";
        height: 0.0625rem;
        left: 0;
        position: absolute;
        width: 100%;

        &:hover {
            background-color: $crimson;
        }
    }

    @supports (text-underline-offset:1rem) {
        position: static;
        text-decoration: underline;
        -webkit-text-decoration-color: $gainsboro-gray;
        text-decoration-color: $gainsboro-gray;
        text-decoration-thickness: 0.0625rem;
        text-underline-offset: 0.375rem;

        &::before {
            display: none;
        }

        &:hover {
            -webkit-text-decoration-color: $crimson;
            text-decoration-color: $crimson;
        }
    }
}

.ui-selectmenu-button {
    background-color: $white;
    color: $black;
    border: 1px solid $black;
    border-radius: 4px;
    padding: 16px;

    &.ui-selectmenu-button-open {
        box-shadow: none !important;

        .ui-selectmenu-icon {
            transform: rotate(180deg);
            transition: all 0.2s ease-out;
        }
    }

    &.ui-button {
        width: 100%;
        height: 50px;
    }

    &:focus,
    &:hover {
        background-color: $white;
        color: $black;
        border: 1px solid $black;
    }

    &:focus {
        box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
        outline: none;
    }

    .ui-selectmenu-text {
        line-height: 18px; // if set to 23px quantity will not align with each and total
        font-size: 16px;

        &:lang(ja),
        &:lang(ko) {
            line-height: 18px; // if set to 23px quantity will not align with each and total
        }
    }

    .ui-selectmenu-icon {
        background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTkuNDEyLjg4Mkw1IDUuMjk0LjU4OC44ODIiLz48L3N2Zz4=) no-repeat 100% !important;
        background-size: 14px 10px !important;
        position: absolute;
        right: 16px;
        top: 18px;
    }
}

.ui-selectmenu-menu {
    .ui-menu {
        background-color: $white;
        border: 1px solid $black;
        border-radius: 4px;
        box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
        list-style: none;
        margin-top: 4px;
        max-height: 23.4375rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 2px;

            &-track {
                background-color: $transparent;
                border-radius: 1px;
            }

            &-thumb {
                background-color: $silver;
                border-radius: 1px;
            }
        }

        .ui-menu-item {
            position: relative;

            + .ui-menu-item {
                margin-top: 0.0625rem;

                &::before {
                    border-top: 0.0625rem solid $dust-gray;
                    content: "";
                    display: block;
                    left: 1rem;
                    position: absolute;
                    right: 1rem;
                    top: -0.0625rem;
                }
            }
        }

        .ui-menu-item-wrapper {
            padding: 20px 24px 20px 32px;
            position: relative;
            transition: background-color 0.3s ease-out;
            @include fontSize($font-text);

            &.ui-state-active {
                background-color: $gainsboro;
                border: none;
                color: $black;
                font-weight: normal;
                margin: 0;
            }
        }
    }
}

@-webkit-keyframes lll-fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes lll-fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes lll-fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(3.125rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes lll-fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(3.125rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.invalid-feedback.hintError-3CNVs {
    color: #d22030;
    padding: 0;
    margin-top: 6px;
    background: $white;
    font-size: 16px;
}

.hintError-3CNVs {
    color: #d22030;
    padding: 0;
    margin-top: 6px;
    background: $white;
    font-size: 16px;
}

.tagWrapper-2SDIg {
    position: relative;
}

.iconError-VnY5J {
    color: $red;
    display: none;
}

.iconContainer-3l9gt {
    border: 0;
    border-radius: 0.25rem;
    bottom: 0.0625rem;
    padding: 0.875rem 0.9375rem 1rem 1rem;
    pointer-events: none;
    position: absolute;
    right: 0.0625rem;
    top: 0.0625rem;

    &:focus {
        outline: none;
    }

    &.showHidePasswordWrapper-A_5sS {
        background-color: #fff;
        cursor: pointer;
        padding: 0.875rem;
        pointer-events: auto;
    }

    .focus-2S3-X {
        background: none;
        border: none;
        cursor: pointer;
    }
}

.form-control.input-2-hFT {
    background-color: $white;
    background-position: center right 1rem;
    background-repeat: no-repeat;
    border: 0.0625rem solid $black !important;
    border-radius: 0.25rem;
    display: block;
    height: 3.25rem !important;
    padding: 0.875rem 1rem;
    width: 100%;
    @include fontSize($font-text);

    &:focus {
        box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
        outline: none;
    }

    &.is-invalid {
        border-color: $red !important;
        padding-right: 3rem;

        &:focus {
            box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
            outline: none;
        }

        ~ .iconError-VnY5J {
            display: block;
        }
    }
}

.notificationBlock-1RRMY {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1.2631578947;
    display: flex;
    padding: 0.75rem;
    @include fontSize($font-body1);

    &.successNotification-1HovB {
        background-color: $skeptic;
        border: 0.0625rem solid $salem-green;

        .iconBlock-1wZMf {
            color: $salem-green;
            flex-shrink: 0;
            height: 1.5rem;
            margin-right: 0.5rem;
            width: 1.5rem;
        }
    }
}

.tablistWrapper-1I3Wi {
    margin-bottom: 1rem;
    position: relative;
    width: 100%;

    &::before {
        background-color: #e5e6e7;
        bottom: 0;
        content: "";
        height: 0.125rem;
        left: 0;
        position: absolute;
        width: 100%;
    }
}

.tabScrollerWrapper-2Hni_ {
    position: relative;
    width: 100%;
}

.tabList-2X-kq {
    display: flex;
    height: auto;
    justify-content: flex-start;
    overflow: scroll;
    -ms-overflow-style: none;
    position: relative;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.tabListDraggable-2cKsh {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;

    &:focus {
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }
}

.tabButtonWrapper-1otvU {
    display: inline-flex;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    position: relative;
}

.tabButton-pNDJp {
    font-size: 1.0625rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1.1764705882;
    background: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    margin: 0;
    padding: 0 0 0.625rem;
    position: relative;
    white-space: nowrap;
    letter-spacing: normal;

    &.tabButtonActive-2_a7R::after {
        background-color: $fireEngine-red;
        bottom: 0;
        content: "";
        display: block;
        height: 0.125rem;
        left: 0;
        position: absolute;
        right: 100%;
        top: auto;
        transition: right 0.25s ease-in-out;
        width: 100%;
    }

    &:focus {
        outline: 0.3125rem auto -webkit-focus-ring-color !important;
    }
}

.tabButtonActive-2_a7R {
    font-weight: 600;
}
