@import '../mixins/utils';
@import '../mixins/fonts';
@import '../global/fontVariables';
@import '../mixins/fontProp';

.custom-tooltip {
    display: inline-block;
    position: relative;
    opacity: 1;

    &:hover {
        cursor: pointer;

        .bottom {
            visibility: visible;
            opacity: 1;
        }
    }

    .info-icon::after {
        margin-top: 0;
    }

    .bottom {
        min-width: 181px;
        top: 24px;
        left: 50%;
        transform: translate(-80%, 0);
        padding: 8px 12px;
        color: $black;
        background-color: $white;
        line-height: 20px;
        position: absolute;
        z-index: $zindex-highest;
        box-sizing: border-box;
        border: 1px solid $light-gray;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.8s;
        font-size: 16px;

        i {
            position: absolute;
            bottom: 100%;
            right: 0;
            margin-left: -12px;
            width: 24px;
            height: 12px;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                transform: translate(-50%, 50%) rotate(45deg);
                background-color: $white;
                border: 1px solid $light-gray;
            }
        }
    }
}
