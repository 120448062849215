body.modal-open-custom {
    overflow-y: scroll;
    padding-right: 0 !important;
}

body.modal-with-backdrop {
    overflow: hidden;
}

.modal-backdrop {
    &.show-backdrop {
        transition: all 0.8s ease-in-out;
        opacity: 0.3 !important;
    }

    &.hide-backdrop {
        transition: all 0.8s ease-in-out;
        opacity: 0 !important;
    }
}

.email-popup-submitted {
    padding: 0 20px 25px;

    .submit-title {
        font-weight: 600;
        line-height: 28px;
        margin: 0;
        padding: 16px 0;
        @include fontSize($font-xsmall-desk);
    }

    .description {
        p {
            text-align: center;
            margin: 0;
            font-weight: 400;
            line-height: 24px;
            @include fontSize($font-body1);
        }
    }

    .close {
        float: none;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: normal;
        border-bottom: 1px solid $black;
        margin-top: 28px;
        padding-bottom: 5px;
        @include fontSize($font-body2);

        &:hover {
            border-color: $red;
            opacity: 1 !important;
        }
    }
}

.modal-open .email-signup-popup {
    overflow: visible;
}

.email-signup-popup {
    justify-content: center;
    padding-right: unset !important;
    font-size: 16px;

    &.email-signup-popup {
        @include fontSize($font-body3);
    }

    .signup-action-section {
        margin-bottom: 0;
    }

    .close {
        &:focus {
            outline: none !important;
        }
    }

    .privacy-policy-section {
        padding: 8px 24px 0;
        color: $dark-gray;
        margin-bottom: 0;
        @include fontSize($font-body3);

        a {
            color: $dark-gray;
        }

        @include media-breakpoint-up(sm) {
            padding: 8px 24px 0;
        }
    }

    .modal-dialog {
        display: flex;
        align-items: flex-start;
        position: fixed;
        margin: 0;
        width: 100%;
        transition: all 0.5s ease-in-out;
        opacity: 0;
        pointer-events: unset;

        @include media-breakpoint-down(sm) {
            align-items: center;
            width: calc(100% - 20px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
        }

        @include media-breakpoint-up('sm') {
            width: 448px;
            padding: unset;
        }
    }

    &:not(.emailSignupToasterTriggered) {
        .modal-dialog {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) !important;
            opacity: 0;
        }

        &.show {
            .modal-dialog {
                bottom: 50%;
                opacity: 1;
            }
        }
    }

    &.emailSignupToasterTriggered {
        @include media-breakpoint-up(sm) {
            width: 0;
        }

        .modal-dialog {
            opacity: 0;

            @include media-breakpoint-up(sm) {
                bottom: 0;
                bottom: -500px;
                right: 24px;
                transform: none !important;
            }

            @include media-breakpoint-down(sm) {
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%) !important;
                opacity: 0;
            }
        }

        &.show {
            .modal-dialog {
                @include media-breakpoint-up(sm) {
                    right: 24px;
                    bottom: 24px;
                }

                opacity: 1;

                @include media-breakpoint-down(sm) {
                    bottom: 50%;
                }
            }
        }
    }

    &.show {
        display: flex !important;

        .modal-dialog {
            @include media-breakpoint-down(sm) {
                bottom: 50%;
            }

            opacity: 1;

            .email-signup-body-content {
                top: 0;
            }

            .email-signup-body {
                position: relative;
                top: 0;
                opacity: 1;
                transition: all 0.5s ease-in-out;
                overflow-y: scroll;
                padding-right: 0 !important;

                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    background: $gainsboro-gray;
                    border-radius: 10px;
                }
            }

            &.form-submitted {
                .email-signup-body {
                    top: 100px;
                    opacity: 0;
                }
            }
        }

        .modal-content {
            .modal-header {
                .header-img-overlay {
                    .logo-and-title {
                        bottom: 0;
                    }
                }
            }
        }
    }

    .popup-kr {
        @include media-breakpoint-up('sm') {
            right: 35px;
        }
    }

    .custom-checkbox input[type=checkbox]:checked + label::before {
        background: $bright-gray;
        border: 0;
        top: 3px;
    }

    .custom-checkbox label::before {
        top: 3px;
    }

    .custom-checkbox input[type=checkbox]:checked + label::after {
        background: $bright-gray;
        top: 7px;
    }

    .custom-checkbox label {
        line-height: 24px;
        @include fontSize($font-body1);
    }

    .modal-content {
        background-clip: border-box;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        max-height: calc(100vh - 40px);

        .modal-header {
            padding: 0;
            border-bottom: 0;
            border-radius: 4px 4px 0 0;
            position: relative;
            justify-content: center;
            overflow: hidden;
            max-height: 155px;

            @include media-breakpoint-up(sm) {
                max-height: 200px;
                min-height: 200px;
            }

            @include media-breakpoint-down(md) {
                min-height: 100%;
            }

            .email-popup-img {
                width: 100%;
            }

            .header-img-overlay {
                position: absolute;
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);

                .logo-and-title {
                    padding: 0 24px 24px;
                    position: absolute;
                    bottom: -90px;
                    transition: all 0.7s ease-in-out;
                    justify-content: center;
                }
            }

            .icon-logo {
                fill: $red;
                width: 34px;
                height: 34px;
                margin: 0 auto;
                display: block;
            }

            .close {
                position: absolute;
                right: 16px;
                top: 16px;
                padding: 8px 16px 0 0;

                .icon-close {
                    width: 16px;
                    height: 16px;
                }
            }

            p.title {
                color: $white;
                font-weight: 600;
                text-align: center;
                line-height: 32px;
                margin-bottom: 0;
                padding-top: 8px;
                @include fontSize($font-medium);

                @include media-breakpoint-up(sm) {
                    line-height: 48px;
                    @include fontSize($font-medium-desk);
                }
            }
        }

        .email-signup-text-component {
            text-align: center;
            margin: 16px 0;

            p.submit-title {
                margin: 0;
                font-weight: 600;
            }

            p.sub-text {
                margin: 0;
                font-weight: 600;
                line-height: 24px;
                @include fontSize($font-xsmall);

                @include media-breakpoint-up(sm) {
                    line-height: 28px;
                    @include fontSize($font-xsmall-desk);
                }
            }
        }

        .email-signup-body-content {
            position: relative;
            top: 100px;
            transition: all 0.7s ease-in-out;
            padding-bottom: 32px;
        }

        .email-popup-form-container {
            .form-group {
                .form-check-input:invalid ~ .form-check-label {
                    color: $black;
                }

                .form-check-input:required ~ .form-check-label {
                    .checkbox-label {
                        &::after {
                            content: "*";
                            color: $black;
                            padding-left: 3px;
                        }
                    }
                }

                .form-check-label {
                    .checkbox-label {
                        @include fontSize($font-body1);
                    }
                }

                .invalid-feedback {
                    color: $black;
                    margin-top: 8px;

                    &::before {
                        content: "!";
                        border: 2px solid $crimson;
                        border-radius: 50%;
                        color: $crimson;
                        padding: 0 9px;
                        margin-right: 4px;
                        font-weight: 500;
                        @include fontSize($font-body2);
                    }
                }
            }
        }

        .email-address-section {
            .form-control {
                border-radius: 4px;
                height: calc(2em + 0.75rem + 2px);
                padding: 0.88rem 0.75rem;
                line-height: 1.5;
                @include fontSize($font-body1);

                &:not(.is-invalid) {
                    border: 1px solid $black;
                }
            }
        }

        .modal-title {
            @include font(400, 26px, $black, 27px, normal);

            margin: 8px auto 12px;
            text-align: center;
        }

        .email-signup-form-popup {
            .subscribe-women-men-category {
                text-align: center;
            }
        }

        .modal-body {
            @include font(400, 16px, $black, 22px, normal);

            margin: 0 auto 32px;
            padding: 0 5px;
            text-align: center;
        }

        .modal-buttons {
            .btn {
                @include font(null, 14px, null, null, null);

                margin-bottom: 16px;
                width: 100%;
                text-transform: uppercase;
            }
        }

        button.btn.btn-block.btn-submit.signup-popup-btn {
            background-color: $red;
            color: $white;
        }

        @include media-breakpoint-up('sm') {
            border: 0;

            .modal-title {
                @include font(400, 36px, $black, 27px, 0.2px);

                margin: 20px auto 16px;
            }

            .modal-body {
                @include font(400, 20px, $black, 22px, normal);

                padding: 0 64px;
                margin-bottom: 36px;
            }

            .modal-buttons {
                .btn {
                    @include font(600, 16px, null, null, null);

                    width: 300px;
                    margin: 0 8px 16px;
                }
            }
        }

        .email-signup-text-component,
        .email-popup-form-container {
            padding: 0 24px;
        }
    }
}
