select {
    appearance: none;
    background-repeat: no-repeat;
    background-position: center right;
    border: 1px solid $black;
    border-radius: 0;
    padding: 16px;
}

input {
    padding: 12px;
    border: 1px solid $medium-gray;

    &:focus {
        outline: none;
        border-color: $black;
    }

    &.error,
    &.is-invalid {
        color: $red;
    }
}

.alert.error-message {
    color: $red;
    margin: 0 0 32px;
    border: 1px solid $red;

    .error-message-text {
        color: $black;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 32px;
        align-items: center;
        line-height: 24px;
        @include fontSize($font-body1);
    }
}

#paypalContent {
    .alert.error-message {
        margin-top: -24px;
    }
}

[data-method-id="Adyen_PayPal"] {
    #paypalContent {
        .alert.error-message {
            margin-top: 0;
        }
    }
}

.required-indicator {
    position: relative;
    bottom: 4px;
    margin: 4px;
    vertical-align: baseline;
    color: $red;
}

.custom-select {
    font-weight: 600;
    color: $black;
    border: 0;
    line-height: 1.25;
    float: right;
    padding: 0 32px 0 8px;
    width: initial;
    height: auto;
    background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTkuNDEyLjg4Mkw1IDUuMjk0LjU4OC44ODIiLz48L3N2Zz4=) no-repeat right;
    background-size: 14px 10px;
    white-space: nowrap;
    font-size: 16px;

    &:focus {
        box-shadow: none;
        color: $black;
        background-color: transparent;
    }
}

.sort-by {
    position: relative;
    top: 0;
    padding-right: 10px;
    font-weight: 400;
    white-space: nowrap;
}

.custom-checkbox {
    input[type='checkbox'] {
        opacity: 0;
    }

    label {
        position: relative;
        display: inline-block;
        padding-left: 24px;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            content: '';
            display: inline-block;
            cursor: pointer;
        }

        &::before {
            height: 16px;
            width: 16px;
            border: 1px solid;
            left: 0;
            top: 1px;
        }

        &::after {
            height: 5px;
            width: 9px;
            border-left: 2px solid;
            border-bottom: 2px solid;
            transform: rotate(-45deg);
            left: 4px;
            top: 6px;
        }

        &.no-wrap {
            @include media-breakpoint-down(xs) {
                white-space: nowrap;
            }
        }
    }

    input[type='checkbox'] {
        + label::after {
            content: none;
        }

        &:checked + label::after {
            content: '';
            background-color: $black;
            color: $white;
        }

        &:checked + label::before {
            background-color: $black;
        }

        &:focus + label::before {
            // for accessibilty
            outline: rgb(59, 153, 252) auto 5px;
        }
    }
}

[type='radio']:checked,
[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $black;
}

[type='radio']:checked + label::before,
[type='radio']:not(:checked) + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid $black;
    border-radius: 100%;
    background: $white;
}

[type='radio']:checked + label::after,
[type='radio']:not(:checked) + label::after {
    content: '';
    width: 10px;
    height: 10px;
    background: $black;
    position: absolute;
    top: 13px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

@include media-breakpoint-up(lg) {
    [type='radio']:checked + label::after,
    [type='radio']:not(:checked) + label::after {
        top: 3px;
    }
}

[type='radio']:not(:checked) + label::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type='radio']:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.info-icon {
    .icon {
        border-radius: 50%;
        background-color: $black;
        width: 12px;
        height: 12px;
        font-size: 11px;
        text-align: center;
        color: #fff;
        font-weight: 600;
    }
}

.invalid-feedback {
    display: none;
}

.alert-danger {
    border: none;
    border-radius: 0;
    width: 100%;
}

div.alert-danger,
.invalid-feedback,
p.alert-danger {
    margin-bottom: 0;
    color: $cardinal-red;
    padding: 14px;
    background: $linen-red;
    margin-top: 4px;
    line-height: 1.33;
    position: relative;
    text-align: left;
    box-shadow: none;
    width: auto;
    @include fontSize($font-text);

    .error-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 12px;
        left: 12px;
    }
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

.info-icon {
    .icon {
        display: none;
    }

    &::after {
        content: ' ';
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAABCFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAgIDAwMFBQUGBgYICAgMDAwNDQ0XFxcZGRkbGxseHh4iIiIlJSUmJiYoKCg3Nzc6Ojo9PT1AQEBISEhJSUlKSkpOTk5SUlJWVlZYWFhgYGBkZGRmZmZpaWlqampra2tzc3N8fHx+fn6BgYGFhYWKioqdnZ2enp6xsbG6urq9vb3Dw8PFxcXHx8fJycnLy8vT09PW1tbc3Nzg4ODo6Ojq6uru7u7w8PDy8vL39/f5+fn6+vr7+/v8/Pz9/f3+/v7///+iOT0CAAAAFnRSTlMAAQIJCh0lP05ZY3JzeZ2rrM/k5urw7ZHFxQAAAX5JREFUeNqllWlTwjAQhqUtvWhrgTaI94l4IuB936KIt+z//ydGhG7SpHYYnm9555mdZpNsx0ZA0Q3LcSxDV/73VNMLSJ/AM9Vk0Q0JR+jK5YxdIAIFOyOamk+kjGtxM5snCeSzMbNIEilyrsbWnFndbqwvTjB1NWZHPubz1x/wy8vBZJT5uDebIFPv0OdhOgrtqJ9cl+5gwBn2bNBfl7Dsne6sVI8fgTIXhW6/aEhEFj6puonn9lfWJDJaVG3g0uypnswsv1J1Ddde79YFMvWQmk9lXAcKVXWZWe8CfG2xiU5VQ2LWvgG6dS4yqGqJZuUNAPb5zKKqI5izbVqzGQsdquYE9QQAduNhTvoB9wA3QmhJt/UMcCSEhrRZHYCmEOrSI7httWrxLFDwYFPw8LqkYSZcwpJghipebZbTzuVyLHLxwbBUAOCcNwsqPkOWDape8KrNPW6kdAXtKmf6mdjIQJbK/CjShhhEQ4639KHpayOM4pQBn/7bGPlnlM4Ps46A9VAA3W0AAAAASUVORK5CYII=);
        width: 14px;
        height: 14px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: 3px;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.form-control,
.custom-checkbox label,
.btn,
button,
select,
a .icon,
a img,
select,
input,
.dropdown,
.dropdown-item {
    &:focus {
        outline: 1px solid -webkit-focus-ring-color !important;
    }
}

.btn,
button {
    &:focus {
        box-shadow: none;
    }
}

// Search & Quantity fields just have border bottom. Hence override for those.
.search-field,
.quantity {
    &:focus {
        outline: none !important;
    }
}

// Overriding SFRA code.
.form-control:focus {
    border: none;
}

.custom-checkbox input[type=checkbox]:focus + label::before {
    box-shadow: 0 0 3px $medium-gray;
    outline: 1px solid -webkit-focus-ring-color !important;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

.form-check .form-check-label {
    cursor: pointer;
}
