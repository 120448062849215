#addedToCartModal {
    .modal-content {
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 16px;
        border: 0;
        box-shadow: none;

        .modal-body {
            padding: 0;
        }
    }

    .modal-dialog {
        @include media-breakpoint-down(md) {
            margin: 0.5rem;
            max-width: inherit;
        }

        .one-size-container {
            display: none;

            &.oneSize {
                display: inline-block;
            }
        }

        .other-size-container.oneSize {
            display: none;
        }
    }

    .subtotal-label {
        font-weight: 500;
        display: block;
        width: 100%;
        margin-top: 5px;
    }

    .subtotal {
        font-weight: 400;
    }

    .footer-buttons {
        margin: 0;
        margin-top: 10px;

        a,
        button {
            min-height: 52px;
            font-weight: 500;
            letter-spacing: 1px;

            &:lang(ja) {
                letter-spacing: 0.4px;
            }

            &:lang(ja),
            &:lang(ko),
            &:lang(zh) {
                padding: 14px;
            }
        }

        a {
            &:hover {
                background-color: #b81c29;
                border: solid 1px #b81c29;
            }

            &:active {
                background-color: #921620;
                border: solid 1px #921620;
            }

            &:focus {
                background-color: #921620;
                border: solid 1px #921620;
                outline: none !important;
                box-shadow: none !important;
            }
        }
    }

    .stock-avail-msg {
        margin: 0 22px 14px 0 !important;

        @include media-breakpoint-down(md) {
            margin: 0 0 14px !important;
        }
    }

    .you-may-also-like {
        .recommendations {
            padding: 0;
            margin-bottom: -20px;

            .carousel-heading h2 {
                font-weight: 600;
                text-align: left;
                padding: 0 10px;
                margin-top: 20px;
                font-size: 33px;
                line-height: 40px;

                &::first-letter {
                    text-transform: uppercase !important;
                }

                @include media-breakpoint-down(md) {
                    line-height: 34px;
                    @include fontSize($font-medium);
                }
            }

            .pdp-link .link {
                font-weight: 600;

                @include media-breakpoint-down(md) {
                    line-height: 17px;
                    font-size: 16px;
                }
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                margin: 0;
                padding: 1rem 0;
                list-style-type: none;
            }

            .slick-dots li {
                margin: 0 0.25rem;
                background-color: #8d8d8d;
            }

            .slick-dots button {
                display: block;
                width: 1rem;
                height: 1rem;
                padding: 0;
                border: none;
                border-radius: 100%;
                background-color: #000;
                text-indent: -9999px;
            }

            .slick-dots li.slick-active {
                background-color: #000;
            }

            .slick-dots li,
            .slick-dots li button {
                height: 8px !important;
                width: 8px;
                cursor: pointer;
                border-radius: 50%;
            }
        }
    }

    .wishlistTile {
        display: none;
    }

    .slick-slider .slick-list,
    .slick-slider .slick-track {
        @include media-breakpoint-up(lg) {
            min-width: 850px;
        }
    }

    .product-carousel-slide {
        min-width: 150px !important;

        @include media-breakpoint-up(lg) {
            min-width: 212px !important;
            max-height: 100%;
        }
    }

    .product-tile .tile-body {
        max-height: 100px;
    }

    @include media-breakpoint-up(lg) {
        .modal-dialog {
            max-width: 876px;

            .modal-content {
                margin: 0 auto;

                .modal-header {
                    padding-left: 0;
                    padding-right: 0;
                }

                .modal-body {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .quickViewLink {
        display: none;
    }
}

.modal-extended {
    .title-text {
        font-size: 33px;
        line-height: 40px;
        font-weight: 600;
        padding-left: 10px;

        @include media-breakpoint-down(md) {
            line-height: 34px;
            padding-left: 0;
            @include fontSize($font-medium);
        }
    }

    .product-image {
        float: left;
        padding: 0;

        img {
            max-width: 100%;
        }
    }

    .product-tile .tile-body .price .sales {
        margin-top: 0 !important;
    }

    @include media-breakpoint-down('md') {
        .low-stock-details {
            padding: 0;
            margin: 0;
        }

        .product-tile .tile-body .price,
        .product-tile .tile-body .price .sales {
            font-weight: 400;
            @include fontSize($font-body3, !important);

            line-height: 20px;
            margin-top: 0 !important;
        }

        .product-details-other {
            margin-top: 15px;
        }

        .carousel-heading {
            h2 {
                font-weight: 600;
                line-height: 34px;
                @include fontSize($font-medium);
            }
        }
    }

    .product-content {
        float: right;
    }

    .product-content-name {
        font-size: 23px;
        line-height: 28px;
        font-weight: 600;
        min-height: 33px;
    }

    .product-label {
        line-height: 25px;
        min-height: 33px;
        @include fontSize($font-body1);

        @include media-breakpoint-down(md) {
            line-height: 21px;
            @include fontSize($font-body2);
        }
    }

    .modal-cart-details {
        margin: 0 5px 0 2px;
    }

    .product-details {
        line-height: 25px;
        float: left;
        margin-bottom: 10px;
        margin-top: 5px;
        padding-left: 0;
        padding-right: 0;
        @include fontSize($font-body1);

        @include media-breakpoint-down(md) {
            line-height: 21px;
            @include fontSize($font-body2);
        }
    }

    .product-details-other {
        line-height: 25px;
        float: left;
        margin-bottom: 10px;
        @include fontSize($font-body1);

        @include media-breakpoint-up(lg) {
            border-left: 1px solid #c0c0c0;
            padding: 8px 6px 16px 23px;
        }

        @include media-breakpoint-down(md) {
            border-top: 1px solid #c0c0c0;
            border-left: 0;
            padding: 17px 6px 16px;
        }

        @include media-breakpoint-down(md) {
            border-top: 1px solid #c0c0c0;
            border-left: 0;
            line-height: 21px;
            padding: 8px 6px 16px;
            @include fontSize($font-body2);
        }
    }

    .radius-4 {
        border-radius: 4px;
    }

    .modal-header {
        border-bottom: 0 !important;
    }

    .product-content-size {
        display: inline-block;
    }

    .product-details-info {
        margin-top: -10px;
        padding-bottom: 5px;

        @include media-breakpoint-down(md) {
            padding: 5px 0;
            margin-top: 0;
        }
    }
}
