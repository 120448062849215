.site-stripe {
    min-height: 60px;
    text-align: center;

    &-slot {
        width: calc(100% - 20px);
    }

    .close-button {
        width: 20px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 50px;
        text-align: left;
        padding-top: 0;
    }
}

.slide-up {
    overflow: visible;
    padding-top: 15px;
    padding-bottom: 15px;
}

.site-stripe-slot .header-promotion {
    width: 100%;
}
