.visual-links-component {
    margin-bottom: 24px;

    .image-reserve-space {
        position: relative;
        padding-top: 118.5%;

        .image-wrapper {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            height: 100%;
            width: 100%;

            img {
                transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
                object-fit: cover;
                height: 100%;
            }

            @include media-breakpoint-up(md) {
                .title-wrapper {
                    @include fontSize($font-body);

                    line-height: 24px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding-left: 20px;
                    padding-bottom: 20px;
                    color: $white;
                    width: 100%;
                }
            }
        }
    }

    .title-wrapper {
        width: 100%;
        height: 29px;
        margin-top: 10px;
        display: inline-block;
        border-bottom: 1px solid #d5d5d5;

        @include font(400, 22px, $black, 18px, 0);

        a:link,
        a:hover,
        a:active {
            text-decoration: none;
            color: $black;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 40px;

        .image-wrapper {
            img {
                width: 249px;
            }
        }

        &.swiper-container {
            height: auto;
        }
    }

    &.swiper-container {
        .swiper-wrapper {
            transform: translateX(10px);
        }

        .swiper-slide {
            transform: translateX(10px);
        }

        .swiper-button-disabled {
            opacity: 0 !important;
        }

        .swiper-button-prev {
            left: 1px;

            &::after {
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTAgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9IkZhdC1DaGV2ZXJvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMDAwMDAwLCAtOS4wMDAwMDApIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPGcgaWQ9IkNoZXZlcm9uLXRvLUNsb3NlLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjAwMDAwMCwgMTAuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iQmFjayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy43NTAwMDAsIDcuNTAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0zLjc1MDAwMCwgLTcuNTAwMDAwKSAiIHBvaW50cz0iMCAxNSA3LjUgNy41IDAgMCI+PC9wb2x5bGluZT4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=) no-repeat center;
            }
        }

        .swiper-button-next {
            right: 1px;

            &::after {
                background: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE3IiB2aWV3Qm94PSIwIDAgMTAgMTciIHdpZHRoPSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0wIDBoMTB2MTdoLTEweiIgZmlsbC1ydWxlPSJub256ZXJvIiAvPjxwYXRoIGQ9Im0xIDE2IDcuNS03LjUtNy41LTcuNSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgLz48L2c+PC9zdmc+) no-repeat center;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 36px;
            height: 45px;
            opacity: 0.85;
            background-color: $white;

            &::after {
                content: ' ';
                width: 13px;
                height: 23px;
            }

            &:hover,
            &:focus {
                outline: 1px solid $black;
            }
        }

        @include media-breakpoint-down(md) {
            .swiper-button-prev,
            .swiper-button-next {
                display: none !important;
            }

            .title-wrapper {
                @include fontSize($font-text);
            }
        }
    }
}
