.save-for-later {
    @include media-breakpoint-up(lg) {
        max-width: 1324px;
        margin: auto;
    }

    .cart-item {
        flex-wrap: wrap;
        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
        }

        .line-item-body {
            .item-attributes.flex-column {
                width: 100%;
            }
        }
    }

    .cart-empty {
        .cart-empty-icon {
            width: 32px;
            height: 32px;
        }

        .cart-empty-line-1 {
            @include fontSize($font-medium);

            line-height: 32px;
            margin-top: 8px;
            margin-bottom: 8px;
            font-weight: 600;

            @include media-breakpoint-down(md) {
                @include fontSize($font-small);

                line-height: 28px;
            }
        }

        .cart-empty-line-2 {
            @include fontSize($font-body1);

            line-height: 24px;
        }

        .cart-empty-cta.shop-cta {
            background-color: $black;
            border-color: $black;
            min-width: 173.5px;
            margin-left: 4px;
            margin-right: 4px;

            @include media-breakpoint-down (md) {
                width: calc(50% - 7px);
                padding: 16px;
                min-width: unset;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .empty-bag-separator {
        width: 355px;
        margin: auto;
        border-color: $gainsboro;

        @include media-breakpoint-down(md) {
            width: calc(100% - 20px);
            margin: 32px auto;
        }
    }

    .totals {
        .cart-cards-container {
            flex-direction: column;

            .we-accept-text {
                @include fontSize($font-body2);

                font-weight: 600;
                text-align: center;
                margin-bottom: 8px;
            }

            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }

        .content-asset .cards-container {
            justify-content: center !important;
        }
    }

    .cart-sfl {
        margin-top: 8px;
        margin-bottom: 32px;

        .sfl-pre-login {
            .icon.sfl-icon {
                display: block;
                margin: 48px auto 8px;
                width: 23px;
                height: 32px;
            }

            .sfl-heading {
                text-align: center;
                @include fontSize($font-medium);

                font-weight: 600;
                line-height: 32px;

                @include media-breakpoint-down(md) {
                    @include fontSize($font-small);

                    line-height: 28px;
                }
            }

            .sfl-description {
                @include fontSize($font-body1);

                line-height: 24px;
                font-weight: 400;
                text-align: center;
            }

            .sfl-cta-primary {
                display: block;
                width: 230px;
                margin: 0 auto;

                &:hover {
                    text-decoration: none;
                }
            }

            .sfl-cta-secondary {
                @include fontSize($font-body1);

                line-height: 24px;
                font-weight: 400;
                text-decoration: underline;
                text-underline-offset: 5px;
                text-decoration-color: $gainsboro-gray;
                margin-top: 12px;
                display: block;
                text-align: center;

                &:hover {
                    text-decoration-color: $fireEngine-red;
                }
            }
        }
    }

    .all-OOS-error {
        @include fontSize($font-body1);

        line-height: 24px;
    }

    .all-OOS-error,
    .sfl-add-msg {
        padding: 15px 0;
        border: solid 1px $silver-grey;
        border-width: 1px 0;
        background-color: $white;
        margin: 0 -10px;
        padding-left: 10px;

        @include media-breakpoint-up(lg) {
            margin: 0 0 24px;
            border: solid 1px $gray;
            box-shadow: 0 1px 0 0 $silver;
            border-radius: 0;
            padding: 24px;
            display: flex;
        }

        .icon {
            height: 18px;
            margin-right: 10px;
        }

        .sfl-prod-title {
            @include fontSize($font-body2);

            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 320px;
        }

        .sfl-moved-msg {
            @include fontSize($font-body2);

            margin: 0 0 0 4px;
        }

        &.empty-sfl {
            border-top: 0;
            margin-top: -48px;
            margin-bottom: 40px;

            @include media-breakpoint-up(lg) {
                margin-top: 0;
                margin-left: 10px;
            }

            .sfl-moved-msg {
                margin-left: 25px;
                @include media-breakpoint-up(lg) {
                    margin-left: 5px;
                }
            }
        }
    }

    .sfl-empty-bag-smmry {
        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            position: relative;
            top: 0;
            margin-left: -10px;

            > .row {
                margin: 0;

                .totals {
                    max-width: 100%;
                    flex: 100%;
                    padding: 0;
                }
            }
        }
    }

    .sfl-empty-bag-smmry {
        @include media-breakpoint-up(lg) {
            top: -30px;
        }
    }

    .sfl-empty-bag-smmry,
    .empty-total-summary {
        @include media-breakpoint-up(lg) {
            width: 33.6666%;
            display: inline-block;
            padding: 0;
        }
    }

    .empty-total-summary {
        @include media-breakpoint-up(lg) {
            .estimated-total {
                margin-bottom: 30px;

                .col-4 {
                    text-align: right;
                }
            }
        }

        .cart-checkout-summary-divider {
            border-bottom: 1px solid $gainsboro-gray;
            margin-bottom: 12px;
        }

        .applepay-icon,
        .paywithgoogle-icon,
        .kakaopay-icon,
        .naverpay-icon,
        .payco-icon {
            display: none;
        }

        .applepay-cards-container {
            .applepay-icon {
                display: block;
            }
        }

        .googlepay-cards-container {
            .paywithgoogle-icon {
                display: block;
            }
        }

        .kakaopay-cards-container {
            .kakaopay-icon {
                display: block;
            }
        }

        .naverpay-cards-container {
            .naverpay-icon {
                display: block;
            }

            .payco-icon {
                display: block;
            }
        }
    }

    .container.cart-empty {
        margin-bottom: 40px;
        padding-top: 40px;

        &:has(.empty-sfl) {
            @include media-breakpoint-up(lg) {
                padding-top: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            max-width: 66%;
            margin-bottom: 0;
            display: inline-block;
            vertical-align: top;
        }
    }

    .card-product-edit-wrapper {
        .product-move {
            .save-for-later {
                border-bottom: 1px solid $black;
                padding-bottom: 4px;
                text-decoration: none;
            }
        }

        @include media-breakpoint-up (lg) {
            float: none !important;

            .d-lg-flex {
                .line-item-attributes.out-of-stock {
                    margin-top: 0;
                }
            }
        }
    }

    .prod-fadeout {
        transition: opacity 1s linear;
        opacity: 0;
    }

    .card-separator-mobile {
        margin: 0 -10px 0 -10px;
    }

    .esw-callout-header {
        display: none !important;
    }

    .esw-callout-body {
        color: $bright-gray2;
    }

    .number-of-items-in-sfl {
        @include fontSize($font-small);

        font-weight: 600;

        &:lang(ja),
        &:lang(ko),
        &:lang(zh) {
            font-weight: 700;
        }

        @include media-breakpoint-up(lg) {
            @include fontSize($font-medium);
        }

        span {
            font-weight: 400;
        }
    }

    .sfl-load-more {
        background-color: $white;
        padding: 24px 0;
        text-align: center;
        border-bottom: 1px solid $silver-grey;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            margin: 0 -10px;
        }

        button.btn-light {
            border-width: 0;
            background-color: $white;
            line-height: 20px;
            @include fontSize($font-body2);

            font-weight: 500;
            padding-top: 0;
            margin-left: 8px;
            letter-spacing: normal;

            &:active,
            &:focus {
                background-color: unset;
                border-color: unset;
                box-shadow: unset;
            }
        }
    }

    .product-info.card {

        @include media-breakpoint-up(lg) {
            padding-bottom: 24px;
        }

        .card-product-image,
        .item-image {
            @include media-breakpoint-down(md) {
                width: 100px;
                margin-left: 0;
            }
        }

        .card-product-image {
            @include media-breakpoint-down(md) {
                height: auto;
            }

            .item-image {
                display: block;
            }
        }

        .item-image {
            @include media-breakpoint-up(lg) {
                height: 245px;
            }
        }

        .product-card-price {
            margin-top: 16px;
            padding: 0;

            .quantity-form-wishlist {
                min-height: auto;
                display: inline;
            }

            .price {
                display: flex;
                flex-direction: column;
                text-align: right;

                .value {
                    @include fontSize($font-body2);

                    font-weight: 500;
                    line-height: 20px;

                    @include media-breakpoint-up(lg) {
                        @include fontSize($font-body1);

                        line-height: 24px;
                    }
                }

                .strike-through {
                    margin-right: 0;

                    .value {
                        font-weight: 400;
                        @include fontSize($font-body3);

                        @include media-breakpoint-up(lg) {
                            @include fontSize($font-body2);

                            line-height: 20px;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    margin: auto 0;
                }
            }
        }

        .product-card-mobile-qty-total {
            margin-top: 0;

            .line-item-quantity {
                .quantity-form {
                    display: inline-flex;

                    .select-quantity {
                        margin: 0;
                        width: 45px;
                        border: none;
                        height: auto;
                        padding-top: 4px;
                        padding-left: 5px;
                        padding-right: 0;
                        background-position-x: 25px;
                        background-position-y: 8px;
                    }

                    .btn.dropdown-toggle.btn-light {
                        border: none;
                        line-height: normal;
                        height: auto;
                        padding-left: 8px;

                        &::before {
                            vertical-align: middle;
                        }
                    }

                    .line-item-total-price-amount {
                        margin-top: 0 !important;
                    }
                }
            }

            .line-item-price-info {
                display: none;
            }

            .line-item-total-price-amount {
                margin-top: 0 !important;
            }
        }

        .item-attributes .line-item-attributes {
            @include media-breakpoint-up(lg) {
                margin-bottom: 4px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .quantity-label,
        .line-item-price-info {
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        .line-item-total-price-amount,
        .strike-through {
            font-weight: 500;

            @include media-breakpoint-up(lg) {
                @include fontSize($font-body1);
            }
        }

        .strike-through {
            font-weight: 400;
        }

        .card-product-edit-wrapper {
            justify-content: end;
            padding-bottom: 0;

            a {
                border-color: $gainsboro-gray2;
            }
        }

        .card-product-info {
            @include media-breakpoint-down(md) {
                width: calc(100% - 110px);
            }

            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
            }

            .card-product-edit-wrapper {
                @include media-breakpoint-up(lg) {
                    margin-top: auto;
                }
            }

            .line-item-quantity .quantity-form {
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }

            .product-edit {
                text-align: right;
            }
        }

        .sfl-remove-icon {
            padding: 0;
            background: transparent;
            border: none;
            display: block;
            position: initial;
            font-size: initial;
            margin: 0;
            height: auto;
            line-height: initial;
        }

        .icon-cross {
            width: 14px;
            height: 14px;
            @include media-breakpoint-up(lg) {
                width: 20px;
                height: 20px;
            }
        }

        .product-move-to-cart {
            margin-top: 16px;

            .btn {
                border-radius: 4px;
                text-transform: uppercase;
                border-color: $black;
            }
        }

        .sfl-edit,
        .move-to-cart-saveforlater {
            border-bottom: 1px solid $gainsboro-gray2;
            padding-bottom: 3px;

            @include fontSize($font-body3);

            line-height: 20px;
            font-weight: 400;

            &:hover {
                text-decoration: none;
            }

            @include media-breakpoint-up(lg) {
                @include fontSize($font-body2);

                margin-left: 24px;
            }
        }

        .sfl-qty-num {
            @include media-breakpoint-up(lg) {
                border-radius: 4px;
                border: 1px solid $black;
                padding: 14px 16px 14px 8px;
                margin-top: 8px;
                width: 90px;
                background-color: $gainsboro-gray3;
                font-size: 19px;
            }
        }
    }

    .empty-total-summary {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .cart-header {
        padding-bottom: 16px;

        .cart-title-none {
            margin-top: 32px;
        }

        h1.page-title {
            padding-bottom: 0;

            span {
                font-weight: 400;
            }

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }

            &:lang(ja),
            &:lang(ko),
            &:lang(zh) {
                font-weight: 700;

                @include media-breakpoint-up(md) {
                    font-size: 25px;
                }
            }
        }

        .title-border {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        &.tabbed-view {
            .page-title.number-of-items {
                padding-top: 24px;
                padding-bottom: 20px;

                span {
                    display: none;
                }
            }

            .tablistWrapper-1I3Wi {
                display: block;
                margin-bottom: 4px;

                .tabButtonWrapper-1otvU {
                    width: 50%;
                    min-width: 50%;

                    .tabButton-pNDJp {
                        line-height: 24px;
                        padding-bottom: 12px;
                        width: 100%;
                        white-space: initial;
                        @include fontSize($font-body1);

                        &:focus {
                            outline: 2px solid -webkit-focus-ring-color !important;
                            outline-offset: -2px;
                        }

                        &:first-of-type {
                            padding-left: 16px;
                            padding-right: 8px;
                        }

                        &:last-of-type {
                            padding-left: 8px;
                            padding-right: 16px;
                        }
                    }
                }
            }

            .cart-tab-panel {
                display: none;

                &.active-tab {
                    display: block;
                }
            }

            .sfl-empty-bag-smmry.cart-tab-panel,
            .empty-bag-separator,
            .number-of-items-in-sfl {
                display: none;
            }

            .cart-sfl-empty {
                display: block;

                .sfl-empty-list {
                    .icon.sfl-icon {
                        display: block;
                        margin: 28px auto 8px;
                        width: 23px;
                        height: 32px;
                    }

                    .sfl-heading {
                        text-align: center;
                        @include fontSize($font-medium);

                        font-weight: 600;
                        line-height: 32px;

                        @include media-breakpoint-down(md) {
                            @include fontSize($font-small);

                            line-height: 28px;
                        }
                    }

                    .sfl-description {
                        @include fontSize($font-body1);

                        line-height: 24px;
                        font-weight: 400;
                        text-align: center;
                        margin-bottom: 24px;
                    }

                    .cart-empty-cta.shop-cta {
                        background-color: $black;
                        border-color: $black;
                        width: calc(50% - 4px);
                        margin-bottom: 40px;
                        padding: 16px;

                        &:first-of-type {
                            margin-right: 4px;
                        }
                    }
                }
            }

            .container.cart-recommendations {
                .recommendations {
                    .carousel-heading {
                        h2 {
                            margin-top: 40px;
                        }
                    }
                }
            }

            .cart-sfl {
                margin-top: 0;
                margin-bottom: 80px;

                .sfl-pre-login {
                    .icon.sfl-icon {
                        margin-top: 4px;
                    }
                }
            }

            .saveforlater-section {
                .saveforlater-page {
                    margin-top: -52px;

                    &:lang(ja),
                    &:lang(ko),
                    &:lang(zh) {
                        margin-top: -28px;
                    }
                }
            }

            .sfl-add-msg {
                display: flex;
                flex-wrap: wrap;

                .p-name {
                    margin-right: -20px;
                }

                .sfl-moved-msg {
                    margin-left: 25px;
                }
            }
        }
    }
}

.cart-sfl-empty,
.tablistWrapper-1I3Wi {
    display: none;
}

#editProductModal.modal {
    .modal-content .modal-body .sfl-prod-edit-modal {
        &.product-quickview {
            .icon-cross {
                z-index: 2;
            }

            .carousel-indicators {
                margin-bottom: -30px !important;
            }

            .details {
                margin: 35px 8px 0;
            }

            .carousel-item {
                img {
                    margin: 0;
                }
            }

            .swatch-circle-container {
                &.unselectable {
                    display: none;
                }
            }

            .detail-panel {
                padding: 0 8px !important;

                .attributes .swatch[data-attr=size],
                .update-cart-product-global {
                    width: 100% !important;
                }
            }
        }
    }
}

.sfl-add-to-cart-btn {
    display: none;

    .product-add-to-cart {
        margin: 20px 0;
        text-transform: uppercase;

        .move-to-cart-saveforlater {
            &:lang(ja),
            &:lang(ko),
            &:lang(zh) {
                padding-top: 14px;
            }
        }
    }
}

.save-for-later-container {
    margin-top: 24px;
}
