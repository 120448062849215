.tile-skeleton {
    margin-bottom: 8px;
    padding-bottom: 10px;

    svg {
        height: 500px;
        width: 100%;
        display: block;
        padding: 12px 0;
        overflow: hidden;

        @include media-breakpoint-only(md) {
            height: 630px;
        }
    }
}
