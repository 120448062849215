.dropdown-selector {
    &.accordion {
        outline: none !important;

        &.country-selector {
            span {
                display: inline-block;
                margin-left: 6px;
            }
        }

        .nav-header {
            padding: 16px 0;
            position: relative;
            line-height: normal;
            display: block;

            &:hover {
                text-decoration: none;
            }

            .icon-expand,
            .icon-collapsed {
                @include center('vertical');

                width: 16px;
                height: 16px;
                right: 0;
            }
        }

        .accordion-country-selector,
        .accordion-language-selector {
            .accordion-item {
                @include body;

                padding: 16px 0;
                display: block;
                line-height: normal;
                border-top: 1px solid $light-gray;

                &.change-country-link {
                    @include link;
                    @include caption;

                    font-weight: 600;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &.show {
        #dropdownCountrySelector,
        #dropdownLanguageSelector {
            .icon-chevron {
                transform: rotate(180deg);
            }
        }
    }

    #dropdownCountrySelector,
    #dropdownLanguageSelector {
        .icon-chevron {
            width: 10px;
            height: 10px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .dropdown-menu {
        padding: 0;
        margin: 0;
        background-color: $gray;
        border: none;
        border-radius: 0;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
        left: auto;
        right: -72px;
        min-width: 225px;

        .list-items {
            padding: 16px 24px;
            column-count: 1;
        }

        &.two-column-view {
            min-width: 380px;

            .list-items {
                column-count: 2;
                column-gap: 32px;
            }
        }

        &.three-column-view {
            min-width: 564px;

            .list-items {
                column-count: 3;
                column-gap: 32px;
            }
        }
    }

    &:nth-last-child(2) {
        .dropdown-menu {
            right: 0;
        }
    }

    &.language-selector {
        .dropdown-menu {
            .list-items {
                padding: 16px 42px;
            }
        }
        @include media-breakpoint-down(md) {
            padding: 0 0 16px 16px;

            .selected-language-js {
                font-weight: bold;
                text-decoration: underline;
            }

            .selected-language-js,
            .language-option {
                text-transform: uppercase;
                padding-right: 4px;
            }

            .list-link:not(:last-child) {
                &::after {
                    content: "|";
                    color: $bright-gray2;
                }
            }

            .list-link:not(:first-child) {
                padding-left: 5px;
            }

            .list-link {
                padding-right: 2px;
            }
        }
    }

    .list-item {
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }

        .list-link {
            @include font(400, 16px, $black, 20px);
            @include underlineOnHover($black, 3px, 1.5);

            padding: 0 0 3px;
        }

        .change-country-link {
            @include link;
            @include caption;

            font-weight: 600;
            display: flex;
            align-items: center;

            .change-country-label {
                margin-left: 6px;
            }
        }

        .name {
            max-width: 135px;
        }

        .flag-icon {
            margin-right: 4px;
            width: 16px;
            height: 12px;
            line-height: 12px;
        }
    }
}

.country-selector-modal {
    @include media-breakpoint-up('sm') {
        // bootstrap modal override
        .modal-dialog {
            max-width: 674px;
        }
    }

    .modal-content {
        padding-bottom: 40px;
        background-clip: border-box;
        border: 1px solid $light-gray;
        border-radius: 0;
        box-shadow: 0 2px 4px 0 rgba(188, 188, 188, 0.5);

        .modal-header {
            margin-top: 20px;
            padding: 0;
            border-bottom: 0;
            border-radius: 0;
            position: relative;

            .icon-logo {
                fill: $red;
                width: 34px;
                height: 34px;
                margin: 0 auto;
                display: block;
            }

            .close {
                position: absolute;
                right: 0;
                top: 0;
                padding: 8px 16px 0 0;

                .icon-close {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .modal-title {
            @include font(400, 26px, $black, 27px, normal);

            margin: 8px auto 12px;
            text-align: center;
        }

        .modal-body {
            @include font(400, 16px, $black, 22px, normal);

            margin: 0 auto 32px;
            padding: 0 5px;
            text-align: center;
        }

        .modal-buttons {
            .btn {
                @include font(null, 14px, null, null, null);

                margin-bottom: 16px;
                width: 100%;
                text-transform: uppercase;
            }
        }

        @include media-breakpoint-up('lg') {
            padding-bottom: 20px;
            border: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

            .modal-header {
                .close {
                    top: 0;
                }
            }

            .modal-title {
                @include font(400, 36px, $black, 27px, 0.2px);

                margin: 20px auto 16px;
            }

            .modal-body {
                @include font(400, 20px, $black, 22px, normal);

                padding: 0 64px;
                margin-bottom: 36px;
            }

            .modal-buttons {
                .btn {
                    @include font(600, 16px, null, null, null);

                    width: 300px;
                    margin: 0 8px 16px;
                }
            }
        }
    }
}

.currency_symbol {
    color: #57585b;
    letter-spacing: 0;
    line-height: 20px;
    @include fontSize($font-body3);
}

.lang-links {
    float: right;
}

@include media-breakpoint-down(md) {
    li.accordion.nav-item.dropdown-selector.country-selector {
        outline: none !important;
    }
}

@include media-breakpoint-down(md) {
    li.dropdown-selector {
        &.country-selector {
            display: flex !important;
            align-items: center;
            border-bottom: 1px solid $light-gray;
            padding-left: 16px;

            .country-and-lang {
                display: flex;
                flex-direction: column;
                flex: 1;

                span:not(.selected-country-js) {
                    @include fontSize($font-body3);
                }

                span:not(.selected-country-js):not(.selected-language-js) {
                    color: $bright-gray2;
                }
            }

            .nav-primary {
                border: none;
                padding-bottom: 16px;
                display: inline-block;
                flex: 1;

                .selected-country-js {
                    font-weight: bold;
                }

                &:has(+ .language-selector) {
                    padding-bottom: 0;
                }
            }

            .secondary {
                .nav-item {
                    &:not(.other-header) {
                        &:not(.location-header) {
                            border-bottom: none;
                        }
                    }
                }

                .more-locations {
                    border-top: 1px solid $gainsboro-gray;
                }
            }

            .next-arrow {
                margin-left: auto;
                margin-right: 16px;
            }
        }
    }
}

@include media-breakpoint-down (md) {
    .country-list {
        .location-header {
            padding-left: 0 !important;

            .close-location {
                float: right;
                margin-top: 18px;
                padding: 0 !important;
            }
        }

        .accordion-item {
            span {
                @include fontSize($font-body2);
            }

            .language-option,
            .selected-language-js {
                @include font-size($font-body3);

                text-transform: uppercase;
            }
        }

        .lang-links {
            .accordion-item {
                .language-option {
                    color: $bright-gray2;

                    &.selected-lang {
                        color: $black;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }

                &:not(:last-child) {
                    .language-option,
                    .selected-language-js {
                        &::after {
                            content: "|";
                            color: $bright-gray2;
                            padding: 4px;
                        }
                    }
                }
            }
        }

        .selected-country {
            .selected-currency-js,
            .selected-country-js,
            .selected-language-js {
                font-weight: bold;
            }

            .selected-language-js {
                text-decoration: underline;
            }
        }

        .more-locations {
            .change-country-label {
                margin-right: 9px;
                font-weight: bold;
            }
        }
    }
}

.language-tooltip {
    @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
    }

    .language-selector-tooltip {
        position: absolute;
        left: 50px;
        width: auto;
        height: auto;
        background: $black;
        padding: 20px 32px 24px 20px;
        color: $white;
        z-index: 1065;
        border-radius: 4px;
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);

        @include media-breakpoint-up(md) {
            top: -6rem;
            left: initial;
            right: 10px;
        }

        button.close {
            position: absolute;
            top: 3px;
            right: 9px;
        }

        .heading {
            @include fontSize($font-body3);

            font-weight: 600;
        }

        .sub-heading {
            font-size: 12px;
            font-weight: 700;
        }

        .languages {
            span {
                margin-right: 40px;

                &:last-child {
                    margin-right: auto;
                }
            }

            a.link {
                color: $white !important;
                font-weight: 500;
                text-decoration: none;
                padding-bottom: 3px;
                border-bottom: 1px solid $white;
                @include fontSize($font-body3);

                &:hover,
                &.selected {
                    border-bottom: 1px solid #d31334;
                }
            }
        }

        .arrow {
            left: -12px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 9px solid $black;
            width: 0;
            height: 0;
            position: absolute;
            top: 13px;
            transform: translate(-15%, 50%) rotate(275deg);

            @include media-breakpoint-up(md) {
                top: -9px;
                right: 2.5rem;
                transform: initial;
                left: initial;
            }
        }
    }
}

.country-selector-modal-new {
    width: auto;
    margin: 0 10px;
    top: 72px;
    bottom: auto;
    position: fixed;
    z-index: 1045;
    display: flex;
    padding: 36px 20px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);

    @include media-breakpoint-up(md) {
        width: 423px;
        margin: initial;
        bottom: 0;
        right: 0;
        top: auto;
    }

    button.close {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: inherit;
        line-height: normal;
    }

    .title-text {
        font-size: 28px;
        font-weight: 600;
    }

    .sub-text {
        @include fontSize($font-body1);

        font-weight: 400;
    }

    .contry-selector-popup-select {
        border: 1px solid $black;
        border-radius: 4px;
        background: none;
        height: 52px;

        .dropdown-toggle {
            background-color: unset;
            position: initial;
            color: $black;
            border: none;
            outline: none !important;
            box-shadow: none;

            &:focus,
            &:active {
                background: none;
            }

            &::before {
                border: none;
                background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTkuNDEyLjg4Mkw1IDUuMjk0LjU4OC44ODIiLz48L3N2Zz4=) no-repeat right;
                height: 7px;
                width: 14px;
                vertical-align: middle;
                background-position: unset;
            }

            &::after {
                display: none;
            }

            &:hover {
                background-color: unset;
                border: none;
            }

            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                font-size: 16px;

                .filter-option {
                    padding-top: 14px;
                }
            }
        }

        .dropdown-menu {
            padding-top: 0;

            ul.dropdown-menu {
                li {
                    padding: 15px 0;
                    margin: 0 15px;
                    border-bottom: 1px solid $light-gray;

                    &:hover,
                    &.active {
                        background-color: $gainsboro;
                    }

                    a.opt,
                    a.dropdown-item {
                        padding-left: 8px;
                        height: unset !important;
                        width: unset !important;
                        display: block !important;

                        &.active,
                        &:active,
                        &:focus,
                        &:hover {
                            color: $black;
                            background: none;
                            outline: none !important;
                        }

                        .text {
                            display: flex;
                            align-items: center;

                            .text-dark {
                                margin-left: 15px;
                                white-space: normal;
                                @include fontSize($font-body1);
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                        border: none;
                    }
                }
            }
        }
    }
}

.country-selector-modal-new-backdrop {
    opacity: 0.3 !important;
}
