@mixin font(
    $weight: null,
    $size: null,
    $color: null,
    $lh: null,
    $ls: null,
    $imp: ""
) {
    @if $weight {
        font-weight: $weight;
    }
    @if $size {
        @include setProperty(unquote($imp), font-size, $size);

        @if $size == $font-body3 { //14
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-body3-noto);

                line-height: 20px;
            }
        } @else if $size == $font-body2 { //17
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-body3);

                line-height: 20px;
            }

            &:lang(zh) {
                line-height: 21px;
            }
        } @else if $size == $font-text { //18
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-body2-noto);

                line-height: 22.8px;
            }

            &:lang(zh) {
                line-height: 22px;
            }
        } @else if $size == $font-body1 { //19
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-button);

                line-height: 24px;
            }

            &:lang(zh) {
                line-height: 23px;
            }
        } @else if $size == $font-body { //20
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-body2);

                line-height: 24px;
            }
        } @else if $size == $font-xsmall { //22
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-text);

                line-height: 24px;
            }
        } @else if $size == $font-xsmall-desk { //24
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-xs-noto);

                line-height: 28px;
            }
        } @else if $size == $font-small { //26
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-xs-noto2);

                line-height: 28px;
            }
        } @else if $size == $font-medium { //30
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-small-noto);

                line-height: 28px;
            }
        } @else if $size == $font-medium-desk { //40
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-lg-noto1);

                line-height: 48px;
            }
        } @else if $size == $font-large { //36 
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-medium);

                line-height: 44px;
            }
        } @else if $size == $font-xl { //48
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-medium-desk);

                line-height: 52px;
            }
        } @else if $size == $font-xxl { //56
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-lg-noto2);

                line-height: 56px;
            }
        } @else if $size == $font-large-desk { //57
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-lg-noto2);

                line-height: 56px;
            }
        } @else if $size == $font-xl-desk { //72
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-xl-noto);

                line-height: 72px;
            }
        } @else if $size == $font-xxl-desk { //100
            &:lang(ko),
            &:lang(ja),
            &:lang(zh) {
                @include setProperty(unquote($imp), font-size, $font-xxl-noto);

                line-height: 92px;
            }
        }
    }
    @if $color {
        color: $color;
    }
    @if $lh == 'normal' {
        line-height: normal;
    } @else if $size and $lh {
        line-height: calc($lh / $size);
    }
    @if $ls == 'normal' {
        letter-spacing: normal;
    } @else if $ls {
        letter-spacing: $ls;
    }
}

@mixin link(
    $color: $black,
    $underline-color: $black,
    $hover: $black,
    $hover-underline-color: $black
) {
    color: $color;
    text-decoration: underline;
    text-decoration-color: $underline-color;

    &:hover {
        color: $hover;
        text-decoration-color: $hover-underline-color;
    }
}
