@include media-breakpoint-down(lg) {
    .outer-header-container {
        .banner {
            .slide-up {
                padding-top: 0;
                padding-bottom: 0;
                max-height: none;

                .container {
                    .site-stripe {
                        padding-top: 0;
                        display: flex;
                        align-items: center;
                        height: auto;

                        .header-promotion {
                            line-height: 1;
                            @include fontSize($font-body3);
                        }

                        .close-button {
                            width: 14px;
                            height: 14px;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            display: none;

                            .icon {
                                width: 14px;
                                height: 14px;
                                @include fontSize($font-body3);

                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }

        .search-mobile {
            .site-search {
                .search-icon {
                    width: 35px;
                    height: 45px;
                    position: absolute;
                    right: 0;
                    padding: 0;
                    top: 0;

                    .icon-search {
                        width: 14px;
                        height: 42px;
                        @include fontSize($font-body3);

                        position: static;
                        right: unset;
                        top: unset;
                    }
                }
            }
        }
    }
}

.outer-header-container {
    .banner {
        .slide-up {
            padding-top: 0;

            .container {
                .site-stripe {
                    .site-stripe-slot {
                        width: calc(50% - 14px);
                        padding: 0;
                        text-align: center;
                        margin: 0 auto;
                        opacity: 0;

                        .slick-track {
                            display: flex;
                            align-items: center;
                        }

                        .slick-prev::before,
                        .slick-next::before {
                            width: 16px;
                            height: 16px;
                            color: $gainsboro-gray2;
                            opacity: 0.6;
                            background-size: contain;
                        }

                        .slick-prev:hover,
                        .slick-next:hover,
                        .slick-prev:focus,
                        .slick-next:focus {
                            outline: none !important;
                        }

                        p {
                            margin-bottom: unset;
                        }

                        .slick-prev,
                        .slick-next {
                            width: 16px;
                            height: 16px;
                        }

                        @include media-breakpoint-down(md) {
                            width: 100%;
                            padding: 0;

                            .slick-prev {
                                left: -3px;
                            }

                            .slick-next {
                                right: -3px;
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            border-bottom: 1px solid $white-lilac;
        }
    }
}

.header {
    display: flex;
    flex-direction: column;
    position: static;
    padding-bottom: 0;
    background: $white;

    @include media-breakpoint-down(lg) {
        max-width: 100vw;

        .navigation {
            order: 2;
        }

        .search-mobile {
            order: 3;
        }
    }

    .banner {
        .slide-up {
            max-height: none;
        }
        @include media-breakpoint-down(md) {
            .container {
                padding-bottom: 0;
            }
        }
    }

    .navigation {
        position: relative;
        background-color: $white;
        height: 45px;
        padding-top: 12px;
        padding-bottom: 12px;

        .navbar {
            width: 100%;
        }
    }

    .banner {
        background-color: $white;

        .site-stripe-nav > div > a {
            @include underlineOnHover();

            font-size: 14px;
        }

        .site-stripe-carousel {
            padding: 10px 0;
            min-height: 40px;
            margin: 0 auto;
            max-width: 546px;

            @include media-breakpoint-down(md) {
                padding: 0;
                max-width: 100%;

                .slick-list {
                    max-width: 299px;
                    margin: 0 auto;
                }
            }
        }

        .site-stripe-wrapper {
            @include media-breakpoint-down(md) {
                padding-bottom: 8px;
            }
        }

        .site-stripe-carousel:not(.countdownconfig):not(.slick-slider) {
            max-height: 40px;
            overflow: hidden;
        }
    }

    .navigation-container {
        display: flex;
        justify-content: space-between;
    }

    .logo {
        height: initial;
        width: 104px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: -1px;

        &-svg {
            width: 104px;
            height: 20px;
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
            left: initial;
            width: 100px;
            padding: 28px 0;
            transform: translateX(0);
            top: 0;

            &-svg {
                width: 100px;
            }
        }
    }

    .cta-icons {
        display: flex;

        .user {
            margin: 0;
            padding: 0;
            line-height: 1;
            position: relative;

            .user-link {
                margin-left: 2rem;

                > a {
                    padding: 0 0 9px;
                }

                > .popover {
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
                    padding: 0;
                    top: 29px;
                    left: -50px;

                    a {
                        @include underlineOnHover();
                    }
                }
            }

            svg {
                width: 24px;
                height: 24px;
            }

            .popover {
                background: transparent;
                border: none;
                left: -44px;
                padding-top: 20px;

                .link-wrapper {
                    padding-left: 16px;
                    padding-right: 16px;
                    border: 1px solid;
                    background-color: $white;
                }
            }

            &-icon {
                cursor: pointer;
                margin: 0;
                padding: 0;
                line-height: 1;
                padding-top: 0;
                position: relative;
                top: 1px;

                @media screen and (min-width: $medium-device-max-width) and (max-width: $large-device-max-width) {
                    margin-left: 16px;
                    left: unset;
                }
            }
        }

        .minicart {
            padding: 5px;
            margin-top: 0;
            margin-left: 2.563rem;
            line-height: 1;

            @include media-breakpoint-down(md) {
                top: -5px;
                margin-left: 1.813rem;
            }

            @include media-breakpoint-up(lg) {
                left: -2px;

                .minicart-total {
                    position: relative;
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }

    .site-search {
        .icon {
            &.disabled {
                pointer-events: none;
                opacity: 0.7;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;

        .navigation {
            order: 2;
            height: 80px;
            padding-top: 24px;
            padding-bottom: 24px;

            .main-menu {
                background-color: $white;
                color: $black;
                margin-left: 100px;
                padding: 12px 0 0;

                .accordion {
                    .abc {
                        display: inline-block;
                        padding: 0 10px;
                        margin: 0 10px;
                    }
                }
            }
        }

        .banner {
            padding: 0;
            margin: 0;
            order: 1;
        }

        .site-stripe {
            height: auto;
        }

        .site-stripe-nav-wrapper {
            background-color: $gray;
        }

        .site-stripe-nav {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-color: $gray;
            padding: 8px 12px;

            > * {
                padding-left: 16px;
            }
        }

        .logo {
            order: 1;
            padding: 30px 0 26px;
        }

        .menu {
            order: 2;
        }

        .navbar-nav {
            .nav-item.dropdown {
                position: static;
            }

            .dropdown-menu {
                left: 0;
                width: 100%;
                top: 60px;
            }

            .nav-link {
                color: $black;
            }
        }
    }

    .enhance-wishlist {
        .logo {
            left: initial;

            @include media-breakpoint-between(sm, md) {
                left: 50%;
            }

            @include media-breakpoint-down(sm) {
                left: 100px;
            }
        }
    }
}

.navigation-container {
    height: initial;
    display: flex;

    .navbar-toggler {
        line-height: 1;
        position: absolute;
        left: 0;
        padding-top: 0;
        font-size: initial;
        top: -2px;

        .icon {
            height: 24px;
            width: 24px;
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar-toggler {
            top: 0;
        }

        .header-right {
            order: 3;
            display: flex;
            align-items: center;
            padding-top: 12px;

            .search {
                margin: 0;

                .site-search {
                    .icon {
                        position: absolute;
                        right: 0;
                        width: 18px;
                        top: 4px;
                        border: 0;
                        background: none;
                    }

                    .cross-icon-button {
                        right: 24px;
                        width: 12px;
                        height: 12px;
                        padding: 0;
                        top: 10px;
                        cursor: pointer;

                        .icon-cross {
                            width: 12px;
                            height: 12px;
                            top: 0;
                        }
                    }

                    .form-control {
                        padding-bottom: 1px;
                    }

                    .search-field {
                        border: transparent;
                        border-bottom: 1px solid $shadyLady-gray;
                        border-radius: 0;
                        min-width: 240px;
                        max-height: 28px;
                        padding-right: 40px;

                        &:focus {
                            border-bottom: 1px solid $black;
                        }
                    }

                    .input {
                        padding-bottom: 0;
                    }
                }
            }

            .user {
                margin: 0;
                padding: 0;
                line-height: 1;

                .popover {
                    left: -56px;
                    padding-top: 12px;

                    .link-wrapper {
                        padding-left: 8px;
                        padding-top: 16px;
                        padding-bottom: 8px;
                        padding-right: 16px;
                        border: 0;
                        background-color: $white;

                        a {
                            font-size: 16px;
                            display: inline-block;
                        }
                    }
                }
            }

            .user .user {
                margin: 0;
                padding: 0;
                line-height: 1;
                margin-left: 20px;
                padding-top: 8px;

                @include media-breakpoint-up(md) {
                    margin-left: 36px;
                    padding-top: 16px;
                }
            }
        }
    }

    $tablet-min-width : 1024px;
    $tablet-max-width : 1083px;

    @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        .header-right {
            .search {
                .site-search {
                    .search-field {
                        min-width: unset;
                        max-width: 180px;
                    }
                }
            }
        }
    }
}

.dropdown-toggle {
    &::after {
        content: none;
    }
}

.search-mobile {
    .icon {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .cross-icon-button {
        right: 40px;
        width: 12px;
        height: 12px;
        padding: 0;
        top: 17px;
        cursor: pointer;

        .icon-cross {
            width: 12px;
            height: 12px;
            top: 0;
            right: 0;
        }
    }

    .icon-chevron {
        right: 11px;
        top: 12px;
    }

    .form-control {
        height: 45px;
        border: 0.5px solid $shadyLady-gray;
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;

        &:focus {
            border: 0.5px solid $black;
        }
    }
}

.navbar-header {
    .country-selector {
        margin: 0;
        line-height: normal;
        font-size: 14px;
    }

    .dropdown-trigger {
        font-size: 14px;
    }
}

.site-stripe-nav.navbar-header {
    height: auto;
}

@include media-breakpoint-down(md) {
    body.modal-open-custom.stickyHeader {
        overflow-y: inherit;
    }
}

body.stickyHeader {
    .navigation {
        position: fixed;
        z-index: $zindex-highest + 2;
        width: 100%;
        top: 0;
        max-width: 100%;
        transition: top 0.3s ease-in-out;

        @include media-breakpoint-down(md) {
            position: static;
            top: -60px;
        }
    }

    &.miniCartModal {
        .navigation {
            z-index: 1050;
        }
    }

    .search-mobile {
        position: fixed;
        top: 44px;

        @include media-breakpoint-down(md) {
            position: static;
            top: -60px;
        }

        z-index: $zindex-highest + 1;
        width: 100%;
        transition: all 0.3s linear;
    }

    @include media-breakpoint-up(md) {
        .grid-header,
        .compare-header {
            &.sticky-top {
                top: 80px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &.hideHeader {
            .global-header {
                top: -200px;
                position: sticky;
                transition: top 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
                z-index: 99;
            }
        }

        &.hideBanner {
            .banner {
                overflow-y: hidden;
                height: 0;
                position: fixed;
                top: -10px;
                width: 100%;
            }
        }

        &.navExpanded,
        &.fixedTop {
            .navigation {
                top: 0;
            }

            .search-mobile {
                top: 44px;
            }

            .grid-header,
            .compare-header {
                &.sticky-top {
                    top: 104px;
                }
            }
        }

        &:not(.navExpanded) {
            .grid-header,
            .compare-header {
                &.sticky-top {
                    top: 0;
                }
            }
        }
    }

    .open-menu {
        position: fixed !important;
        z-index: $zindex-highest + 1;
        top: 80px;
    }
}

.quickStartWrapper {
    position: fixed;
    bottom: -200px;
    width: 100%;
    min-height: 50px;
    background: $white;
    z-index: 2;
    transition: all 0.8s ease-out;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    opacity: 0;
    left: 0;

    &.show {
        bottom: 0;
        opacity: 1;
    }

    .title {
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 1.2px;
        margin: 0;
        text-transform: uppercase;
        padding-bottom: 10px;
        @include fontSize($font-body3);
    }

    button {
        background: transparent;
        border: 0;
        outline: none !important;
        position: absolute;
        right: 0;
        padding: 10px;
        top: 0;

        .icon {
            width: 12px;
            height: 12px;
        }
    }

    .product-tile-wrapper {
        display: flex;
        width: 100%;
    }

    .tile {
        padding: 0 1px;
    }

    .tile-image {
        max-width: 81px;
        width: 100%;
    }

    .slick-arrow {
        top: 36px;
    }

    .title-and-cta-wrapper {
        .quick-start-close-cta {
            display: none;
        }
    }

    .quickStartInnerWrapper {
        max-width: 580px;
        margin: 0 auto;
    }

    &:not(.noSlider) {
        .title {
            text-align: center;
            padding: 8px 0;
            max-width: 294px;
            margin: 0 auto;
        }

        .quickStartInnerWrapper {
            padding-bottom: 10px;
            @include media-breakpoint-up(lg) {
                max-width: 408px;
            }
        }
    }

    .swiper-wrapper.center-tile {
        justify-content: center;
    }
}

#guestUser {
    .enhance-wishlist {
        .popover {
            left: -20px;
        }
    }
}

#wishlist-header {
    position: relative;
    top: 2px;
    margin-left: 3rem;

    @include media-breakpoint-down(md) {
        top: -1px;
        margin-left: 2.25rem;
    }

    .icon-wishlist {
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22.75a.72.72 0 0 1-.42-.13c-.32-.21-7.79-5.27-10.24-9.76C-.12 10.18-.45 6.4 2.09 4a6.48 6.48 0 0 1 8.82 0L12 5l1.08-1a6.48 6.48 0 0 1 8.82 0c2.54 2.41 2.21 6.19.75 8.87-2.45 4.49-9.9 9.55-10.22 9.76a.72.72 0 0 1-.43.12zm-5.5-19a4.89 4.89 0 0 0-3.37 1.32c-2 1.87-1.66 4.9-.47 7.07 2 3.59 7.73 7.82 9.34 9 1.6-1.14 7.36-5.36 9.32-8.95 1.28-2.34 1.54-5.68-1-7.49a5.07 5.07 0 0 0-6.32.52l-.88.84 1.45 1.4-.35.36a1 1 0 0 1-1.41 0L9.87 5.07A4.89 4.89 0 0 0 6.5 3.75z'/%3E%3C/svg%3E");
    }
}

@include media-breakpoint-down(md) {
    .global-header {
        position: relative;
    }

    .navExpanded {
        .global-header {
            top: 0 !important;
            position: sticky;
            transition: top 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
            z-index: 99;
        }
    }
}

.countdowntimerslot {
    .countdown {
        font-weight: 600;
        font-size: 0;

        li {
            display: inline-block;
            list-style-type: none;
            font-size: 16px;
        }

        span {
            font-size: 1rem;
            display: inline-block;
        }

        .timerData {
            display: inline-block;
        }
    }

    p {
        display: inline;
    }

    ul,
    .timer-campaign-msg,
    .main-text {
        display: inline;
    }

    span.timerLabel {
        display: none;
    }

    .date-display,
    .main-text {
        font-weight: 600;
        font-size: 1rem;
        display: inline;
    }

    .countdowntimerlink {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .countdowntimermainlink {
        text-decoration: underline;
    }
}

html {
    &:lang(de),
    &:lang(fr),
    &:lang(es) {
        .countdowntimerslot {
            span.timerLabel {
                display: inline-block;
            }
        }
    }

    &:lang(de) {
        .countdowntimerslot {
            span.timerLabel {
                text-transform: lowercase;
            }
        }
    }

    &:lang(fr) {
        .countdowntimerslot {
            span.timerLabel {
                text-transform: uppercase;
            }

            .timercolon {
                @include media-breakpoint-up('md') {
                    padding: 0 6px;
                }

                padding: 0 3px;
            }
        }
    }

    &:lang(es) {
        span.timerLabel {
            text-transform: lowercase;
            padding-right: 3px;
        }

        .timercolon {
            padding: 0 3px;
        }
    }
}

//PD Countdown Timer
.pd_countdowntimer {
    position: relative;

    .countdown-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: $white;
        z-index: 2;
    }

    &.nobgimage {
        .countdown-wrapper {
            position: relative;
            color: $black;
            align-items: start;
            justify-content: start;
        }

        padding: 60px 0;
    }

    .sub-text,
    .alt-sub-text {
        @include fontSize($font-body3);

        letter-spacing: 1.2px;
        line-height: 1.5;
        padding-bottom: 10px;
    }

    .main-text,
    .alt-main-text,
    .date-display {
        @include fontSize($font-medium-desk);
    }

    .countdown {
        display: flex;
        font-size: 0;

        li {
            display: flex;
        }
    }

    .timerData {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hours,
    .minutes,
    .seconds {
        @include fontSize($font-medium-desk);

        line-height: 48px;
    }

    .timerLabel {
        @include fontSize($font-body3);

        line-height: 18px;
    }

    .timercolon {
        @include fontSize($font-medium-desk);

        padding: 0 10px;
        line-height: 48px;
    }

    &.big-size {
        @include media-breakpoint-up('xl') {
            .hours,
            .minutes,
            .seconds,
            .timercolon,
            .main-text,
            .alt-main-text,
            .date-display {
                @include fontSize($font-xxl-desk);

                line-height: 92px;
            }

            .timerLabel {
                @include fontSize($font-body1);

                line-height: 24px;
            }
        }
    }

    &:not(.nobgimage) {
        &::after {
            content: '';
            display: block;
            background: $black;
            opacity: 0.3;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1;
        }

        margin: 32px 0;

        .countdown-wrapper {
            padding: 10px;
            text-align: center;
        }
    }

    @include media-breakpoint-down('lg') {
        .main-text,
        .alt-main-text,
        .date-display,
        .hours,
        .minutes,
        .seconds,
        .timercolon {
            font-size: 33px;
            line-height: 48px;
        }

        .timerLabel {
            @include fontSize($font-body3);

            line-height: 20px;
        }

        .sub-text,
        .alt-sub-text {
            font-size: 12px;
            line-height: 20px;
        }
    }

    @include media-breakpoint-only('md') {
        .main-text,
        .alt-main-text,
        .date-display,
        .hours,
        .minutes,
        .seconds,
        .timercolon {
            font-size: 47px;
            line-height: 56px;
        }

        .timerLabel {
            font-size: 16px;
            line-height: 24px;
        }

        .sub-text,
        .alt-sub-text {
            font-size: 12px;
            line-height: 20px;
        }
    }

    @include media-breakpoint-down('sm') {
        .main-text,
        .alt-main-text,
        .date-display,
        .hours,
        .minutes,
        .seconds,
        .timercolon {
            font-size: 25px;
            line-height: 32px;
        }

        .timerLabel {
            @include fontSize($font-body3);

            line-height: 20px;
        }

        .sub-text,
        .alt-sub-text {
            font-size: 12px;
            line-height: 20px;
        }
    }
}

.myaccount-global-header {
    #guestUser {
        @include media-breakpoint-up(lg) {
            height: 50px;
            top: 7px;
        }

        .user-icon {
            &.user-link {
                @include media-breakpoint-up(lg) {
                    margin-left: 1.5rem;
                    top: -8px;
                    padding: 2px 4px;
                    right: 4px;
                }
            }

            &:hover {
                text-decoration-line: underline;
                text-underline-offset: 4px;
                text-decoration-thickness: 2px;
                text-decoration-color: $fireEngine-red;
            }

            a {
                display: flex;
                gap: 8px;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-up(lg) {
                    padding-bottom: 0;
                }

                &:hover {
                    text-decoration-color: $fireEngine-red !important;
                }

                &:focus {
                    border-radius: 4px;
                    outline-color: $blue-outline !important;
                    text-decoration-line: underline;
                    text-underline-offset: 4px;
                    text-decoration-thickness: 2px;
                    text-decoration-color: $fireEngine-red;
                }

                &.user-icon-desktop {
                    position: relative;
                    top: 5px;
                    padding: 8px;
                    border: 2px solid transparent;

                    span {
                        font-size: 17px;
                        font-weight: 400;
                        line-height: 20px;
                        text-wrap: nowrap;
                    }

                    .kakaoIcon {
                        left: 24px;
                        border-radius: 100px;
                        bottom: 18px;
                        padding: 0;

                        svg.icon {
                            margin: 3px;
                        }
                    }
                }

                &.user-icon-mobile {
                    padding: 10px;
                    margin-top: -12px;

                    &:focus {
                        padding: 8px;
                        margin-top: -12px;
                        border-radius: 4px;
                        border: 2px solid $blue-outline;
                    }

                    .kakaoIcon {
                        left: 26px;
                        border-radius: 100px;
                        bottom: 20px;
                        padding: 0;

                        svg.icon {
                            margin: 4px;
                        }
                    }
                }
            }

            &.kakao-kr {
                a.user-icon-desktop {
                    padding-top: 0.75rem;
                    top: 2px;
                    gap: 1rem;
                }
            }
        }

        .enhance-wishlist {
            .popover {
                left: -82px;
                top: 58px;
                background-color: $white;
                width: 264px;
                padding: 16px 16px 20px;
                box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.45);
                border: 1px solid $black;
                border-radius: 4px;
                cursor: auto;

                &:lang(ko) {
                    @include media-breakpoint-up(lg) {
                        left: -74px;
                    }
                }

                @include media-breakpoint-down(md) {
                    left: -116px;
                    top: 52px;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 48%;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                    border: 10.5px solid transparent;
                    border-bottom-color: $black;

                    @include media-breakpoint-down(md) {
                        border: 11px solid transparent;
                        border-bottom-color: $black;
                    }
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 48.25%;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                    border: 9.5px solid transparent;
                    border-bottom-color: white;
                }
            }
        }
    }

    #wishlist-header {
        @include media-breakpoint-down(md) {
            margin-left: 2.25rem;
        }
    }

    .kakao-wrapper#kakaoSection {
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
        border-bottom: 1px solid $gainsboroNew;

        .kakao-auth-container {
            margin-top: 0 !important;
            margin-bottom: 0;

            .kakao-container {
                width: 100%;

                .ll_kakao-title {
                    display: none;
                }

                .kakao-auth-btn {
                    background-color: $goldenYellow;
                    color: $black;
                    width: 100%;
                    border: none;
                    display: flex;
                    gap: 8px;
                    font-weight: bold;
                    height: 53px;
                }

                .ll_kakao-or {
                    display: none;
                }
            }
        }
    }

    .signin-wrapper {
        padding-bottom: 1rem;
        border-bottom: 1px solid $gainsboroNew;
        margin-bottom: 1rem;

        &:lang(ko) {
            padding-bottom: 1.25rem;
            margin-bottom: 1.25rem;
        }

        .buttonSecondary-1ZKFx {
            background-color: $black !important;
            height: 53px;

            &:lang(ja),
            &:lang(ko),
            &:lang(zh) {
                line-height: 48px;
            }

            &:hover {
                background-color: $black !important;
                border-color: $black !important;
                color: $white !important;
            }
        }
    }

    .signup-wrapper {
        .title {
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0.75rem;

            @include media-breakpoint-up(lg) {
                font-size: 24px;
                line-height: 28px;
            }

            &:lang(ja),
            &:lang(ko),
            &:lang(zh) {
                font-size: 18px;

                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }
            }
        }

        .description {
            font-size: 17px;
            line-height: 20px;
            font-weight: 600;
            margin-bottom: 0.75rem;
        }

        .perks-list {
            .perks-icon {
                margin-bottom: 0.75rem;
                display: flex;
                gap: 0.5rem;

                span {
                    font-size: 17px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }

            &:last-child {
                margin-bottom: 1rem;
            }
        }

        .ao-create-account {
            background-color: $crimson !important;
            border: none;
        }

        .llrichtext {
            padding: 0 !important;
        }
    }
}

.myaccount-redesign {
    #authUser {
        @include media-breakpoint-up(lg) {
            height: 50px;
            margin-left: 1.5rem;
            padding: 2px 4px;
            right: 0;

            ~ #wishlist-header {
                margin-left: 2.25rem;
            }
        }

        a {
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
            }

            &:hover {
                text-decoration-color: $fireEngine-red !important;
            }

            &:focus {
                border-radius: 4px;
                outline-color: $blue-outline !important;
                text-decoration-line: underline;
                text-underline-offset: 4px;
                text-decoration-thickness: 2px;
                text-decoration-color: $fireEngine-red;
            }

            &.user-icon-desktop {
                position: relative;
                top: 6px;
                padding: 7px 8px;
                border: 2px solid transparent;
                padding-bottom: 6px;

                span {
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 20px;
                    text-wrap: nowrap;
                }

                &:hover {
                    text-underline-offset: 4px;
                    text-decoration-thickness: 2px;
                    text-decoration-color: $fireEngine-red;
                }

                &:focus {
                    ~ .popover {
                        @include media-breakpoint-up(lg) {
                            top: 58px;
                        }
                    }
                }
            }

            &.user-icon-mobile {
                padding: 10px;
                margin-top: -11px;
                position: relative;
                left: 6px;

                &:focus {
                    padding: 8px;
                    margin-top: -11px;
                    border-radius: 4px;
                    border: 2px solid $blue-outline;
                }
            }
        }

        .popover {
            left: -55px;
            top: 53px;
            background-color: $white;
            width: 264px;
            padding: 16px 16px 20px;
            box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.45);
            border: 1px solid $black;
            border-radius: 4px;
            cursor: auto;

            @include media-breakpoint-down(md) {
                left: -100px;
                top: 42px;
            }

            &.show {
                ~ a.user-icon-desktop {
                    text-underline-offset: 4px;
                    text-decoration-thickness: 2px;
                    text-decoration-color: $fireEngine-red;
                }
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 44%;
                bottom: 100%;
                width: 0;
                height: 0;
                border: 10.5px solid transparent;
                border-bottom-color: $black;

                @include media-breakpoint-down(md) {
                    border: 11px solid transparent;
                    border-bottom-color: $black;
                }
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 44.45%;
                bottom: 100%;
                width: 0;
                height: 0;
                border: 9.5px solid transparent;
                border-bottom-color: white;
            }

            .account-wrapper {
                h3.title {
                    color: $black;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 24px;
                    border-bottom: 1px solid $gainsboroNew;
                    padding-bottom: 0.5rem;
                    margin-bottom: 0;
                }

                a {
                    &:hover {
                        text-decoration: none;
                    }
                }

                a.acc-db-link {
                    border-bottom: 1px solid $gainsboroNew;
                    display: flex;
                    justify-content: flex-start;
                    padding: 1.25rem 0.5rem;
                    gap: 1rem;
                    margin-bottom: 0;

                    svg.icon {
                        width: 16px;
                        height: 16px;
                    }

                    span {
                        font-size: 17px;
                        font-weight: 400;
                        line-height: 20px;
                        text-wrap: wrap;
                    }
                }

                a.account-menu-links {
                    display: flex;
                    flex-direction: row;
                    padding-top: 20px;
                    display: flex;
                    width: 100%;
                    gap: 1rem;
                    justify-content: flex-start;
                    padding-left: 8px;
                    padding-right: 8px;
                    margin-bottom: 4px;

                    svg.icon {
                        width: 16px;
                        height: 16px;
                    }

                    span {
                        font-size: 17px;
                        font-weight: 400;
                        line-height: 20px;
                    }

                    &:nth-last-child(2) {
                        margin-bottom: 1.25rem;
                    }

                    &:last-child {
                        border-top: 1px solid $gainsboroNew;
                        padding-top: 0.5rem !important;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.kakaoIcon {
    position: absolute;
    left: 15px;
    display: inline-block;
    padding: 0;
    background: $goldenYellow;
    border-radius: 100%;
    bottom: 8px;
    border: 1.5px solid white;

    svg.icon {
        width: 14px !important;
        height: 14px !important;
        margin: 4px;
    }

    &.kakao-global-header {
        left: 23px;
        padding: 3px;
        border-radius: 100px;
        bottom: 16px;
    }
}
