.termsAndConditionsModal,
.privacyPolicyModal {
    word-break: break-all;
    z-index: 99999992;

    .modal-header {
        border-bottom: none;
    }

    .modal-content {
        padding: 40px 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        word-break: break-word;
    }

    .modal-close {
        background: transparent;
        border: none;
        padding: 0;
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }
}
