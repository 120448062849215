@import "~base/variables";
@import '../mixins/utils';
@import '../global/fontVariables';
@import '../mixins/fontProp';

$border-radius: 4px;

.compare-bar-wrapper {
    z-index: 3;
    background: $white;
    height: 120px;
    width: 375px;
    left: 48.5%;
    bottom: -120px;
    position: fixed;
    box-shadow: 8px 0 12px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s ease;

    &.visible {
        bottom: 0;
    }

    @include media-breakpoint-down(xs) {
        width: 100%;
        left: unset;
        bottom: -200px;
        height: auto;
    }

    .compare-bar {
        overflow: hidden;
    }

    .compare-bar-header {
        display: flex;
        justify-content: space-between;
        padding: 12px 2px 0;

        @include media-breakpoint-down (xs) {
            padding-bottom: 20px;
        }

        h6 {
            @include fontSize($font-body2);
        }

        .action-btns {
            .clear-all {
                @include fontSize($font-body2);

                color: $black;
                line-height: 20px;
                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-color: $gainsboro-gray2;

                &:hover {
                    text-decoration-color: $fireEngine-red;
                }
            }
        }
    }

    @include media-breakpoint-up (lg) {
        .product-slots {
            height: 6.25em;
        }
    }

    .slot-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        padding-right: 12px;
    }

    .slot {
        background-color: $gainsboroNew;
        height: 54px;
        margin: 0 auto;
        position: relative;
        width: 54px;
        border-radius: $border-radius;
        margin: 5px 5px 10px;

        img {
            float: left;
            height: 100%;
            width: 100%;
            position: static;
            border-radius: $border-radius;
        }

        .close {
            svg {
                position: absolute;
                height: 16px;
                width: 16px;
                right: 4px;
                top: 4px;
                border-radius: 50%;
                background: $white;
                padding: 2px;
            }

            &:focus {
                svg {
                    border: 2px solid $black;
                }
            }
        }
    }

    .action {
        text-align: center;

        .compare-btn {
            display: block;
            border-radius: 4px;
            position: relative;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            background-color: $black;
            color: $white;
            line-height: unset;
            height: 54px;

            &:hover {
                background-color: $bright-gray2;
                border-color: $bright-gray2;
            }

            &.disabled {
                background-color: $silver-grey;
                cursor: default;
            }
        }
    }
}

.comparisonModal {
    top: 100%;
    transition: top 0.6s linear;
    background-color: $white;
    overscroll-behavior: none;

    &.modal {
        overflow-x: scroll;

        @include media-breakpoint-up(lg) {
            overflow-x: unset;
            overflow-y: scroll;
            scroll-behavior: smooth;
            padding-right: 0 !important;
        }
    }

    &.show {
        top: 0;
    }

    .modal-dialog {
        margin: 0;
        max-width: unset;
    }

    .modal-content {
        width: fit-content;
        box-shadow: none;
        border: none;

        @include media-breakpoint-up (lg) {
            padding: 20px 60px 30px;
            width: 100%;
            height: 100%;
            align-items: center;
        }

        .modal-header {
            justify-content: unset;
            background-color: $white;
            padding-bottom: 24px;
            position: sticky;
            top: 0;
            z-index: $zindex-medium;

            @include media-breakpoint-up (lg) {
                width: 100%;
                justify-content: space-around;
            }

            .modal-title {
                @include fontSize($font-body1);

                font-weight: 500;
                line-height: 24px;
                margin-left: 8px;

                @include media-breakpoint-up (lg) {
                    @include fontSize($font-medium);

                    font-weight: bold;
                    line-height: 32px;
                    position: relative;
                }
            }

            .collapseModal {
                margin: unset;
                padding: unset;
            }

            .clear-link {
                @include fontSize($font-body2);

                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-color: $gainsboro-gray2;
                margin-left: auto;

                &:hover {
                    text-decoration-color: $fireEngine-red;
                }

                @include media-breakpoint-up (lg) {
                    margin-left: unset;
                }

                @include media-breakpoint-down (md) {
                    position: sticky;
                    right: 10px;
                }
            }
        }

        .modal-body {
            .body-container {
                justify-content: center;
            }

            .attribute-data,
            .tile-data {
                display: grid;
                grid-template-columns: 40vw 40vw 40vw;
                grid-column-gap: 12px;

                @include media-breakpoint-up(lg) {
                    grid-template-columns: 21vw 21vw 21vw;
                    grid-column-gap: 30px;
                }
            }

            .tile-section-bg {
                display: none;
                position: fixed;
                top: 62px;
                border-top: 1px solid var(--border-subdued-grey-25, $zumthor);
                border-bottom: 1px solid var(--border-subdued-grey-25, $zumthor);
                box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
                width: 98%;
                left: 0;
                margin: 0 5px;
                z-index: $zindex-medium;

                @include media-breakpoint-up (lg) {
                    top: 70px;
                }
            }

            .tile-data {
                background: $white;

                .product-header {
                    position: relative;
                    height: 100%;

                    .remove-compare-product {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        z-index: 2;

                        .icon-cross {
                            height: 26px;
                            width: 26px;
                            padding: 4px;
                            background: $white;
                            border-radius: 50%;
                        }
                    }
                }

                .product {
                    height: 100%;

                    .product-tile {
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        @include media-breakpoint-only (lg) {
                            .slick-slide {
                                margin-right: 10px;
                            }
                        }

                        .tile-body {
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;

                            .pdp-link {
                                flex-grow: 1;
                            }

                            .price {
                                .sales {
                                    line-height: 24px;
                                    @include fontSize($font-body1, !important);
                                }

                                .strike-through {
                                    margin-right: 0;
                                    display: inline;
                                    color: $bright-gray2;
                                }
                            }
                        }
                    }
                }

                .product-tile {
                    margin-bottom: 0;

                    .tile-image {
                        transition: 0.3s ease;

                        &.hover-image {
                            display: none !important;
                        }
                    }

                    .wishlistTile {
                        display: none;
                    }

                    .tile-body {
                        .minicart-icon {
                            display: none;
                        }

                        .quickViewLink {
                            .quickview {
                                border: 1px solid $black;
                                text-decoration: none;
                                font-weight: 600;
                                padding: 16px;
                                text-align: center;
                                display: block;
                                margin-top: 20px;
                                border-radius: 4px;
                                position: unset;
                                margin-left: unset;
                                margin-bottom: 26px;
                                text-transform: uppercase;
                                line-height: 20px;
                                letter-spacing: 1.2px;
                                font-size: 16px;

                                &:hover {
                                    background-color: $black;
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                .modal-prod-slot {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $white-lilac;
                    height: 60%;
                    padding: 10px;
                    min-height: 50%;
                    border: 1px solid var(--Border-Subdued, $gainsboroNew);

                    &.add-prod {
                        span {
                            text-align: center;
                        }
                    }

                    .add-prod-text {
                        cursor: pointer;
                        text-align: center;

                        @include media-breakpoint-up (lg) {
                            width: 50%;
                        }

                        svg {
                            margin-bottom: 8px;

                            @include media-breakpoint-up (lg) {
                                margin-bottom: 24px;
                            }
                        }

                        .add-prod-link {
                            @include fontSize($font-body1);

                            line-height: 24px;
                            text-decoration: none;
                            display: block;
                        }
                    }
                }

                &.sticky-top {
                    padding: 10px 5px 12px;
                    top: 64px;

                    @include media-breakpoint-up (lg) {
                        top: 71px;
                    }
                }

                &.minimize {
                    .remove-compare-product {
                        left: 36px;
                        right: unset;
                        top: 2px;

                        .icon-cross {
                            height: 16px;
                            width: 16px;
                            padding: 2px;
                        }
                    }

                    .product-tile {
                        .image-container {
                            display: inline;
                            padding-bottom: 55px;
                            background: unset;

                            .icon-wishlist {
                                display: none;
                            }

                            .tile-image {
                                width: 55px;
                            }

                            .promoCallout {
                                display: none !important;
                            }
                        }

                        .tile-body {
                            .price-link,
                            .color-swatches,
                            .cdp-bv-star-rating,
                            .flag {
                                display: none !important;
                            }

                            .quickview {
                                margin: 0;
                            }
                        }
                    }

                    .modal-prod-slot {
                        display: none;

                        svg {
                            margin-bottom: unset;
                        }
                    }

                    + .tile-section-bg {
                        display: block;
                    }
                }
            }

            .attribute-data {
                margin: 30px 0;
                max-height: 165px;
                overflow-y: clip;

                &.no-margin {
                    margin-bottom: 0;
                }

                &.low-margin {
                    margin-top: 12px;
                }

                &.low-height {
                    max-height: 80px;
                }

                &.expanded {
                    max-height: unset;
                }

                .data-block {
                    .header-parent {
                        width: 80vw;
                        margin-bottom: 6px;

                        .sticky-header {
                            left: 10px;
                            position: sticky;
                            position: -webkit-sticky;

                            .compare-icons {
                                height: 24px;
                                width: 24px;
                                margin-right: 8px;
                                margin-bottom: 4px;
                            }

                            h4 {
                                display: inline;
                                line-height: 20px;
                                font-weight: bold;
                                @include fontSize($font-body1);

                                @include media-breakpoint-up (lg) {
                                    @include fontSize($font-xsmall-desk);
                                }
                            }
                        }
                    }

                    ul {
                        li {
                            @include fontSize($font-body2);

                            @include media-breakpoint-up (lg) {
                                @include fontSize($font-body1);
                            }
                        }
                    }

                    &:not(:first-child) {
                        li {
                            &:first-child {
                                margin-top: 34px;
                            }
                        }
                    }

                    .bullets {
                        list-style: unset;
                        list-style-position: inside;
                    }

                    .fabric-title {
                        font-weight: 500;
                        color: $bright-gray;
                    }

                    .fabric-description {
                        margin-bottom: 12px;
                    }
                }
            }

            .attribute-data-container {
                &:not(:last-child) {
                    border-bottom: 1px solid $gainsboro-gray;
                }

                &.no-border {
                    border-bottom: none;

                    .attribute-data {
                        margin-bottom: 0;
                        max-height: unset;
                    }

                    ~ .attribute-data-container {
                        .material-block {
                            ul {
                                li {
                                    &:first-child {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .show-more,
            .show-less {
                @include fontSize($font-body3);

                font-weight: 500;
                line-height: 18px;
                text-decoration: underline;
                color: $bright-gray;
                position: relative;
                bottom: 20px;
            }

            .gradient {
                height: 24px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) -7.14%, #fff 73.77%);
                bottom: 23px;
                position: relative;
            }
        }
    }
}

.product-comparison {
    .back-to-results {
        margin: 1em 0;
    }

    .product-header > .col {
        padding: 0;
    }
}

.product-tile {
    .tile-body {
        .compare {
            @include fontSize($font-body3);
        }
    }
}

.table-striped-column td:nth-of-type(odd) {
    background-color: $grey2;
}
