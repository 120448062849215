@import "./global/variables";

html {
    &:lang(ja) {
        .minicart-link {
            .minicart-quantity {
                font-family: 'Noto Sans JP', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
            }
        }
    }
}

html {
    &:lang(ko) {
        .minicart-link {
            .minicart-quantity {
                font-family: 'Noto Sans KR', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
            }
        }
    }
}

html {
    &:lang(zh) {
        .minicart-link {
            .minicart-quantity {
                font-family: 'Noto Sans HK', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
            }
        }
    }
}

.minicart-link {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;

    .minicart-icon {
        width: 24px;
        height: 24px;
        text-align: center;
    }

    .minicart-quantity {
        background-color: $transparent;
        border-radius: 0;
        width: 17px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        position: absolute;
        color: $red;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        // Overriding locale specific styles for minicart quantity
        // to provide the same experience across different sites
        font-family: 'Calibre', sans-serif !important;
    }

    @include media-breakpoint-up(lg) {
        width: 19px;
        height: 26px;

        &.show {
            display: block;
        }

        .minicart-icon {
            display: inline-block;
        }

        .minicart-quantity {
            border-radius: initial;
            width: auto;
            height: auto;
            top: 55%;
            left: 62%;
            line-height: 1;
            display: inline-block;
        }
    }

    &.product-added {
        &::after {
            display: block;
            animation: pulse 3s 2;
            background-color: $crimson;
            border-radius: 50%;
            content: "";
            height: 0.5rem;
            position: absolute;
            right: -0.475rem;
            top: -0.1875rem;
            width: 0.5rem;
        }
    }
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }

    50% {
        box-shadow: 0 0 0 0 $newRed;
    }

    to {
        box-shadow: 0 0 0 0.5rem transparent;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
        -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }

    50% {
        box-shadow: 0 0 0 0 $newRed;
        -moz-box-shadow: 0 0 0 0 $newRed;
    }

    to {
        box-shadow: 0 0 0 0.5rem transparent;
        -moz-box-shadow: 0 0 0 0.5rem transparent;
    }
}

.minicart {
    .google-pay-container {
        display: none;
    }

    .popover {
        min-width: 353px;
        max-width: 353px;
        min-height: 607px;
        top: 32px;
        right: 3px;
        border-radius: 0;

        .cart {
            padding-top: 0;
            padding-bottom: 0;

            .checkout-continue {
                margin: 0;
            }
        }

        .minicart-title {
            @include font(400, 23px, $black, 27px, 0.2px);

            width: 100%;
            text-align: center;
            padding: 16px 0 12px;
            margin-bottom: 12px;
            background-color: $white;
        }

        .minicart-quantity {
            @include font(400, 23px, $black, 27px, 0.2px);

            background-color: transparent;
            width: auto;
            top: 0;
            left: 0;
        }

        .product-summary {
            max-height: 365px;

            .minicart-modal-content {
                padding-left: 24px;
                padding-right: 24px;
            }

            .line-item-divider {
                margin: 16px 0;
            }

            .card {
                border: 1px solid $light-gray;
                border-radius: 0;
                margin-bottom: 28px;
                padding: 0;

                .card-body {
                    padding: 16px 8px;
                    margin-top: 0;
                }

                .product-line-item {
                    .bonus-title {
                        margin-bottom: 8px;
                    }

                    .line-item-header {
                        align-items: center;
                        margin-bottom: 16px;
                        position: relative;

                        .line-item-name {
                            @include font(600, 16px, $black, 16px, normal);

                            white-space: normal;

                            .line-item-name-desc {
                                @include font(400, 16px, $black, 16px, normal);

                                white-space: nowrap;
                            }

                            .product-flag {
                                @include font(400, 16px, $red, 16px, normal);
                            }
                        }

                        .remove-btn {
                            margin: 0;
                            line-height: 1;
                            min-width: 14px;
                            font-size: inherit;
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor: pointer;

                            .icon-close {
                                width: 14px;
                                height: 14px;
                                pointer-events: none;
                            }
                        }
                    }
                }

                .product-line-item-details {
                    overflow-y: visible;

                    .item-image {
                        height: 128px;
                        width: 106px;
                        margin-right: 12px;

                        .product-image {
                            max-height: 128px;
                        }
                    }

                    .item-attributes {
                        flex-direction: column;
                        width: 100%;
                        justify-content: flex-start;
                    }

                    .line-item-attributes {
                        .value,
                        .line-item-instock-date {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            float: right;
                            max-width: 100px;
                        }
                    }

                    .line-item-attributes {
                        margin-bottom: 24px;

                        .line-item-instock-date {
                            max-width: 80px;
                        }
                    }

                    .line-item-quantity {
                        .quantity {
                            width: auto;
                            height: 28px;
                            border: 0;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                .item-attributes,
                .line-item-unit-price,
                .line-item-total-price {
                    display: flex;
                    justify-content: space-between;
                }

                .line-item-attributes,
                .quantity-label,
                .line-item-total-text,
                .line-item-promo,
                .price,
                .pricing {
                    font-weight: 400;
                    font-size: 16px;
                }

                .strike-through {
                    color: $bright-gray2;
                }

                .line-item-total-price-amount {
                    font-weight: 600;
                    margin-right: 4px;
                }

                .price {
                    display: flex;
                }

                .line-item-promo {
                    color: $red;
                    text-align: right;
                    margin-top: 16px;
                }

                .sales {
                    font-weight: 400;
                }
            }
        }

        #removeProductModal {
            @include font(400, 20px, $black, normal, normal);

            .modal-dialog {
                max-width: 466px;
                margin-top: 166px;
            }

            .modal-header {
                padding: 4px 0 8px 12px;
                line-height: 33px;

                .close {
                    margin: 4px 36px 0;
                    padding: 0;
                }
            }

            .modal-body {
                .product-to-remove {
                    font-weight: 400;
                }
            }

            .modal-footer {
                border: 0;
                padding: 32px 12px;

                .btn {
                    flex-grow: 1;
                }

                .cart-delete-confirmation-btn {
                    margin-left: 12px;
                }
            }
        }

        .minicart-footer {
            border: 0;
            background-color: $white;

            .estimated-total {
                display: flex;
                justify-content: space-between;
                margin: 16px 0;
            }

            .estimated-total,
            .checkout-continue {
                padding-left: 24px;
                padding-right: 24px;

                .continue-shopping-btn {
                    display: none;
                }
            }

            .view-cart-button {
                @include font(600, null, null, null, null);

                &:hover {
                    text-decoration: none;
                }
            }

            .sub-total-label {
                @include fontSize($font-body);

                font-weight: 400;
            }

            .sub-total {
                @include fontSize($font-body);

                font-weight: 600;
            }

            .checkout-continue {
                a {
                    height: 53px;
                    line-height: 53px;
                    padding: 0;
                    margin-bottom: 16px;

                    @include media-breakpoint-up(lg) {
                        &:lang(ko) {
                            margin-bottom: 16px;
                            font-size: 16px;
                        }

                        &:lang(ja) {
                            line-height: 20px;
                            padding: 14px 0;
                            letter-spacing: 1.6px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.row-reverse {
    flex-direction: row-reverse;
}
