$white: #fff;
$black: #000;
$gray: #fafafa;
$red: #d22030;
$dark-gray: #565656;
$medium-gray: #959595;
$bright-gray: #57585b;
$light-gray: #e0e0e0;
$gainsboro-gray: #d5d5d5;
$snuff-gray: #e2e1e2;
$transparent: transparent;
$transparentSafari: rgba(255, 255, 255, 0);
$shadyLady-gray: #979797;
$nightrider-grey: #333;
$venetian-red: #d0021b;
$gainsboro-white: #d8d8d8;
$white-smoke: #ececec;
$dove-gray: #636363;
$dust-gray: #9b9b9b;
$suva-gray: #8d8d8d;
$linen-red: #faebea;
$cardinal-red: #d02435;
$alto-gray: #dadada;
$salem-green: #039e33;
$cod-gray: #161616;
$scorpion: #606060;
$amour: #fae8ea;
$skeptic: #cdecd6;
$quartz: #d6e4f4;
$cornflower-blue: #4a90e2;
$corn: #efc100;
$oasis: #fcf3cc;
$fireEngine-red: #c8102e;
$cardinal: #d21d2e;
$very-light-grey: #ccc;
$ghost: #d6d6d7;
$dim-gray: #656565;
$blackCurrent: #2c282e;
$matterhorn: #4d4d4d;
$crimson: #d31334;
$cardinal: #b81c29;
$newRed: #f00;
$gainsboroNew: #d9d9d9;
$newGrey: #797979;
$zumthor: #d3d5d7;
$disabled-grey: #8e9196;
$strikethrough-grey: #6b6f74;
$crimson-dark: #d31613;
$goldenYellow: #fee500;
$dark-golden: #8a6c00;
$light-cream: #fff7da;
$vivid-red: #d20014;
$light-pink: #ffe1e4;

// swatch colors
$yellow: #f5cd2b;
$orange: #fb7f40;
$beige: #dcc584;
$blue: #8bb1c4;
$brown: #6f3e20;
$green: #209015;
$grey: #888;
$navy: #254470;
$pink: #e56289;
$purple: #5c37a0;
$blue-misc: #006aa0;
$blue-outline: #005fcc;
$red-misc: #f76767;
$neon: #ffe700;
$darkCharcoal: #323232;
$gainsboro: #e6e6e6;
$gainsboro2: #e5e5e5;
$silver: #c0c0c0;
$suva-grey: #8c8c8c;
$falu-red: #911620;
$silver-grey: #bfbfbf;
$light-grey: #d4d4d4;
$wan-white: #e7e8e4;
$light-white-smoke: #f2f2f2;
$bright-gray2: #575f5b;
$java: #633527;
$dusty-clay: #ab6b5e;
$contour: #ebc8a4;
$tan: #d6a891;
$raven: #6b6f74;
$gainsboro-gray2: #8d9196;
$bright-gray2: #53565a;
$white-lilac: #f1f1f2;
$gainsboro-gray3:#e5e6e7;
$scrollbarGrey: #f5f5f5;
$dark-green:#0f752f;
$account-success:#ddfbe7;
$blue2: #028ff5;
$gainsboro-gray-link: #8d9196;
$darkGray: #828282;
