@include media-breakpoint-down(xs) {
    .header-search {
        .search-button {
            display: block;
        }
    }

    .search-row {
        .search-button {
            display: none;
        }
    }
}

.search-button {
    display: none;
    margin-top: 0.375em; /* 6/16 */
    margin-right: 0.375em;
}

.site-search {
    height: auto;

    .icon-button {
        background: $transparent;
        border: none;
    }

    @include media-breakpoint-only(md) {
        width: 100%;
    }

    @include media-breakpoint-up(xl) {
        width: 240px; /* 320/16 */
    }

    @include media-breakpoint-only(sm) {
        width: 100%;
    }
}

.suggestions-wrapper {
    .suggestions {
        border: none;
        height: auto;
        background-color: $white;
        padding: 16px 12px calc(32px + env(safe-area-inset-bottom));

        .header {
            border-bottom: 1px solid $light-gray;
            color: $raven;
            padding: 0 0 12px;
            font-weight: 600;
            letter-spacing: 1.33px;
            font-size: 16px;

            &:not(:first-child) {
                border-top: none;
                padding-top: 28px;
            }
        }

        .items {
            padding: 12px 0 0;

            .item {
                padding: 0;

                .term {
                    padding-top: 2px;
                }

                .name {
                    margin-top: -3px;
                    padding-bottom: 8px;

                    a {
                        white-space: normal;
                    }

                    p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            &.did-you-mean {
                a {
                    letter-spacing: 1.33px;
                }
            }

            &.products {
                padding-top: 8px;
            }

            &.categories {
                .item {
                    padding-bottom: 24px;

                    a {
                        letter-spacing: 1.33px;
                    }
                }
            }

            .product-img {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .site-search {
        width: 100%;
    }
}

@include media-breakpoint-only(md) {
    .suggestions-wrapper {
        .suggestions {
            bottom: initial;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            min-width: 100%;
            position: fixed;

            .suggestion-img-wrapper {
                max-width: 10%;
            }

            .items {
                .product-img {
                    width: 64px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .suggestions-wrapper {
        .suggestions {
            top: 24px;
            bottom: initial;
            border: none;
            width: 455px;
            height: auto;
            background-color: $white;
            right: -130px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            padding: 12px 32px 16px;

            .header {
                padding-bottom: 4px;
                font-weight: 400;

                &:not(:first-child) {
                    padding-top: 8px;
                }

                &.categories-header {
                    padding-top: 16px;
                }
            }

            .items {
                padding-top: 8px;

                &.categories {
                    .item {
                        padding-bottom: 0;

                        .name {
                            padding-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $medium-device-max-width) and (max-width: $large-device-max-width) {
    .suggestions-wrapper {
        .suggestions {
            right: -100px;
        }
    }
}

// CSS for Enhancements on Keyword Search Prominence. Wrapper Class = 'newSearch'
.navigation-container {
    .header-right {
        .search {
            .site-search {
                &.newSearch {
                    z-index: $zindex-higher;

                    .search-field {
                        border-radius: 4px;
                        border: 1px solid $blackCurrent;
                        padding: 0 26px 0 36px;
                        max-height: unset;
                        height: 50px;
                        color: $black;

                        &::placeholder {
                            @include fontSize($font-body2);

                            color: $bright-gray;
                        }

                        &:hover {
                            border: 1px solid $black;

                            &::placeholder {
                                color: $black;
                            }
                        }

                        &:focus {
                            border: 1.5px solid $black;

                            &::placeholder {
                                color: $bright-gray;
                            }
                        }
                    }

                    .search-icon {
                        padding: 0;
                        left: 12px;
                        position: absolute;
                        top: 48%;
                        transform: translateY(-52%);
                        width: unset;
                        right: unset;

                        .icon {
                            position: unset;
                            right: unset;
                        }
                    }

                    .cross-icon-button {
                        top: 50%;
                        transform: translateY(-50%);
                        right: 16px;
                    }
                }
            }
        }
    }

    .overlay-div {
        background-color: $black;
        opacity: 0.5;
        z-index: $zindex-medium;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@include media-breakpoint-down(lg) {
    .outer-header-container {
        .search-mobile {
            .site-search {
                &.newSearch {
                    width: unset;
                    padding: 8px 10px;
                    background-color: $gray;
                    margin: unset;

                    &.focused {
                        z-index: $zindex-high;
                    }

                    .search-field {
                        border-radius: 4px;
                        border: 1px solid $gainsboro-gray;
                        height: 44px;
                        padding: 0 16px 0 40px;
                        color: $black;

                        &::placeholder {
                            @include fontSize($font-body2);

                            color: $bright-gray;
                        }

                        &:focus {
                            border: 1px solid $blackCurrent;

                            &::placeholder {
                                color: $bright-gray;
                            }
                        }
                    }

                    .search-icon {
                        top: 50%;
                        transform: translateY(-50%);
                        margin-left: 16px;
                        right: unset;
                        width: unset;

                        .icon-search {
                            width: 16px;
                        }
                    }

                    .cross-icon-button {
                        right: 26px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .overlay-div {
            background-color: $black;
            opacity: 0.5;
            z-index: $zindex-medium;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
