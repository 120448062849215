.product-grid-component {
    margin-bottom: 48px;

    .product-grid-carousel {
        > .slick-next {
            right: 10px;
        }

        > .slick-prev {
            left: 10px;
        }

        .slick-arrow {
            background-color: $white;
            opacity: 0.5;
            width: 28px;
            height: 35px;
            top: 40%;
        }

        .slick-dots {
            padding: 0 10px;
        }
    }

    .title-text {
        max-width: 717px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 32px;
        line-height: 1.1;
        @include fontSize($font-small);
    }

    .product {
        margin: 8px auto;
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5);

        .tile-body {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .grid-cta {
        .btn {
            margin: 52px auto 0;
            display: block;
            font-weight: 600;
            width: 300px;

            &.cta-text-wrap {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;

        .product-grid-carousel {
            display: flex;
            flex-wrap: wrap;
            margin-right: -10px;
            margin-left: -10px;
        }

        .products-count-1 {
            .item-0.offset-lg-4 {
                margin-top: 0;
            }
        }

        .products-count-2 {
            justify-content: center;
        }

        .products-count-2,
        .products-count-3,
        .products-count-4,
        .products-count-6,
        .products-count-7 {
            .item-0,
            .item-2 {
                margin-top: 308px;
            }
        }

        .products-count-4,
        .products-count-6,
        .products-count-7 {
            .item-4,
            .item-6,
            .item-3.offset-lg-4 {
                margin-top: -308px;
            }
        }

        .product {
            box-shadow: none;

            .tile-body {
                padding: 16px 0;
            }
        }

        .grid-cta {
            .btn {
                width: 425px;
                margin-top: 0;
            }
        }
    }
}
