.cancel-success-alert {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $skeptic;
    padding: 10px;

    @include media-breakpoint-up('sm') {
        left: 30%;
        width: 40%;
    }
}

.cancel-failure-alert {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $linen-red;
    padding: 10px;

    @include media-breakpoint-up('sm') {
        left: 30%;
        width: 40%;
    }
}

.cancel-order-btn {
    width: 100%;
    font-weight: 600;
    margin-top: 20px;

    .cancel-order {
        text-transform: uppercase;
    }
}

.order-cancel-modal {
    @include media-breakpoint-up('sm') {
        // bootstrap modal override
        .modal-dialog {
            width: 420px;
        }
    }

    @include media-breakpoint-down('sm') {
        .modal-dialog {
            position: fixed;
            right: 0;
            bottom: 0;
            margin: 0;
            width: 100%;
        }

        .modal-dialog-centered {
            display: block;
        }
    }

    .modal-content {
        padding-bottom: 0;
        background-clip: border-box;
        border: 1px solid $light-gray;
        border-radius: 0;
        box-shadow: 0 2px 4px 0 rgba(188, 188, 188, 0.5);

        .custom-header {
            background-color: $amour;

            .cancel-header-content {
                padding: 0 10px;
                font-weight: 600;
                line-height: 48px;

                .cancel-header-text {
                    display: inline-block;
                    margin-right: 20px;
                    padding: 10px 0;
                    line-height: 24px;
                    vertical-align: middle;
                }
            }
        }

        .order-images-modal {
            img {
                margin: 10px 10px 10px 0;
                height: 80px;
            }

            span {
                width: 75px;
                margin: 10px 10px 10px 0;
                text-align: center;
                border: 1px solid;
                vertical-align: middle;
                line-height: 74px;
                height: 80px;
                display: inline-block;
            }
        }

        .order-cancel-form {
            .cancel-reason-select {
                padding: 10px;
                background-position: right 10px center;
                width: 100%;
            }

            .cancellation-reason {
                text-align: right;

                textarea#reasontext {
                    width: 100%;
                    margin-top: 10px;
                    padding: 5px;
                    height: 75px;
                }
            }

            .reason-error-message {
                .error-message {
                    width: 100%;
                    display: inline-block;
                }
            }
        }

        .cancellation-feedback-section {
            label {
                padding-top: 10px;
            }
        }

        .modal-header {
            padding: 0;
            border-bottom: 0;
            border-radius: 0;
            position: relative;

            .icon-logo {
                fill: $red;
                width: 34px;
                height: 34px;
                margin: 0 auto;
                display: block;
            }

            .close {
                position: absolute;
                right: 10px;
                top: 0;
                line-height: 48px;
                padding: 0;
                margin: 0;

                .icon-close {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .modal-title {
            @include font(400, 26px, $black, 27px, normal);

            margin: 8px auto 12px;
            text-align: center;
        }

        .modal-body {
            @include font(400, 16px, $black, 22px, normal);

            margin: 0 auto 32px;
            padding: 0 5px;
            text-align: center;
        }

        .modal-buttons {
            .btn {
                @include font(null, 14px, null, null, null);

                margin-bottom: 16px;
                width: 100%;
                text-transform: uppercase;
            }

            .confirm-cancel {
                background-color: $red;
                color: $white;
                border-color: $red;
            }
        }

        @include media-breakpoint-up('lg') {
            padding-bottom: 0;
            border: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

            .modal-header {
                .close {
                    top: 0;
                }
            }

            .modal-title {
                @include font(400, 36px, $black, 27px, 0.2px);

                margin: 20px auto 16px;
            }

            .modal-body {
                @include font(400, 20px, $black, 22px, normal);

                padding: 0 64px;
                margin-bottom: 36px;
            }

            .modal-buttons {
                .btn {
                    @include font(600, 16px, null, null, null);

                    width: 100%;
                }
            }
        }
    }
}
