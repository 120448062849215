@mixin underlineOnClass($color: $black, $underlineStroke: 3px, $lineHeight: 1, $flag: null, $spacing: 4px) {
    padding: 0 0 $spacing;
    display: inline;
    text-decoration: none;
    background-image: linear-gradient($color, $color);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    line-height: $lineHeight;
    @if $flag == 'active' {
        background-size: 100% $underlineStroke;
    }
}

@mixin underlineOnHover($color: $black, $underlineStroke: 3px, $lineHeight: 1) {
    @include underlineOnClass($color, $underlineStroke, $lineHeight);

    background-size: 0% $underlineStroke;

    @include media-breakpoint-up(lg) {
        &:focus,
        &:hover {
            background-size: 100% $underlineStroke;
            outline: none;
        }
    }
}

@mixin boldOnActive() {
    &:active {
        font-weight: bold;
    }
}

@mixin boldOnFocus() {
    &:focus {
        font-weight: bold;
    }
}

@mixin semiboldOnActive() {
    &:active {
        font-weight: 600;
    }
}

@mixin semiboldOnFocus() {
    &:focus {
        font-weight: 600;
    }
}
