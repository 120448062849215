@import "~base/components/productTiles";
@import "./quickView";

.product-tile {
    margin-bottom: 48px;

    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }

    .wishlistTile {
        .wishlistpanel {
            height: 2rem;
            width: 2rem;
            background: #fff;
            border-radius: 50%;
            display: block;
            margin-top: 0.1rem;
            opacity: 0.7;

            @include media-breakpoint-down(sm) {
                height: 1.75rem;
                width: 1.75rem;
            }
        }

        .icon-wishlist {
            position: absolute;
            top: 10px;
            right: 8px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            @include media-breakpoint-down(sm) {
                top: 8px;
                right: 6px;
            }

            &-o {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12,22.75 C11.8498702,22.7515167 11.7030217,22.7060636 11.58,22.62 C11.26,22.41 3.78999996,17.35 1.33999995,12.86 C-0.120000051,10.18 -0.450000053,6.39999998 2.08999996,3.99999997 C4.57644544,1.69049757 8.42355452,1.69049757 10.91,3.99999997 L12,4.99999997 L13.08,3.99999997 C15.5664455,1.69049757 19.4135546,1.69049757 21.9,3.99999997 C24.4400001,6.40999998 24.11,10.19 22.65,12.87 C20.2,17.36 12.75,22.42 12.43,22.63 C12.3030009,22.7145484 12.1524272,22.756569 12,22.75 Z M6.49999998,3.74991869 C5.24918643,3.7427924 4.04316499,4.21518062 3.12999996,5.06999997 C1.12999995,6.93999998 1.46999996,9.96999999 2.65999996,12.14 C4.65999997,15.73 10.39,19.96 12,21.14 C13.6,20 19.36,15.78 21.32,12.19 C22.6,9.84999999 22.86,6.50999998 20.32,4.69999997 C18.3515073,3.3719103 15.7249427,3.58802004 14,5.21999997 L13.12,6.05999998 L14.57,7.45999998 L14.22,7.81999998 C13.8299626,8.20772358 13.2000375,8.20772358 12.81,7.81999998 L9.86999999,5.06999997 C8.95683497,4.21518062 7.75081353,3.7427924 6.49999998,3.74991869 Z'%3E%3C/path%3E%3C/svg%3E");

                @include media-breakpoint-up(lg) {
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23D31334' d='M12,22.75 C11.8498702,22.7515167 11.7030217,22.7060636 11.58,22.62 C11.26,22.41 3.78999996,17.35 1.33999995,12.86 C-0.120000051,10.18 -0.450000053,6.39999998 2.08999996,3.99999997 C4.57644544,1.69049757 8.42355452,1.69049757 10.91,3.99999997 L12,4.99999997 L13.08,3.99999997 C15.5664455,1.69049757 19.4135546,1.69049757 21.9,3.99999997 C24.4400001,6.40999998 24.11,10.19 22.65,12.87 C20.2,17.36 12.75,22.42 12.43,22.63 C12.3030009,22.7145484 12.1524272,22.756569 12,22.75 Z M6.49999998,3.74991869 C5.24918643,3.7427924 4.04316499,4.21518062 3.12999996,5.06999997 C1.12999995,6.93999998 1.46999996,9.96999999 2.65999996,12.14 C4.65999997,15.73 10.39,19.96 12,21.14 C13.6,20 19.36,15.78 21.32,12.19 C22.6,9.84999999 22.86,6.50999998 20.32,4.69999997 C18.3515073,3.3719103 15.7249427,3.58802004 14,5.21999997 L13.12,6.05999998 L14.57,7.45999998 L14.22,7.81999998 C13.8299626,8.20772358 13.2000375,8.20772358 12.81,7.81999998 L9.86999999,5.06999997 C8.95683497,4.21518062 7.75081353,3.7427924 6.49999998,3.74991869 Z'%3E%3C/path%3E%3C/svg%3E");
                    }
                }
            }

            &-s {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23D31334' d='M12,22.75 C11.8498702,22.7515167 11.7030217,22.7060636 11.58,22.62 C11.26,22.41 3.78999996,17.35 1.33999995,12.86 C-0.120000051,10.18 -0.450000053,6.39999998 2.08999996,3.99999997 C4.57644544,1.69049757 8.42355452,1.69049757 10.91,3.99999997 L12,4.99999997 L13.08,3.99999997 C15.5664455,1.69049757 19.4135546,1.69049757 21.9,3.99999997 C24.4400001,6.40999998 24.11,10.19 22.65,12.87 C20.2,17.36 12.75,22.42 12.43,22.63 C12.3030009,22.7145484 12.1524272,22.756569 12,22.75 Z'%3E%3C/path%3E%3C/svg%3E");
            }
        }

        &:focus {
            .icon-wishlist {
                outline: 2px solid -webkit-focus-ring-color !important;
                outline-offset: 7px;

                &-o {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12,22.75 C11.8498702,22.7515167 11.7030217,22.7060636 11.58,22.62 C11.26,22.41 3.78999996,17.35 1.33999995,12.86 C-0.120000051,10.18 -0.450000053,6.39999998 2.08999996,3.99999997 C4.57644544,1.69049757 8.42355452,1.69049757 10.91,3.99999997 L12,4.99999997 L13.08,3.99999997 C15.5664455,1.69049757 19.4135546,1.69049757 21.9,3.99999997 C24.4400001,6.40999998 24.11,10.19 22.65,12.87 C20.2,17.36 12.75,22.42 12.43,22.63 C12.3030009,22.7145484 12.1524272,22.756569 12,22.75 Z M6.49999998,3.74991869 C5.24918643,3.7427924 4.04316499,4.21518062 3.12999996,5.06999997 C1.12999995,6.93999998 1.46999996,9.96999999 2.65999996,12.14 C4.65999997,15.73 10.39,19.96 12,21.14 C13.6,20 19.36,15.78 21.32,12.19 C22.6,9.84999999 22.86,6.50999998 20.32,4.69999997 C18.3515073,3.3719103 15.7249427,3.58802004 14,5.21999997 L13.12,6.05999998 L14.57,7.45999998 L14.22,7.81999998 C13.8299626,8.20772358 13.2000375,8.20772358 12.81,7.81999998 L9.86999999,5.06999997 C8.95683497,4.21518062 7.75081353,3.7427924 6.49999998,3.74991869 Z'%3E%3C/path%3E%3C/svg%3E");
                }
            }
        }
    }

    .wishlistTile.using-mouse:focus {
        .icon-wishlist {
            outline: none;
        }
    }

    .fademe {
        opacity: 0;
    }

    .image-container {
        display: block;
        height: 0;
        overflow: hidden;
        padding-bottom: 119.8%;
        position: relative;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-transition: background 0.3s ease;
        transition: background 0.3s ease;

        > a:focus {
            outline: auto 6px;

            img {
                width: calc(100% - 2px);
                margin: 1px;
                outline: 1px solid -webkit-focus-ring-color !important;
            }
        }

        .hover-image {
            position: absolute;

            &.hovered {
                top: 0;
                left: 0;
            }

            &[src|="null"] {
                display: none;
            }
        }

        .promoCallout {
            background: white;
            bottom: 12px;
            position: absolute;
            box-sizing: border-box;
            height: 24px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            color: #323232;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            margin-left: 12px;
            padding: 2px 8px 0;
            pointer-events: none;
            @include fontSize($font-body3);

            @include media-breakpoint-down(md) {
                margin-left: 8px;
                bottom: 8px;
            }
        }

        .lis-badge {
            background: #fff7da;
            bottom: 12px;
            position: absolute;
            box-sizing: border-box;
            height: 24px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            color: #8a6c00;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            margin-left: 12px;
            padding: 2px 8px 0;
            pointer-events: none;
            @include fontSize($font-body3);

            @include media-breakpoint-down(md) {
                margin-left: 8px;
                bottom: 8px;
            }
        }
    }

    .tile-body {
        line-height: 1.13;
        color: $black;
        padding-bottom: 0;
        padding-top: 12px;
        font-size: 16px;
        @include media-breakpoint-down(md) {
            padding-top: 4px;
        }

        .pdp-link {
            margin-top: 12px;
            @include media-breakpoint-down(md) {
                margin-top: 8px;
            }

            .link {
                @include fontSize($font-body);

                line-height: 22px;
                word-break: break-word;
                font-weight: 500;
                letter-spacing: 0;
                color: #222;

                @include media-breakpoint-down(md) {
                    line-height: 22px;
                    @include fontSize($font-body1);
                }
            }

            .attr {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                color: #57585b;
                margin-top: 6px;
                @include fontSize($font-body1);

                @include media-breakpoint-down(md) {
                    margin-top: 4px;
                    font-size: 16px;
                }
            }
        }

        .price-link {
            &:hover {
                text-decoration: none;
            }
        }

        .flag {
            color: #57585b;
            margin-bottom: 8px;
            margin-top: 6px;
            @include fontSize($font-body1);

            @include media-breakpoint-down(md) {
                margin-top: 4px;
                line-height: 19px;
                font-size: 16px;
            }
        }

        .price {
            .sales {
                font-weight: normal;
                display: inline-block;
                color: #2c282e;
                line-height: 23px;
                margin-top: 12px;
                @include fontSize($font-body1);

                @include media-breakpoint-down(md) {
                    margin-top: 16px;
                }
            }

            .strike-through {
                line-height: 20px;
                color: $bright-gray2;
                @include fontSize($font-body2);
            }

            .markdown-prices {
                color: #222 !important;
            }
        }

        .sales {
            margin-top: 12px;
            font-weight: 400;
            display: inline-block;
            color: #2c282e;
            line-height: 23px;
            @include fontSize($font-body1);

            @include media-breakpoint-down(md) {
                margin-top: 16px;
            }
        }

        .tile-btn {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .color-swatches {
            min-height: 2rem;

            .swatches {
                max-height: 30px;
            }

            .swatch-circle {
                // $white will be replaced by color image background via Javascript
                @include swatch(1.5em, $white);

                padding-right: 0;
                box-shadow: 0 0 0 0.09375rem #8d9196;
                outline-width: 1.5px !important;
                outline-offset: 2px;
            }

            a {
                position: relative;
                cursor: pointer;

                &:focus {
                    outline: 1px auto -webkit-focus-ring-color !important;
                    border-radius: 50%;
                }

                &.unselectable {
                    &::after {
                        content: none;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        width: 31px;
                        border-radius: 0;
                        border: none;
                        background: none;
                        left: 0;
                        top: 50%;
                        right: 0;
                        border-top: 0.09375em solid $black;
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                        z-index: 1;

                        @include media-breakpoint-down(md) {
                            top: 48%;
                            width: 23px;
                        }
                    }
                }
            }
        }

        .quickview-btn-container {
            margin-top: 8px;

            .quickview {
                margin-left: 7px;
                text-decoration: none;
                padding-bottom: 4px;
                border-bottom: 1px solid $gainsboro-gray;
                position: absolute;
                margin-top: 1px;
                @include fontSize($font-body2);

                &:hover {
                    border-bottom: 1px solid $crimson;
                }

                @include media-breakpoint-down('xs') {
                    font-size: 16px;
                    margin-top: 0;
                }
            }
        }

        .dynamicPricing {
            .price-vary-msg,
            .strikedOutPrice {
                @include fontSize($font-body2);

                line-height: 20px;
                color: $bright-gray;
            }

            .price-vary-msg {
                font-weight: $font-weight-medium;
                display: inline-block;
                margin-top: 4px;

                &:lang(ko),
                &:lang(ja),
                &:lang(zh) {
                    font-weight: $font-weight-bold;
                }
            }

            .strikedOutPrice {
                text-decoration: line-through;
                display: block;
                margin-top: 4px;
            }
        }
    }

    .cdpPlus {
        width: 16px;
        height: 16px;
    }

    .cdp-ratings {
        .icon {
            width: 20px;
            height: 20px;
        }
    }
}

.add-to-wishlist-messages {
    z-index: $zindex-highest + 2;
}

.color-swatches {
    .swatches {
        &.slick-slider {
            &:first-child {
                margin-left: 4px;
            }

            margin: 0 8px;
        }

        .slick-list {
            .slick-track {
                margin: 0;
                display: flex;
                justify-content: flex-start;

                &::before,
                &::after {
                    content: none;
                }
            }

            .slick-slide {
                a {
                    width: auto !important;
                }
            }
        }

        a {
            border: 1px solid $transparentSafari;

            &.selected {
                border-color: $black;
                border-radius: 50%;

                &:hover {
                    border: 1px solid #d5d5d5;
                    border-radius: 50%;

                    @include media-breakpoint-down(md) {
                        border: 1px solid $black;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                &.plusSymbol {
                    box-shadow: 0 0 0 0.09375rem $gainsboro-gray;
                    border-radius: 50%;
                    transform: translateY(2px);
                    height: 16px;
                    width: 16px;

                    span {
                        height: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .cdpPlus {
                            height: 10px;
                            width: 10px;
                            transform: translateY(-1px);
                        }
                    }
                }
            }
        }
    }

    .slick-arrow {
        background-repeat: no-repeat;
        background-position: center;

        &::before {
            content: '';
        }
    }

    .slick-prev {
        left: -15px;
    }

    .slick-next {
        right: -16px;
    }

    .slick-next,
    .slick-prev {
        outline: none !important;
        background-color: unset !important;

        &:hover,
        &:focus {
            &::before {
                outline: 1px solid $black;
                background-color: $white;

                @include media-breakpoint-down (xs) {
                    outline-offset: 2px;
                }
            }
        }
    }

    @include media-breakpoint-down (xs) {
        .slick-prev {
            left: -2px;

            ~ .slick-list {
                width: 90%;
                left: 6%;
            }
        }

        .slick-prev,
        .slick-next {
            top: 40%;

            &::before {
                height: 14px;
                width: 8px;
            }
        }
    }
}

.add-remove-wishlist-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: transform 0.4s ease-in-out;

    .link,
    .close-wishlist-toast {
        cursor: pointer;
    }

    #dialogWishlist {
        flex-basis: 90%;
    }

    @include media-breakpoint-down(md) {
        align-items: flex-start;

        #dialogWishlist {
            text-align: left;
        }
    }

    &::before {
        display: none;
    }
}

.add-to-wishlist-wrapper {
    top: unset !important;
    position: fixed;
    bottom: -100px;
    z-index: 999;
    background-color: $white;
    color: $black;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 48.5%;
    margin: 0 auto;
    transition: bottom 1s ease-in-out;
    transform: translate(-50%, 0%) !important;

    @include media-breakpoint-down(md) {
        width: 100%;
        padding-bottom: 2.625rem;
        min-height: 78px;
    }

    #closeButton {
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: 5px;
        position: relative;
        align-self: flex-start;

        @include media-breakpoint-down(md) {
            left: 8px;
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &.show-toast {
        bottom: 4vh !important;

        @include media-breakpoint-down(md) {
            bottom: 0 !important;
        }
    }
}
