h1,
.h1 {
    @include font($weight: 400, $size: 26px, $color: $black, $lh: 22px, $ls: 0);
}

h2,
.h2 {
    @include font($weight: 400, $size: 40px, $color: $black, $lh: 44px, $ls: 0);
}

h3,
.h3 {
    @include font($weight: 400, $size: 20px, $color: $black, $lh: 22px, $ls: 0);
}

@include media-breakpoint-up(lg) {
    h1,
    .h1 {
        @include font($size: 48px, $lh: 50px);
    }

    h3,
    .h3 {
        @include font($size: 36px, $lh: 40px);
    }
}

@mixin large {
    @include font($weight: 400, $size: 20px, $color: $black, $lh: 24px, $ls: 0);
}

.large {
    @include large;
}

@mixin body {
    @include font($weight: 400, $size: 16px, $color: $black, $lh: 20px, $ls: 0);
}

.body {
    @include body;
}

@mixin bold {
    @include font($weight: 700, $size: 16px, $color: $black, $lh: 20px, $ls: 0);
}

@mixin semibold {
    @include font($weight: 600, $size: 16px, $color: $black, $lh: 20px, $ls: 0);
}

.bold {
    @include bold;
}

.semibold {
    @include semibold;
}

@mixin light {
    @include font($weight: 300, $size: 16px, $color: $black, $lh: 20px, $ls: 0);
}

.light {
    @include light;
}

@mixin caption {
    @include font($weight: 400, $size: 14px, $color: $black, $lh: 20px, $ls: 0);
}

.caption {
    @include caption;
}

button,
.btn {
    @include font(
        $weight: 600,
        $size: 14px,
        $color: $black,
        $lh: 16px,
        $ls: 1.4px
    );

    &:lang(ko),
    &:lang(ja),
    &:lang(zh) {
        font-size: 16px;
    }
}

.tag {
    @include font(
        $weight: 600,
        $size: 12px,
        $color: $red,
        $lh: 16px,
        $ls: 0.5px
    );
}

.link {
    @include link();
}

.link-major {
    @include link($black, $red, $black, $red);
}

.link-with-icon {
    @include link($black, $transparent, $black, $transparent);

    &::before {
        content: '';
        background: url('https://image.flaticon.com/icons/svg/126/126495.svg') no-repeat center;
        width: 20px;
        height: 20px;
        display: inline-block;
    }
}

html {
    &:lang(ja) {
        * {
            font-family: 'Noto Sans JP', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
        }
    }
}

html {
    &:lang(ko) {
        * {
            font-family: 'Noto Sans KR', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
        }
    }
}

html {
    &:lang(zh) {
        * {
            font-family: 'Noto Sans HK', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
        }
    }
}

.h1 {
    display: block;
}

.text-warning-custom {
    background-color: rgba(239, 193, 0, 0.2);
    padding: 12px 16px 16px;
    margin-bottom: 24px;
    margin-top: 24px;
    display: flex;

    @include font($weight: 400, $size: 14px, $color: $black, $lh: 18px, $ls: 0);

    @include media-breakpoint-up(lg) {
        @include font($size: 16px, $lh: 20px);
    }

    .warning {
        width: 28px;
        margin-right: 8px;
    }

    .content-asset {
        text-align: left;
    }
}

html {
    &:lang(ja),
    &:lang(ko),
    &:lang(zh) {
        p {
            line-height: 1.375rem;
        }
    }
}
