ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        a {
            font-size: inherit;
        }
    }
}

a {
    color: $black;

    &:hover {
        text-decoration: underline;
        color: $black;
    }
}

hr {
    margin: 0;
}

.form-control {
    border: 1px solid $light-gray;
    border-radius: 0;
    color: $black;

    &:focus {
        box-shadow: none;
    }
}

input.form-control {
    &.is-invalid {
        background-image: none;
    }
}

select.custom-select {
    &.is-invalid {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTkuNDEyLjg4Mkw1IDUuMjk0LjU4OC44ODIiLz48L3N2Zz4=);
        background-size: 14px 10px;
    }
}

[class*="collapsible-"],
[class*="collapsible-"].active {
    .card-header,
    .title {
        &::after {
            display: none;
        }
    }
}

header ~ .container {
    a {
        &:not(.btn-primary):not(.btn-outline-primary) {
            color: $black;
        }
    }
}

.close {
    opacity: 1;
}

.card {
    // important to override specific .card:only-child classes in base
    border: 0;
    border-radius: 0;
}

.modal-content {
    border-radius: 0;
    border: 1px solid $light-gray;
    box-shadow: 0 2px 4px 0 rgba(188, 188, 188, 0.5);
}

/* slick slider reset */
.slick-prev,
.slick-next {
    z-index: $zindex-lowest;

    &::before {
        content: '' !important;
        width: 20px;
        height: 20px;
        display: inline-block;
        opacity: 1;
    }
}

.slick-prev {
    &::before {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTAgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9IkZhdC1DaGV2ZXJvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMDAwMDAwLCAtOS4wMDAwMDApIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPGcgaWQ9IkNoZXZlcm9uLXRvLUNsb3NlLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjAwMDAwMCwgMTAuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iQmFjayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy43NTAwMDAsIDcuNTAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0zLjc1MDAwMCwgLTcuNTAwMDAwKSAiIHBvaW50cz0iMCAxNSA3LjUgNy41IDAgMCI+PC9wb2x5bGluZT4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=) no-repeat center;
    }
}

.slick-next {
    &::before {
        background: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE3IiB2aWV3Qm94PSIwIDAgMTAgMTciIHdpZHRoPSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0wIDBoMTB2MTdoLTEweiIgZmlsbC1ydWxlPSJub256ZXJvIiAvPjxwYXRoIGQ9Im0xIDE2IDcuNS03LjUtNy41LTcuNSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgLz48L2c+PC9zdmc+) no-repeat center;
    }
}

.slick-dots {
    display: flex;

    li {
        width: 100%;
        margin: 0;
        height: 4px;
        background: lightgray;

        &.slick-active {
            background: darkgray;
        }
    }
}

// hiding spinner
.veil {
    .underlay,
    .spinner {
        display: none;
        z-index: -1;
    }
}

.braintree-loader {
    display: none !important;
}
