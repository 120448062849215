@import '../global/colors';
@import "../mixins/utils";
@import "../global/fontVariables";
@import "../mixins/fontProp";

.cart.cart-page .product-info .line-item-quantity,
.quantity-form-wishlist,
#editProductModal,
#editWishlistProductModal {
    .bootstrap-select.custom-select {
        background: none;
        width: 92px;
        padding: 0;
        height: 50px;
        border: 0;

        div.dropdown-menu {
            border: 0;
            min-width: 92px !important;
            padding: 0;
            box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
            margin-top: 4px;
            border: 1px solid $black;

            > .inner {
                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $silver;
                    border-radius: 10px;
                }
            }

            ul.dropdown-menu {
                li {
                    transition: background-color 0.3s ease-out;

                    &::before {
                        border-top: 0.0625rem solid $dust-gray;
                        content: "";
                        display: block;
                        left: 1rem;
                        position: absolute;
                        right: 1rem;
                        top: -0.0625rem;
                    }

                    &:hover,
                    &:focus {
                        background-color: $gainsboro;
                    }

                    .dropdown-item {
                        padding: 20px 24px 20px 32px;

                        &.active,
                        &:active,
                        &:hover,
                        &:focus {
                            background-color: unset;
                            outline: none !important;
                        }

                        &:focus {
                            outline: 1px solid -webkit-focus-ring-color !important;
                        }

                        span.text {
                            color: $black;
                            @include fontSize($font-text);
                        }
                    }
                }
            }
        }

        .dropdown-toggle {
            background-color: unset;
            position: initial;
            outline: none !important;
            border: 1px solid $black;
            border-radius: 4px;
            height: 50px;

            &:focus {
                box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.45);
                outline: 1px solid -webkit-focus-ring-color !important;
            }

            &::before {
                background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTkuNDEyLjg4Mkw1IDUuMjk0LjU4OC44ODIiLz48L3N2Zz4=) no-repeat right;
                height: 10px;
                width: 14px;
                vertical-align: middle;
                background-size: 14px 10px;
                transition: all 0.2s ease-out;
            }

            &::after {
                display: none;
            }

            .filter-option-inner-inner {
                @include fontSize($font-text);

                color: $black;
                font-weight: 400;
            }

            &:lang(ja),
            &:lang(ko),
            &:lang(zh) {
                padding: 14px;
            }
        }

        &.show {
            .dropdown-toggle {
                &::before {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.quantity-form-wishlist {
    @include media-breakpoint-down('md') {
        min-height: 50px;
    }

    .bootstrap-select.select-quantity {
        width: 100% !important;

        @include media-breakpoint-down('md') {
            width: 92px !important;
            float: right;
        }

        div.dropdown-menu {
            min-width: 100% !important;
        }
    }
}

#editProductModal,
#editWishlistProductModal {
    .modal-content {
        .modal-body {
            overflow: initial;

            .product-quickview {
                .product-wrapper {
                    .detail-panel {
                        .attribute {
                            overflow: initial;

                            .bootstrap-select.select-picker {
                                border: none;
                                padding: 0;

                                &.update-select-quantity {
                                    float: left;
                                    width: 100%;
                                }

                                &.size-select {
                                    width: 100%;
                                }

                                div.dropdown-menu {
                                    min-width: 100% !important;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
