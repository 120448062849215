@font-face {
    font-family: 'Calibre';
    src: url('../fonts/calibre-web-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('../fonts/calibre-web-regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'Calibre';
    src: url('../fonts/calibre-web-semibold.woff2') format('woff2');
    font-weight: 600;
}

@font-face {
    font-family: 'Calibre';
    src: url('../fonts/calibre-web-medium.woff2') format('woff2');
    font-weight: 500;
}
