.alert-on-checkout {
    .alert-checkout-container {
        justify-content: center;
        margin: 10px 0 8px;

        @include media-breakpoint-up('lg') {
            width: 873px;
            margin: 40px auto 5px;
        }

        span {
            padding: 14px 7px 22px 0;
            text-align: left;

            @include media-breakpoint-up('lg') {
                padding: 14px 19px 11px 0;

                &:lang(ja) {
                    padding: 14px 0 10px;
                }
            }

            a {
                text-decoration: underline;
            }
        }
    }
}

.alert-checkout-container,
.promo-alert-checkout-container,
.gc-alert-checkout-container {
    display: flex;
    width: auto;
    margin: 16px 0;
    background-color: $quartz;
    border: 1px solid $cornflower-blue;

    @include media-breakpoint-up('lg') {
        width: auto;
        margin-bottom: 16px;
    }

    svg {
        width: 24px;
        height: 24px;
        margin: 12px 4px 12px 12px;
        flex-shrink: 0;
    }

    span {
        line-height: 21px;
        font-weight: 400;
        padding: 14px 7px 13px 0;
        @include fontSize($font-body2);

        @include media-breakpoint-up('lg') {
            padding: 14px 14px 13px 0;
        }
    }
}
