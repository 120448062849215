.size-guide-modal {
    .modal-dialog {
        max-width: 874px;
        z-index: 1;
    }

    .modal-content {
        padding-bottom: 40px;
        background-clip: border-box;
        border: 1px solid $light-gray;
        border-radius: 0;
        box-shadow: 0 2px 4px 0 rgba(188, 188, 188, 0.5);

        .modal-header {
            margin-top: 20px;
            padding: 0;
            border-bottom: 0;
            border-radius: 0;
            position: relative;

            .icon-logo {
                fill: $red;
                width: 34px;
                height: 34px;
                margin: 0 auto;
                display: block;
            }

            .close {
                position: absolute;
                right: 16px;
                top: 0;
                padding: 8px 16px 0 0;

                .icon-close {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .modal-body {
            @include font(400, 16px, $black, 22px, normal);

            margin: 0;
            padding: 0 5px;
            text-align: center;
            z-index: 999999;
        }

        @include media-breakpoint-up('lg') {
            padding-bottom: 20px;
            border: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

            .modal-body {
                @include font(400, 20px, $black, 22px, normal);

                padding: 0 64px;
            }
        }
    }

    .nav-tabs {
        border-bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 60px;
        margin: 0 auto;

        .nav-item {
            .nav-link {
                color: $black;
                padding: 0;
                margin: 0 12px 0 0;
                display: inline;

                &.active {
                    line-height: 17px !important;
                    font-weight: 600 !important;
                    display: inline-block !important;
                    background-size: 100% 4px !important;
                    height: 30px;
                    background-color: $white;
                    border-bottom: 0 !important;
                    @include fontSize($font-body2);

                    @include underlineOnClass($red, $flag: 'active');
                }
            }
        }
    }

    .close:active {
        outline: none !important;
    }
}

.size-chart a.nav-link {
    border-bottom: 0 !important;
}

.sizinginformation {
    margin-top: 32px;
}

.sizechart {
    .title {
        margin: 32px 0;

        @include font(400, 36px, $black, 27px, normal);
    }

    .subtitle {
        @include font(600, 20px, $black, 22px, normal);
    }

    .caption,
    .desc {
        @include font(400, 16px, $black, 22px, normal);
    }

    .desc-title {
        @include font(600, 16px, $black, 22px, normal);
    }

    .desc,
    .desc-title {
        text-align: left;
        margin-bottom: 16px;
    }

    .table {
        td,
        th {
            border: 0;
        }

        th {
            @include font(600, 16px, $black, normal, normal);
        }

        th.rotate {
            white-space: nowrap;
            transform: rotate(-90deg);
            vertical-align: middle;
            min-width: 88px;
            padding: 0;
        }
    }

    .table-striped {
        tbody {
            width: 100%;
            display: table;

            tr:nth-of-type(odd) {
                background-color: $white;
            }

            tr:nth-of-type(even) {
                background-color: $gray;
            }
        }
    }

    .support-size-table {
        padding-bottom: 16px;
        margin-bottom: 24px;

        td {
            min-width: 88px;

            @include font(400, 16px, $black, normal, normal);

            span {
                display: block;
            }
        }

        tr:nth-of-type(odd) {
            background-color: $light-gray;
        }

        tr:nth-child(n+3) {
            td:first-child {
                background-color: $light-gray;
                opacity: 0.5;
            }
        }

        tr:first-child {
            background-color: $white;

            th:nth-child(2) {
                text-align: left;
                padding-left: 88px;
            }
        }

        &::-webkit-scrollbar {
            height: 6px;
            border-radius: 0;
        }

        &::-webkit-scrollbar-track {
            background: $light-gray;
        }

        &::-webkit-scrollbar-thumb {
            background: $medium-gray;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $dark-gray;
        }
    }
}

.sizechart {
    .size-h1 {
        line-height: 48px;
        margin-top: 20px;
        display: block;
        font-weight: 600;
        @include fontSize($font-medium-desk);
    }

    .size-h2 {
        line-height: 48px;
        margin-top: 7px;
        display: block;
        font-weight: 600;
        margin-bottom: -9px;
        @include fontSize($font-medium);
    }

    .size-h3 {
        line-height: 20px;
        margin-top: 0;
        display: block;
        font-weight: 400;
        @include fontSize($font-body3);
    }

    .size-table td {
        @include fontSize($font-body3);

        height: 39px;
        text-align: center;
        width: 194px;
    }

    .size-table th {
        width: 194px;
        height: 39px;
        text-align: center;
        font-weight: 400;
        background: #fff;
        line-height: 12px;
        border: 1px solid #fff;
        @include fontSize($font-body2);
    }

    .size-table td {
        @include fontSize($font-body3);

        height: 55px;
        text-align: center;
        width: 194px;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
    }

    .size-guide-modal .nav-tabs .nav-item .nav-link {
        line-height: 17px;
        font-weight: 400;
        margin: 0 20px 0 0;
        @include fontSize($font-body2);
    }

    .nav-link.active {
        line-height: 17px;
        font-weight: 600;
        display: inline-block;
        background-size: 100% 4px;
        height: 30px;
        @include fontSize($font-body2);
    }

    .size-table tr:nth-child(even) {
        background: #f2f2f2;
    }

    .size-table tr:nth-child(odd) {
        background: #fafafa;
    }

    .fade:not(.show) {
        opacity: 1;
    }

    .fade {
        transition: unset;
    }

    .measure {
        line-height: 14px;
        margin: 27px 0 20px;
        display: block;
        text-align: left;
        font-weight: 600;
        @include fontSize($font-medium);

        @include media-breakpoint-up('sm') {
            margin: 27px 0 20px;
            line-height: 1.1;
        }
    }

    @include media-breakpoint-up('sm') {
        .other-information {
            span.measure-subhead {
                margin-bottom: 39px;
            }
        }
    }

    @include media-breakpoint-down('xs') {
        .other-information {
            span.measure-subhead {
                margin-bottom: 12px !important;
            }
        }
    }

    .measure-subhead {
        line-height: 24px;
        margin-bottom: 20px;
        display: block;
        text-align: left;
        font-weight: 600;
        @include fontSize($font-body1);
    }

    .measure-desc {
        line-height: 24px;
        display: block;
        text-align: left;
        @include fontSize($font-body1);
    }

    .size-guide-modal .modal-content .modal-body {
        padding: 0 32px;
    }

    .size-table tr .headcol.odd {
        background: #f2f2f2;
    }

    .size-table tr .headcol.even {
        background: #e6e6e6;
    }

    @include media-breakpoint-up(xs) {
        .mobile-blank {
            display: none;
        }

        .size-guide-modal .modal-dialog {
            max-width: 874px;
        }

        .measure-desc {
            margin-bottom: 35px;
        }
    }

    .nav-tabs-div hr {
        height: 4px;
        background-color: #e8e7e4;
        margin-top: -4px;
        margin-bottom: 15px;
        border-top: 0;
    }

    .container.header {
        padding: 0;
    }

    .nav-tabs {
        justify-content: center !important;
    }

    .nav-tabs-div {
        position: relative;
        margin-top: 20px;
    }

    .nav-tabs .nav-item .nav-link {
        margin: 0 25px 0 0;
    }

    .size-guide-modal .nav-tabs .nav-item .nav-link.active {
        line-height: 17px;
        font-weight: 600;
        display: inline-block;
        background-size: 100% 4px;
        height: 30px;
        @include fontSize($font-body2);
    }

    $medium-mobile-min-width : 400px;
    $medium-mobile-max-width : 530px;

    @media screen and (min-width: $medium-mobile-min-width) and (max-width: $medium-mobile-max-width) {
        .size-table th {
            width: 120px !important;
        }
    }

    .myMeasureTab {
        .other-information {
            .measure {
                margin-bottom: 17px;
            }

            .measure-desc {
                margin-bottom: 17px;
            }
        }

        .measure-subhead {
            margin-bottom: 13px;
        }

        .hemming {
            border-bottom: 1px solid #d8d8d8;
            cursor: pointer;
            @include fontSize($font-body1, !important);
        }
    }

    @include media-breakpoint-down('xs') {
        .size-table td {
            @include fontSize($font-body3);

            height: 39px;
            text-align: center;
            width: 110px;
        }

        .nav-tabs-div {
            position: relative;
            margin-top: 20px;
            margin-bottom: -15px;
        }

        .size-table th.mobile-blank,
        .size-table td.mobile-blank {
            width: 130px;
            border-right: 0;
            display: none;
        }

        .size-table th {
            @include fontSize($font-body2);

            width: 105px;
            height: 39px;
            text-align: center;
            font-weight: 400;
            background: #fff;
        }

        .size-guide-modal .modal-content .modal-body {
            padding: 0 5px;
        }

        .search-table-outter {
            width: auto;
            max-width: 100%;
            margin-left: 0;
            margin-right: auto;
            padding-bottom: 7px;
        }

        .search-table {
            table-layout: fixed;
            margin: 0 auto;
            background-color: lightblue;
        }

        .search-table,
        .size-table td,
        .size-table th {
            border-collapse: collapse;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            line-height: 10px;
        }

        .size-table th {
            padding: 5px 7px;
            font-size: 15px;
        }

        .size-table td {
            padding: 5px 10px;
            height: 35px;
        }

        .search-table-outter {
            overflow-x: scroll;
        }

        .size-table th,
        .size-table td {
            min-width: 105px;
            text-align: center;
            height: 55px;
            line-height: 12px;
        }

        .headcol {
            position: sticky;
            color: black;
            left: 0;
            width: 7em;
            top: auto;
            padding-top: 5px !important;
        }

        .measure {
            line-height: 14px;
            margin: 20px 0;
            display: block;
            text-align: left;
            font-weight: 600;
            @include fontSize($font-small);
        }

        .measure-subhead {
            line-height: 20px;
            margin-bottom: 30px;
            display: block;
            text-align: left;
            font-weight: 600;
            @include fontSize($font-body2);
        }

        .measure-desc {
            line-height: 20px;
            display: block;
            text-align: left;
            @include fontSize($font-body2);
        }

        .other-information {
            padding: 10px;
        }

        .myMeasureTab {
            .other-information {
                .nav-tabs-div {
                    hr {
                        margin-bottom: 33px;
                    }
                }

                .nav-tabs {
                    padding: 0;
                }

                .measure {
                    padding-top: 13px;
                    margin-bottom: 22px;
                }

                .measure-subhead {
                    margin-bottom: 16px;
                }

                .measure-desc,
                .measure-subhead {
                    margin-bottom: 20px;
                }
            }

            .men-bottom th {
                line-height: 15px !important;
                padding: 0 9px !important;
            }
        }
    }
}

.sizechart .other-information span.measure-subhead:empty {
    margin-bottom: 0;
}

html:lang(ja) * {
    @include media-breakpoint-up('sm') {
        .sizechart {
            .measure {
                margin: 27px 0 31px;
            }
        }
    }
}

#exTab1 .nav-pills > li > a {
    font-weight: 400 !important;
    padding-bottom: 11px !important;
    padding-top: 11px !important;
}
