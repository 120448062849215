.recommendations,
.ai-recommendations {
    margin-bottom: 40px;

    .carousel-heading {
        h2 {
            @include font(400, 20px, $black, 29px, normal);

            text-align: center;
        }
    }

    .product-carousel {
        display: none;
        min-height: 450px;

        @include media-breakpoint-down(xs) {
            min-height: 300px;

            a.product-carousel-dots {
                display: inline-block;
            }
        }

        &.slick-initialized {
            display: block;
        }

        .product-carousel-slide {
            padding: 10px;

            @include media-breakpoint-down(xs) {
                padding: 10px 5px;
            }
        }

        > .slick-prev,
        > .slick-next {
            background: $white;
            opacity: 0.5;
            height: 36px;
            top: 35%;

            &.slick-arrow {
                padding: 0 24px 0 10px;
            }
        }

        > .slick-next {
            right: 8px;
        }

        > .slick-prev {
            left: 10px;
        }

        > .slick-dots {
            bottom: 20px;
            left: 0;
        }

        &:hover {
            > .slick-next,
            > .slick-prev {
                opacity: 1;
            }
        }
    }

    .product-carousel,
    .product-enhanced-grid-carousel {
        .color-swatches {
            .swatches {
                > a {
                    margin-left: 8px;
                }

                > a:first-child {
                    margin-left: 0;
                }

                > a:focus {
                    outline: 1px auto -webkit-focus-ring-color !important;
                    border-radius: 50%;
                }

                > a.plusSymbol {
                    margin-left: 10px;
                }
            }
        }
    }

    div.recommendations,
    .ai-recommendations {
        &.container {
            margin: 0;
            padding: 0;

            .product-carousel > .slick-dots {
                padding: 0 10px;
            }

            .color-swatches {
                display: none;
            }
        }
    }

    @media screen and (min-width: $medium-device-max-width) and (max-width: $large-device-max-width) {
        .product-carousel {
            > .slick-next {
                right: 10px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;

        .carousel-heading {
            h2 {
                @include font(400, 40px, $black, 50px, normal);
            }
        }

        .product-carousel {
            > .slick-prev,
            > .slick-next {
                height: 54px;

                &.slick-arrow {
                    padding: 0 30px 0 12px;
                }
            }

            > .slick-dots {
                left: 0;
            }
        }
    }

    .product-tile {
        .strike-through {
            margin-right: 0;
            display: inline;
            color: $bright-gray2;

            span[content] {
                margin-right: 4px;
            }

            span[content="null"] {
                margin-right: 0;
            }
        }
    }
}

div[data-action=Search-Show],
div[data-action=Home-Show],
div[data-action=Default-Start] {
    .recommendations,
    .ai-recommendations {
        margin-bottom: 16px;
        @include media-breakpoint-up('lg') {
            padding-bottom: 24px;
        }

        .carousel-heading {
            h2 {
                font-weight: 600;
                line-height: 44px;
                margin-top: 0;
                margin-bottom: 16px;
                text-align: center;
                @include fontSize($font-medium);

                @include media-breakpoint-up('lg') {
                    line-height: 56px;
                    margin-bottom: 32px;
                    @include fontSize($font-medium-desk);
                }
            }

            h2::first-letter {
                text-transform: capitalize;
            }
        }

        .product-carousel-slide {
            .tile-body {
                height: auto !important;
            }

            .product-tile {
                margin-bottom: 16px;
            }
        }

        .product-carousel {
            @include media-breakpoint-up('lg') {
                > .slick-prev,
                > .slick-next {
                    height: 48px;
                    border-radius: 4px;

                    &.slick-arrow {
                        padding: 0 34px 0 14px;
                    }
                }

                > .slick-prev {
                    left: 26px;
                }

                > .slick-next {
                    right: 26px;
                }
            }
        }

        .slick-dotted {
            &.slick-dotted {
                margin-bottom: 24px;
            }
        }

        .slick-dots {
            display: flex;
            justify-content: center;
            position: static;
            margin-top: 6px;

            li {
                width: 8px;
                height: 8px;
                margin-right: 8px;
                border-radius: 50%;
                background-color: #8d9196;

                &.slick-active {
                    background-color: #000;
                }

                &:focus,
                &:hover {
                    outline: 1px solid -webkit-focus-ring-color !important;
                }
            }
        }
    }
}

div[data-action=Search-Show],
div[data-action=Home-Show],
div[data-action=Default-Start] {
    .recommendations.product-enhanced-grid {
        .carousel-heading {
            margin-bottom: 24px;

            @include media-breakpoint-up('lg') {
                margin-bottom: 32px;
            }

            @include media-breakpoint-down('sm') {
                margin-bottom: 9px;
            }

            h2 {
                margin-bottom: 8px;
                font-weight: 600;
            }

            p {
                margin: 0 auto;
                font-weight: 400;
                line-height: 1.26316;
                text-align: center;
                @include fontSize($font-body1);

                @include media-breakpoint-up('md') {
                    max-width: 85%;
                }
                @include media-breakpoint-up('lg') {
                    max-width: 75%;
                }
            }
        }

        @include media-breakpoint-up('md') {
            .row {
                justify-content: center;
            }
        }

        @include media-breakpoint-up('lg') {
            .carousel-heading {
                h2 {
                    line-height: 1.2;
                    @include fontSize($font-medium-desk);
                }
            }
        }

        .product-tile,
        .product-enhanced-promo {
            margin-bottom: 32px;
        }

        @include media-breakpoint-only(md) {
            .product-tile,
            .product-enhanced-promo {
                margin-bottom: 15px;
            }
        }

        .product-grid {
            @include media-breakpoint-down(xs) {
                padding-top: 0;
            }
        }

        @include media-breakpoint-down('lg') {
            .carousel-heading {
                h2 {
                    line-height: 1.06667;
                    @include fontSize($font-medium);
                }
            }
        }

        @include media-breakpoint-down('sm') {
            .btnWrapper {
                .btn {
                    display: inline-block;
                    margin: 8px 0;
                    width: 100%;
                }
            }
        }

        @include media-breakpoint-up('sm') {
            .btnWrapper {
                .btn {
                    margin: 0 6px;
                }
            }
        }

        .image-wrapper {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .image-inner-wrapper {
                height: 54vw;
                max-height: 224px;

                @include media-breakpoint-only('sm') {
                    height: 56.6vw;
                    max-height: 435px;
                }

                @include media-breakpoint-only('md') {
                    height: 27vw;
                    max-height: 245px;
                }

                @include media-breakpoint-up('lg') {
                    height: 27.6vw;
                    max-height: 378px;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: center;
            }
        }
    }
}

#addedToCartModal {
    .recommendations {
        .product-carousel {
            min-height: 400px;
        }
    }
}

.recommendations {
    .product-carousel-slide {
        &:not(.slick-active) {
            .image-container {
                img[data-lazyload='false'] {
                    display: none;
                }
            }
        }

        .compare {
            display: none;
        }
    }

    &.multi-tab,
    &.product-enhanced-grid-multi {
        .multitablinkcontainer {
            position: relative;
        }

        .tab-content > .tab-pane {
            display: block;
            height: 0;
            overflow: hidden;
        }

        .tab-content > .tab-pane.active {
            height: auto;
            max-width: 100%;
            width: 100%;
        }

        hr {
            display: none;
        }

        @include media-breakpoint-down('md') {
            hr {
                height: 2px !important;
                background-color: #e8e7e4;
                margin-top: -2px !important;
                border-top: 0;
                display: block;
            }

            .carousel-heading {
                display: none;
            }

            .multitablinkcontainer {
                overflow-x: scroll;
                overflow-y: hidden;
                scroll-behavior: smooth;
                width: 100%;
                margin-bottom: 22px;
            }

            .multitablink {
                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .scroll-tab {
                flex: 0 0 auto;
                min-width: 100px;
                cursor: pointer;
                margin-right: 5px;
            }

            .arrow {
                cursor: pointer;
                padding: 5px;
                color: #000;
                display: block;
                width: 34px;
            }

            .arrow.disabled {
                background-color: transparent;
                cursor: none;
                display: none;
            }

            .scroll-left-arrow::before,
            .scroll-right-arrow::before {
                content: "" !important;
                width: 16px;
                height: 16px;
                display: inline-block;
                opacity: 1;
            }

            .scroll-left-arrow {
                position: absolute;
                left: 0;
                top: 0;
                text-align: left;
                background: linear-gradient(89deg, #fafafa 74.76%, rgba(255, 255, 255, 0) 98.79%);
            }

            .btnmultitab {
                width: 100%;
            }

            .scroll-right-arrow {
                position: absolute;
                right: 0;
                top: 0;
                text-align: right;
                background: linear-gradient(-89deg, #fafafa 74.76%, rgba(255, 255, 255, 0) 98.79%);
            }

            .scroll-left-arrow::before {
                background: url('../../images/LeftChevron.svg') no-repeat center;
            }

            .scroll-right-arrow::before {
                background: url('../../images/RightChevron.svg') no-repeat center;
            }

            .nav-link {
                padding: 4px 0 0 !important;

                &:hover {
                    isolation: auto;
                }
            }

            .multitablink {
                padding-left: 0;
                justify-content: unset !important;
                white-space: nowrap;
                flex-wrap: inherit;
            }
        }

        .multitablink {
            display: flex;
            justify-content: center;
            margin: 12px 0 22px;
            gap: 10px;

            .nav-item {
                .btn-primary.active {
                    background: $black !important;
                }
            }

            @include media-breakpoint-down('md') {
                overflow-x: auto;
                gap: 0;
                margin: 0;

                .nav-item {
                    background-image: linear-gradient(#e8e7e4, #e8e7e4);
                    background-position: 0 100%;
                    background-repeat: no-repeat;
                    background-size: 100% 2px !important;

                    .btn {
                        margin: 0 25px 0 0;
                        color: $black;
                        background-color: transparent !important;
                        display: inline-table;
                        padding: 0;
                        line-height: 17px;
                        text-transform: capitalize;
                        font-weight: 400;
                        background-color: initial;
                        @include fontSize($font-body2);

                        border: none !important;

                        &.active {
                            font-weight: 600;
                            height: 30px;
                            border-bottom: 0 !important;
                            text-decoration: none;
                            background-image: linear-gradient(#d22030, #d22030) !important;
                            background-position: 0 100% !important;
                            background-repeat: no-repeat !important;
                            background-size: 100% 2px !important;
                            background-color: initial !important;

                            &:focus {
                                box-shadow: none;
                                outline: none !important;
                            }
                        }

                        &:focus {
                            box-shadow: none;
                            outline: none !important;
                        }
                    }
                }
            }
        }

        .carousel-heading {
            @include fontSize($font-body3);

            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
        }

        .ctaButton {
            margin-bottom: 0;
        }
    }

    &.product-enhanced-grid-multi {
        .tab-content > .tab-pane.active {
            justify-content: center;
            display: flex;
        }
    }
}
