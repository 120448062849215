@include media-breakpoint-down(lg) {
    .container {
        width: 100%;
        max-width: none;
    }
}

.modal-backdrop {
    &.show {
        opacity: 0.3 !important;
    }
}

.modal.fade:not(.in) .modal-dialog {
    transform: translate3d(0, 0, 0);
}

.modal-open {
    position: relative;
}

.modal-open.type-ahead-suggestions {
    position: fixed;

    .page {
        pointer-events: none;
    }

    .suggestions {
        pointer-events: auto;
    }
}

.page {
    @include media-breakpoint-down('xs') {
        overflow-x: clip !important;
    }
}

.unscroll {
    @include media-breakpoint-down('xs') {
        overflow-x: unset !important;
    }
}
