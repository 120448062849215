.masonry-component {
    margin-top: 32px;
    margin-bottom: 4px;

    @include media-breakpoint-up('lg') {
        margin-bottom: 12px;
    }

    // ISBS-369 : Adding reserved space for Masonry Elements
    .image-wrapper::before,
    .video-wrapper::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
    }

    .col-lg-12 {
        .image-wrapper::before,
        .video-wrapper::before {
            padding-top: calc(243 / 958 * 100%);
        }
    }

    .col-lg-8 {
        .image-wrapper::before,
        .video-wrapper::before {
            padding-top: calc(484 / 851 * 100%);
        }
    }

    .col-lg-6 {
        .image-wrapper::before,
        .video-wrapper::before {
            padding-top: calc(504 / 593 * 100%);
        }
    }

    .col-lg-4 {
        .image-wrapper::before,
        .video-wrapper::before {
            padding-top: 100%;
        }
    }

    .masonry-element {
        .image-wrapper::after,
        .video-wrapper::after {
            content: "";
            display: table;
            clear: both;
        }
    }

    .masonry-title {
        margin-bottom: 16px;

        @include font(400, 26px, $black, 40px, normal);
    }

    .masonry-text {
        h2 {
            font-weight: 600;
            @include fontSize($font-medium);

            line-height: 44px;
            margin-top: 0;

            @include media-breakpoint-up('lg') {
                @include fontSize($font-medium-desk);

                line-height: 56px;
            }
        }

        p {
            margin-bottom: 0;
        }

        @include font(400, 20px, $black, 22px, normal);

        display: flex;
        flex-direction: column;
        min-height: 100%;
        justify-content: space-between;
        margin-bottom: 16px;
        text-align: left;
    }

    .cta-wrapper {
        text-align: left;
    }

    .btn {
        width: fit-content;
        margin: 8px auto 0;
        letter-spacing: 0.075rem;
        font-size: 16px;
        font-weight: 600;
        margin-right: 8px;

        @include media-breakpoint-down('md') {
            width: 100%;
        }
    }

    .video-wrapper {
        .masonry-video {
            margin-bottom: 16px;
        }
    }

    .image-wrapper,
    .video-wrapper {
        margin-bottom: 16px;
        text-align: center;

        img {
            margin-bottom: 16px;
        }
    }

    .image-wrapper,
    .video-wrapper {
        .cta-wrapper {
            display: flex;
            flex-direction: column;

            .header-text h3 {
                text-align: left;
                font-weight: 600;
                line-height: 40px;
                @include fontSize($font-medium);

                @include media-breakpoint-down('md') {
                    @include fontSize($font-small);

                    font-weight: 600;
                }
            }

            .cta-body {
                text-align: left;
                line-height: 25px;
                @include fontSize($font-body1);

                @include media-breakpoint-down('md') {
                    line-height: 25px;
                    @include fontSize($font-body2);
                }
            }
        }
    }

    + .hero-overlay-component,
    + .hero-no-overlay-component {
        margin-top: -24px;
    }

    @include media-breakpoint-down(lg) {
        .masonry-element {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .masonry-element {
            margin-bottom: 20px;
        }

        .masonry-title {
            margin-bottom: 32px;
        }

        .masonry-text {
            margin-bottom: 12px;
        }

        .image-wrapper,
        .video-wrapper {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 16px;
            position: relative;
        }

        .col-lg-4 {
            .image-wrapper,
            .video-wrapper {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 16px;
            }
        }

        + .hero-overlay-component,
        + .hero-no-overlay-component {
            margin-top: -60px;
        }
    }
}

// UGC AB test
.home-slot8-ABtest {
    display: none;
}

.home-slot9-ABtest {
    display: none;
}
