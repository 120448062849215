.check-gift-card-balance {
    @include fontSize($font-body2);

    letter-spacing: 0;
    line-height: 20px;
    font-weight: normal;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 0.0625rem;
    display: block;
    margin-left: 24px;

    &:hover {
        text-decoration-color: $red;
        -webkit-text-decoration-color: $red;
        text-decoration-thickness: 0.0625rem;
    }
}

.giftcard-container {
    .check-gift-card-balance {
        text-align: left;
    }
}

.gift-card-section {
    .gift-card-modal {
        padding-bottom: 32px;
        z-index: 1111;

        .modal-header {
            border-bottom: none;
            padding: 0;
            text-align: right;

            .modal-close {
                background: none;
                border: none;
                cursor: pointer;
                z-index: $zindex-lowest;
            }
        }

        .modal-dialog {
            box-shadow: 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.2);
            background: $white;
            align-items: flex-start;
            margin-top: 106px;
            min-height: auto;
            max-width: 875px;
            max-height: 448px;
            border-radius: 0.25rem;
            animation: 0.2s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
            animation-name: lll-fadeInUp;

            &.has-balance {
                .gift-card-check-balance {
                    display: none;
                }

                .gift-card-balance-display {
                    display: block;

                    .btn {
                        display: block;
                        background-color: $red;
                        color: $white;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        letter-spacing: 0.075rem;
                        width: 100%;
                        border: 0.0625rem solid;
                        border-radius: 0.25rem;
                        text-decoration: none;
                        height: 52px;
                    }
                }
            }
        }

        .modal-content {
            border: none;
            padding: 24px;
            border-radius: 0.25rem;
            box-shadow: 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.2);

            @include media-breakpoint-up(sm) {
                max-width: 472px;
                width: 472px;
            }
        }

        .gift-card-balance-display {
            display: none;

            .balance {
                font-weight: 400;
                @include fontSize($font-body1);

                line-height: 20px;
                margin: 0;

                .gc-number {
                    float: left;
                    margin: 12px 0;
                }

                .gc-balance {
                    float: right;
                    margin: 12px 0;
                    font-weight: 600;
                }
            }

            .modal-header {
                padding: 0;
            }
        }

        .header-text {
            @include fontSize($font-xsmall);

            font-weight: 600;
            letter-spacing: 0;
            line-height: 28px;
            margin-top: 12px;
            margin-bottom: 0;
        }

        .header-description {
            @include fontSize($font-body1);

            letter-spacing: 0;
            line-height: 24px;
            margin-top: 8px;
            margin-bottom: 16px;
            text-align: left;
        }

        .modal-body {
            .gift-card {
                margin-top: 12px;
                margin-bottom: 0;
            }

            .form-control-label {
                float: left;

                &::before {
                    color: $black;
                }
            }

            .check-giftcert {
                background-color: $red;
                color: $white;
                font-size: 16px;
                font-weight: 600;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                letter-spacing: 0.075rem;
                width: 100%;
                border: 0.0625rem solid;
                border-radius: 0.25rem;
                text-decoration: none;
                height: 52px;
            }

            .card-number-section {
                .form-control-label {
                    @include fontSize($font-text);

                    letter-spacing: 0;
                    line-height: 24px;
                    margin-bottom: 6px;

                    &::before {
                        content: '';
                        margin-right: 0;
                    }
                }

                &.required .form-control-label::after {
                    content: '*';
                    margin-left: 1px;
                    white-space: pre;
                }

                .gift-modal-error {
                    top: 31px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .modal-body {
                text-align: center;

                .card-number-section,
                .check-giftcert,
                .check-btn
                input.giftCard {
                    // width: 315px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .invalid-feedback {
                    margin-top: 4px;
                    text-align: left;

                    @include font(400, 18px, $cardinal-red, 24px, 0);
                }
            }

            .header-text {
                margin-top: 0;
                @include fontSize($font-small);

                font-weight: 600;
                letter-spacing: 0;
                line-height: 32px;
                text-align: left;
            }

            .gift-card-balance-display {
                .modal-header {
                    padding-right: 0;
                }
            }
        }
    }
}
