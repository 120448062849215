@media only screen and (max-width: 1023px) {
    div.page[data-action='Default-Start'] div.banner,
    div.page[data-action='Home-Show'] div.banner,
    div.page[data-action='Search-Show'] div.banner,
    div.page[data-action='Product-Show'] div.banner,
    div.page[data-action='Page-Show'] div.banner,
    div.page[data-action='Cart-Show'] div.banner {
        margin: 8px 0;
        padding: 0 3px;        
    }
}
