footer {
    background-color: $white !important;
    padding-top: 0;
    margin-top: 20px;

    &.no-links {
        .footer-copy {
            .copy-container,
            .email-container {
                a {
                    @include underlineOnHover();
                }
            }
        }
    }

    &.pdp-footer {
        .footer-copy {
            margin-bottom: 120px;
        }
    }

    &.checkout-footer {
        .footer-copy {
            margin-bottom: 100px;
        }
    }

    &.cart-footer {
        .footer-copy {
            margin-bottom: 120px;
        }
    }
}

.footer-links-container {
    .footer-item {
        border: 0;

        .title {
            @include font(600, 18px, $black, normal, 1.5px);

            padding: 16px 0;
            border-top: 1px solid $light-gray;

            .icon {
                float: right;
            }
        }

        &.footer-support {
            .title {
                border-top: 0;
            }

            .footerHelp {
                border-top: 1px solid $light-gray;
            }
        }

        &.footer-about {
            .title {
                border-bottom: 1px solid $light-gray;
            }

            &.active {
                border-bottom: 1px solid $light-gray;

                .title {
                    border-bottom: 0;
                }
            }
        }

        &.footer-social {
            .footer-social-connect-title {
                padding: 0;
                border: 0;

                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
            }

            svg {
                vertical-align: top;
            }

            .footer-social-link-items {
                padding: 0;
            }
        }

        .menu-footer {
            &.content {
                li {
                    @include font(400, 16px, $nightrider-grey, 18px, normal);

                    height: auto;
                    margin: 16px 0;
                }

                @include media-breakpoint-down(sm) {
                    .noMarginTop {
                        margin-top: unset;
                    }
                }
            }
        }

        &.footer-email {
            text-align: center;
            padding: 20px 8px 24px;

            @include media-breakpoint-down(md) {
                &:not(.footer-social-kr) {
                    padding-bottom: 0;
                }

                &.footer-social-kr {
                    padding: 40px 8px;
                }
            }

            .email-title {
                @include font(600, 16px, $nightrider-grey, normal, 1.6px);

                margin-bottom: 20px;
            }

            .required-notice {
                @include font(300, 16px, null, normal, 0);

                margin-top: 16px;
            }

            .signUpKR {
                border-top: none;
            }
        }

        .content-asset {
            > a.title {
                @include underlineOnHover($red);

                display: block;
                padding-top: 18px;
                padding-bottom: 18px;
            }

            .menu-footer {
                a {
                    @include underlineOnHover();
                }
            }

            @include media-breakpoint-up(lg) {
                > a.title {
                    display: inline;
                    padding-top: 0;
                    padding-bottom: 4px;
                }

                .menu-footer {
                    a {
                        display: inline;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .contactUsTitle {
                    display: block;
                }
            }
        }
    }

    &.link-container-kr {
        .footer-item {
            p {
                margin-bottom: 0;
            }

            .title {
                font-weight: bold;
                display: block;
                border-top: 1px solid $light-gray;

                &.social-links-title {
                    @include media-breakpoint-down(md) {
                        border: 0;
                        padding-bottom: 24px;
                        display: inline-block;
                        padding-top: 0;
                    }
                }
            }

            .footer-social-connect-title {
                border-top: unset;
            }

            &.footer-account {
                .title {
                    border-top: unset;
                }
            }
        }

        .socialIconsKorea {
            @include media-breakpoint-up(md) {
                li {
                    margin-right: 24px;
                }
            }

            @include media-breakpoint-down(xs) {
                width: 100%;
                display: flex;
                justify-content: center;

                li {
                    width: 12%;
                    margin: 0;
                }
            }
        }
    }

    &:not(.link-container-kr) {
        @include media-breakpoint-down(md) {
            .title {
                display: inline-block;
                width: 100%;
            }

            p:not(.footer-social-connect-title) {
                margin-bottom: 0;
                border: 0;
            }
        }
    }
}

.footer-social-container {
    .footer-kcp-insurance {
        border: 1px solid $very-light-grey;
        display: flex;
        flex-wrap: wrap;
        border-radius: 4px;
        height: fit-content;
        margin-top: 32px;
        padding: 0;

        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .kcp-img-div {
            flex: 0 0 22%;
            text-align: center;
            align-self: center;

            @include media-breakpoint-down(xs) {
                flex: 0 0 20%;
            }
        }

        .insurance-content {
            flex: 0 0 78%;
            margin-top: 8px;
            margin-bottom: 4px;

            @include media-breakpoint-down(xs) {
                flex: 0 0 80%;
            }
        }

        .kcp-header {
            font-weight: bold;
            @include fontSize($font-body3);

            margin-bottom: 4px;
            line-height: 20px;
        }

        .kcp-body {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: unset;
        }

        .verify-link {
            bottom: 0;
            display: flex;
            justify-content: center;
            margin-bottom: 8px;
            flex: 0 0 100%;
        }

        .verify-certificate {
            display: block;
            text-align: center;
            border: 1px solid #ccc;
            border-radius: 4px;
            text-decoration: none;
            cursor: pointer;
            padding: 4px;
        }
    }

    .copyright-statement-links {
        display: flex;
        justify-content: center;
    }

    .copyright-statement,
    a {
        @include fontSize($font-body3);
    }
}

.footer-social {
    .social-links {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 32px;

        &::before,
        &::after {
            content: '';
            display: block;
        }

        li {
            margin: 0;

            a {
                @include underlineOnHover();

                font-size: initial;
                padding-bottom: 5px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .socialLinksDiv {
            display: flex;
            justify-content: center;
        }

        .socialIconsKorea {
            justify-content: space-between;
            width: 80px;
        }
    }
}

.footer-copy {
    @include font(300, 16px, null, 18px, normal);

    text-align: center;
    margin-top: 32px;

    .copyright {
        margin: 0;
        padding: 0 24px;
        font-size: 16px;
    }

    .postscript {
        padding: 0 24px;
        @include fontSize($font-body2);

        a {
            @include fontSize($font-body2);

            @include underlineOnHover();
        }
    }

    .corporate-info {
        line-height: 20px;
        @include fontSize($font-body3);
    }

    .footerHrMobile {
        margin-bottom: 24px;
    }
}

@include media-breakpoint-up(lg) {
    footer {
        &.checkout-footer,
        &.pdp-footer,
        &.cart-footer {
            .footer-copy {
                margin-bottom: 0;
            }
        }
    }

    .footer-links-container {
        padding-top: 44px;

        a {
            margin-bottom: 16px;
        }

        .footer-item {
            .content-asset {
                .title {
                    @include font(600, 16px, $nightrider-grey, normal, 1.14px);

                    border-top: 0;
                    border-bottom: 0;
                    padding-bottom: 0;
                    font-weight: 600;
                }
            }

            &.footer-email {
                text-align: left;
                padding-top: 0;

                .email-title {
                    @include font(600, 16px, $nightrider-grey, normal, 1.14px);

                    margin-bottom: 12px;
                }
            }
        }
    }

    .footer-social {
        .social-links {
            display: block;

            li {
                margin-right: 24px;
            }

            .icon {
                width: 20px;
            }
        }
    }

    .footer-copy {
        text-align: left;

        .copyright,
        .postscript {
            padding: 0;
        }
    }

    .footer-copy-non-kr {
        &.footer-copy {
            .content-asset {
                display: flex;
                justify-content: space-between;
            }

            .copyright {
                order: 2;
            }
        }

        .postscript {
            .hidden-md-down {
                display: none;
            }
        }
    }

    .privacyPolicy {
        padding-left: 0;
    }
}

.container-shopmore-category-link {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;
    @include media-breakpoint-down('xs') {
        margin-top: 0;
        margin-bottom: 0;
    }

    h4 {
        line-height: 48px;
        font-weight: 700;
        @include fontSize($font-xl);

        @include media-breakpoint-down('xs') {
            font-size: 28px;
            line-height: 32px;
        }
    }

    .shopmore-link {
        max-width: 650px;
        margin: 0 auto;
        text-align: center;
        padding-top: 10px;
        @include media-breakpoint-down('xs') {
            padding-top: 0;
            width: 97%;
            overflow-x: auto;
            white-space: nowrap;
            min-height: 44px;
        }
    }

    span {
        display: inline-block;
        padding-right: 15px;
        line-height: 48px;
        font-weight: 700;
        @include media-breakpoint-down('xs') {
            line-height: 35px;
            padding-right: 10px;
            padding-left: 10px;
            display: inline-block;
        }

        a {
            line-height: 20px;
            font-weight: 500;
            border-bottom: 1px solid #d8d8d8;
            @include fontSize($font-body2);

            @include media-breakpoint-down('xs') {
                line-height: 20px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.form-check-kr:invalid ~ .form-check-label {
    color: $black;
}

table.tfc-valign-wrapper {
    letter-spacing: 1px;
}

.slick-dots {
    li {
        background-color: $disabled-grey;

        &.slick-active {
            background-color: $black;
        }
    }
}
