* {
    font-family: 'Calibre', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    box-sizing: border-box;
}

body {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);

    @include font(
        $size: 16px,
        $color: $black,
        $lh: 20px,
        $ls: 0
    );

    background-color: $white;
}

.divider {
    border: 1px solid $light-gray;
    margin: 16px 0;
}

.typo-rule {
    .heading {
        display: block;
        margin: 16px 0 32px;
    }

    .rules {
        margin: 44px 0 32px;

        p {
            text-align: center;
        }
    }

    .product-name,
    .color-name {
        text-transform: capitalize;
    }
}

.circle {
    display: inline-block;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    &-1 {
        background-color: $white;
        border: 1px solid $black;
    }

    &-2 {
        background-color: $black;
    }

    &-3 {
        background-color: $gray;
    }

    &-4 {
        background-color: $red;
    }

    &-5 {
        background-color: $dark-gray;
    }

    &-6 {
        background-color: $medium-gray;
    }

    &-7 {
        background-color: $light-gray;
    }
}

.colors {
    p {
        margin: 0;
    }
}

.page {
    background-color: $gray;
}

.form-group {
    &.required {
        label.form-control-label {
            &::before {
                margin-right: 4px;
                color: $red;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .content-component {
        padding: 0 60px;
    }
}

.cards-container {
    justify-content: center;
    display: flex;

    .payment-card-icon-wrap {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

    .icon {
        margin-right: 8px;
        margin-bottom: 8px;

        &.payment-card-icon {
            width: 38px;
            height: 24px;

            &:lang(ko) {
                width: 40px;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.price {
    .sales {
        display: inline-block;
        font-weight: 600;
    }

    .strike-through.list {
        display: inline-block;
    }

    .strike-through.list {
        margin-right: 4px;
    }
}

.bulleted-list {
    list-style: disc;
    padding-left: 40px;
}

.oms-error-card {
    max-width: 650px;
    margin: 0 auto !important;
}

.pricing {
    font-weight: 600;
}

.additional-card-icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/**
 * Override for Medallia Feedback Tab. See https://lululemon.atlassian.net/browse/ISBS-220
 **/

@include media-breakpoint-down(md) {
    button#nebula_div_btn {
        top: 65% !important;
    }
}

button#nebula_div_btn {
    .kampyle_button-text {
        -moz-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
        -o-transform: scale(-1, -1);
        -ms-transform: scale(-1, -1);
        transform: scale(-1, -1);
    }
}
