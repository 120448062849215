.btn {
    @include font(600, 16px, $white, 16px, 1px);

    height: 48px;
    line-height: 48px;
    border-radius: 0;
    padding: 0 16px;
    border: 1px solid transparent;
    letter-spacing: 0.075rem;

    @include media-breakpoint-up('lg') {
        @include font(600, 16px, $white, null, normal);
    }

    &.btn-primary {
        @include button(
            $bg: $primary-btn-idle-background-color,
            $color: $primary-btn-idle-color,
            $border-color: $primary-btn-idle-border-color
        );

        &:hover {
            @include button(
                $hover-bg: $primary-btn-hover-background-color,
                $hover-color: $primary-btn-hover-color,
                $hover-border-color: $primary-btn-hover-border-color
            );
        }

        &:active {
            @include button(
                $active-bg: $primary-btn-active-background-color,
                $active-color: $primary-btn-active-color,
                $active-border-color: $primary-btn-active-border-color
            );
        }
    }

    &.btn-secondary {
        @include button(
            $bg: $secondary-btn-idle-background-color,
            $color: $secondary-btn-idle-color,
            $border-color: $secondary-btn-idle-border-color
        );

        &:hover {
            @include button(
                $hover-bg: $secondary-btn-hover-background-color,
                $hover-color: $secondary-btn-hover-color,
                $hover-border-color: $secondary-btn-hover-border-color
            );
        }

        &:active {
            @include button(
                $active-bg: $secondary-btn-active-background-color,
                $active-color: $secondary-btn-active-color,
                $active-border-color: $secondary-btn-active-border-color
            );
        }
    }

    &.btn-alt {
        @include button(
            $red, $white, transparent,
            $red, $white, transparent,
            $red, $white, transparent
        );
    }
}

.ctaButton {
    font-size: 1rem !important;
    letter-spacing: 1.2px !important;
    color: $white;
    line-height: 1.3 !important;
    border: 0.0625rem solid !important;
    border-radius: 0.25rem !important;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    height: auto;

    &.buttonTertiary {
        background-color: $white;
        border-color: $nightrider-grey;

        &:hover {
            background-color: $blackCurrent !important;
            color: $white;
            text-decoration: none;
            border-color: $nightrider-grey !important;
        }
    }

    &.buttonSecondary {
        background-color: $nightrider-grey !important;
        border: 0 !important;
        color: $white !important;
        border: 1px solid $nightrider-grey !important;

        &:hover,
        &:focus,
        &:active {
            background-color: $matterhorn !important;
            text-decoration: none;
            outline-offset: 2px !important;
            outline: 2px solid -webkit-focus-ring-color !important;
        }
    }

    &.buttonPrimary {
        background-color: $crimson !important;
        border-color: $crimson !important;

        &:hover {
            background-color: $cardinal !important;
            border-color: $cardinal;
            text-decoration: none;
            color: $white !important;
        }
    }

    &:disabled {
        background-color: $disabled-grey !important;
        border: none !important;
        pointer-events: none;
        opacity: 1;
    }
}
