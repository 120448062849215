.hero-image-wrapper {
    .slick-prev,
    .slick-next {
        width: 36px;
        height: 36px;
        border-radius: 4px;

        &::before,
        &::after {
            transform: translateX(5px);
        }

        &:hover {
            opacity: 1;
        }
    }

    .slick-dots {
        visibility: hidden; // Added this to hide the greybar as mentioned in ISBS-1044
    }
}

.hero-slot-router-component {
    @include media-breakpoint-down(sm) {
        padding-bottom: unset;
    }

    .hero-title {
        font-weight: 600;
        line-height: 20px;
        @include fontSize($font-body);

        @include media-breakpoint-up('lg') {
            font-weight: 600;
            @include fontSize($font-medium-desk);
        }
    }

    .hero-link-wrapper {
        ul li {
            a {
                @include underlineOnHover($crimson, 3px, 1.5);
            }
        }
    }

    .hero-subtext {
        line-height: 24px;
        @include fontSize($font-xsmall);
    }
}

.hero-no-overlay-component {
    .hero-subtext {
        @include fontSize($font-large);

        line-height: 43px;
    }

    @include media-breakpoint-down(xs) {
        .hero-title,
        .hero-subtext {
            @include fontSize($font-body);

            line-height: 20px;
        }

        .hero-title {
            text-align: center !important;
        }
    }

    .hero-title {
        font-weight: 500;
    }

    .hero-subtext {
        padding-bottom: 5px;
    }

    .hero-image-wrapper {
        .hero-images {

            @include media-breakpoint-up(lg) {
                .slick-next,
                .slick-prev {
                    top: 42%;
                    transform: translateY(-42%);
                }
            }
        }
    }
}

.hero-overlay-component {
    .text-wrapper {
        padding: 10px 15px 0;

        .hero-title {
            font-weight: 600;
        }

        @include media-breakpoint-down(xs) {
            left: 50%;
            transform: translateX(-50%);
        }

        @include media-breakpoint-up(lg) {
            padding: unset;
            left: unset;
        }
    }

    .hero-image-wrapper {
        .slick-prev,
        .slick-next {
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-down(md) {
                top: 30%;
                transform: translateY(-30%);
            }
        }

        .inner-image-wrapper {
            @include media-breakpoint-down(sm) {
                margin-top: 16px;
            }

            .subtext {
                font-weight: 400;
                position: absolute;
                z-index: 2;
                transform: translateY(40px);

                @include media-breakpoint-down(md) {
                    padding: 10px 24px 0 35px;
                    max-width: 15%;
                }

                @include media-breakpoint-down(xs) {
                    transform: translateY(20px);
                    text-align: center;
                    height: 205px;
                    overflow: hidden;
                }

                @include media-breakpoint-up(lg) {
                    transform: translateY(65px);
                    padding: 16px 24px 0;
                }

                .hero-subtext {
                    padding: unset;
                    @include fontSize($font-xsmall-desk);

                    font-weight: 500;
                }
            }
        }
    }
}

.hero-no-overlay-component,
.hero-slot-router-component {
    padding-bottom: unset !important;

    .hero-images {
        &.slick-slider {
            margin-bottom: unset;
        }
    }

    @include media-breakpoint-down(sm) {
        .hero-title {
            text-align: center;
            margin-bottom: 16px;
            margin-top: 16px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .img-and-cta {
        position: relative;
    }

    .hero-ctas {
        &.null {
            bottom: 24px;
        }
    }

    .text-wrapper {
        .hero-title,
        .hero-subtext {
            text-align: left;
        }
    }

    .hero-title {
        @include font(600, 36px, $white, 40px, normal);
    }

    .hero-slot-router-component {
        .hero-link-wrapper {
            ul {
                margin-left: unset;

                li {
                    margin: 0 0 8px;
                }
            }
        }

        .hero-title {
            line-height: 40px;
            margin-bottom: 24px;
            margin-top: 24px;
        }

        .hero-subtext {
            line-height: 43px;
            max-width: unset;
            @include fontSize($font-large);
        }

        .hero-image-wrapper {
            position: relative;

            .slick-prev,
            .slick-next {
                width: 48px;
                height: 48px;

                &::before,
                &::after {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .hero-overlay-component,
    .hero-no-overlay-component {
        .text-wrapper {
            margin: 28px 24px;
        }
    }

    .hero-image-wrapper {
        .slick-prev,
        .slick-next {
            width: 48px;
            height: 48px;
            border-radius: 4px;

            &::before,
            &::after {
                transform: translateX(4px);
            }
        }

        .slick-prev {
            left: 12px;
        }

        .slick-next {
            right: 12px;
        }

        .slick-slide {
            img,
            .hero-video {
                padding: 0;
            }
        }

        .slick-dots {
            visibility: hidden;
        }
    }

    .hero-slot-router-component,
    .hero-no-overlay-component {
        .hero-title {
            color: $black;
        }
    }
}
