body:not(.co-2022) {
    .check-gift-card-balance {
        line-height: normal;
        font-weight: 600;
        text-decoration: underline;
        margin-top: 24px;
        display: block;
        margin-left: 0;
        text-align: center;
    }

    .giftcard-container {
        .check-gift-card-balance {
            margin-left: 40px;
            text-align: left;
        }
    }

    .gift-card-section {
        .gift-card-modal {
            padding-bottom: 32px;

            .modal-header {
                border-bottom: none;
                padding: 16px 16px 0 0;
                text-align: right;

                .modal-close {
                    background: none;
                    border: none;
                    cursor: pointer;
                    z-index: $zindex-lowest;
                }
            }

            .modal-dialog {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
                background: $white;
                align-items: flex-start;
                margin-top: 106px;
                min-height: auto;
                max-width: 875px;
                max-height: 448px;

                &.has-balance {
                    .gift-card-check-balance {
                        display: none;
                    }

                    .gift-card-balance-display {
                        display: block;

                        .btn {
                            border-radius: 0;
                            margin: 24px auto 0;
                            display: block;
                            width: 100%;
                            max-width: 315px;
                        }
                    }
                }
            }

            .modal-content {
                border: none;
                padding-bottom: 32px;
            }

            .gift-card-balance-display {
                display: none;
                padding: 16px 12px 32px;

                .balance {
                    @include fontSize($font-body);

                    line-height: normal;
                    border-bottom: 1px solid $gainsboro-white;
                    padding-bottom: 16px;
                    margin-bottom: 8px;
                    margin-top: 24px;
                }

                .modal-header {
                    padding: 0;
                }

                .header-text {
                    border-bottom: 1px solid $gainsboro-white;
                    padding-bottom: 32px;
                    margin-bottom: 0;
                }
            }

            .header-text {
                @include fontSize($font-small);

                line-height: 1.04;
                letter-spacing: 0.2px;
                margin-top: 12px;
                margin-bottom: 0;
            }

            .header-description {
                @include fontSize($font-body);

                line-height: 1.1;
                margin-top: 28px;
                margin-bottom: 0;
            }

            .modal-body {
                padding: 0 10px;

                .gift-card {
                    margin-top: 12px;
                    margin-bottom: 0;
                }

                .form-control-label {
                    float: left;

                    &::before {
                        color: $black;
                    }
                }

                input.giftCard {
                    border-radius: 0;
                }

                .gift-card-section {
                    margin-top: 12px;
                }

                .check-giftcert {
                    background-color: $red;
                    color: $white;
                    font-weight: 600;
                    line-height: 1;
                    letter-spacing: 1.6px;
                    width: 100%;
                    margin-top: 20px;
                    border-radius: 0;
                    font-size: 16px;
                }
            }

            @include media-breakpoint-up(lg) {
                .modal-body {
                    padding: 0 160px;
                    text-align: center;

                    .card-number-section,
                    .check-giftcert,
                    .check-btn input.giftCard {
                        width: 315px;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .invalid-feedback {
                        margin-top: 4px;
                        text-align: left;

                        @include font(400, 18px, $cardinal-red, 24px, 0);
                    }
                }

                .header-text {
                    margin-top: 36px;
                }

                .gift-card-balance-display {
                    .header-text,
                    .balance {
                        width: 554px;
                        margin: 0 auto;
                    }

                    .balance {
                        margin-top: 24px;
                        margin-bottom: 48px;
                    }

                    .modal-header {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
